@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.updatePaymentStripeContainer {
  min-width: 540px;
  @include small {
    min-width: 100%;
  }

  .errorMessage {
    font-family: $nunito !important;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: red;
  }
}

.updatePaymentStripe {
  display: flex;
  flex-direction: column;
  width: 100%;

  .action {
    display: flex;
    width: 100%;
    margin-top: 2rem;
  }

  .actions {
    display: grid;
    grid-template-columns: 160px 160px;
    gap: 1rem;
    margin-top: 2rem;
  }

  .updatePaymentStripeFooterCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 320px;
    text-align: center;
    margin: auto;
    margin-top: 2rem;

    .stripeImage {
      height: 28px;
      margin-top: 1rem;
    }
  }

  .updatePaymentStripeFooterLeft {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    margin: auto;
    margin-top: 2rem;

    .stripeImage {
      height: 28px;
      margin-top: 1rem;
    }
  }

  .updatePaymentStripeComponent {
    min-height: 180;
    width: 100%;

    .updatePaymentStripeComponentTitle {
      font-family: $georgia !important;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;
      color: $gray;
      margin: 0;
    }

    .updatePaymentStripeComponentDescription {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
      margin-bottom: 1rem;
    }

    .termsContent {
      font-family: $nunito !important;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.45;
      color: #757575;
      margin-top: 1rem;
    }

    .oneDollarContent {
      text-decoration: underline;
    }
  }
}
