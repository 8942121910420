@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandSetupSuccessView {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;

  display: flex;

  max-width: 100vw !important;
  width: 100%;

  max-height: 100vh !important;
  height: 100%;

  background-image: url("/assets/images/bg-ocean.jpg");
  background-size: cover;

  .confetti1,
  .confetti2 {
    background-image: url("/assets/images/brand-setup/confetti.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 390px;
    width: 100%;
    max-height: 280px;
    height: 100%;
  }

  .confetti1 {
    position: absolute;
    top: 0;
    left: 0;

    @include small {
      max-width: 100vw;
      width: 100%;
    }
  }

  .confetti2 {
    position: absolute;
    top: 0;
    right: 0;

    @include small {
      display: none;
    }
  }

  .stackContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    gap: 72px;

    @include small {
      gap: 86px;
    }

    .title {
      font-family: Georgia;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;

      line-height: 135%;
      color: $white;

      text-align: center;

      @include small {
        font-size: 20px;
        max-width: 320px;
      }
    }

    .imageContainer {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      width: 304px;
      height: 304px;

      background-color: $white;
      border-radius: 50%;

      .successImg {
        width: 270px;
        height: 270px;
        object-fit: contain;
        z-index: 10;
        border-radius: 50%;
      }
    }
  }
}
