@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.accountAddressesView {
  .flexContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    @include small {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  }

  .title {
    font-family: $georgia !important;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $medium-gray;
  }

  .text {
    font-family: $nunito !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.009px;
    color: $gray;
    margin-top: 4px;

    .link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .boxContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid $light-gray;
    background-color: $white;
  }

  .addressesContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
