@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.formContainer {
  flex: 1;
  border: 1px solid #0000001f;
  background-color: $lighter-gray;
}

.formActions {
  position: relative;
  display: flex;
  gap: 1rem;

  @include small {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 16px 10%;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
}

.brandSetupFormCustomizer {
  flex: 1;

  .formSlider {
    width: 90%;
    margin: auto;
    margin-bottom: 3rem;
  }
  .accordionHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    :global(.MuiAccordionSummary-content) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 0.75rem;
    }

    .accordionHeaderTitle {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      .errorIcon {
        height: 12px;
        width: 12px;
        border-radius: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $error;
        color: #fff;
        border: 1px solid $white;
        font-size: 8px;
      }
    }
  }

  .accordionHeaderExpanded {
    pointer-events: none;
  }

  .formRadio {
    position: relative;
    left: -10px;

    &:hover {
      span {
        color: $peach !important;
      }
      p {
        color: $peach !important;
      }
    }
  }
  :global(.MuiAccordion-root) {
    border-left: none;
    border-right: none;

    &:first-child {
      border-top: none;
    }

    &:not(:last-child) {
      border-bottom: none;
    }

    &::before {
      display: none;
    }
  }

  .radioLogoTypes {
    margin-bottom: 0.25rem;
    :global(.MuiFormControlLabel-root) {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      margin-right: auto;
      padding-left: 10px;
    }
  }

  .radioLogoTypesOptionInverted {
    background: $gray !important;
  }

  .radioLogoTypesOption {
    height: 64px;
    width: 64px;
    background: $light-gray;
    padding: 0.5rem;
    border: 1px solid #0000001f;

    .radioLogoTypesOptionImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .radioLogoTypesOptionLabel {
      position: absolute;
      top: 50%;
      left: 132px;
      transform: translateY(-50%);
    }
  }
}

.customizedChip {
  font-family: $nunito;
  font-size: 0.625rem;
  line-height: 0.75rem;
  font-weight: 600;
  text-align: center;

  height: 14px;
  max-height: 14px;

  background-color: $bg-blue-faded;
  color: $blue;

  .icon {
    height: 12px;
    width: 12px;
  }
}

.defaultButtonContainer {
  display: flex;
  cursor: pointer;
  margin-bottom: 1rem;
  justify-content: flex-end;
  .defaultButtonText {
    text-decoration: underline;
    font-size: 0.825rem;
    color: $gray;

    &:hover {
      color: $peach;
    }
  }

  @include small {
    position: inherit;
  }
}
