@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.active {
  font-weight: bold;
}

:global(.MuiStepLabel-label.Mui-active) {
  font-weight: bold !important;
}
