@import "styles/colors.scss";
@import "styles/mixins";

.pageHeaderContainer {
  border-bottom: 1px solid $gray;
  padding-bottom: 10px;

  .actionContainer {
    margin-bottom: 0px;
  }
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: flex-start;
}

@include small {
  .pageHeaderContainer {
    .actionContainer {
      width: 100%;
      // margin: 15px 0;
    }
  }
  .minimumnPageHeaderContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    border: none;
    width: 100vw;
    max-height: 120px;
    min-height: 60px;
    height: 100%;

    margin-left: -16px;
    align-items: center;
    justify-content: center;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    background: #fff;
    z-index: 100;
    padding: 10px;
    // margin-bottom: 80px;

    .actionContainer {
      width: 100%;
    }
  }

  .addTopBorder {
    border-top: $border;
  }

  .dropShadow {
    box-shadow: 0px 4px 4.7px rgba(0, 0, 0, 0.1);
  }
}
