@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandPlateStatusProcessTitle {
  color: $peach;
  font-size: 18px;
  font-weight: 700;
}

.brandPlateStatusProcess {
  .stepperRoot {
    @include small {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .stepLabelRoot {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    color: $light-gray;
    font-family: $nunito !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  .stepLabelAlternativeLabel {
    margin-top: 0;
  }

  .stepLabelActive,
  .stepLabelCompleted {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    color: $blue;
  }

  // ICON props:
  .stepIconRoot {
    color: $light-gray;
    height: 24px;
    width: 24px;
  }

  .stepLabelContent {
    padding-left: 28px;
  }

  .stepIconCompleted {
    color: $blue;
  }
  .stepIconActive {
    color: $blue;
  }

  // CONNECTOR props:
  .stepConnectorRoot {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    margin-left: 9px;
  }

  .stepConnectorLine {
    min-height: 12px;
  }

  .subtitle {
    text-align: start;
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @include small {
    margin: 0 auto;
  }
}

.showInRevision {
  display: block;
}
.hideInRevision {
  display: none;
}
