@import "styles/colors";
@import "styles/text.scss";
@import "styles/mixins";

.sidePanelOnboardingStepOneB {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  background-image: url("/assets/images/bg-sand.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  @include small {
    gap: 24px;
    padding: 32px;
    width: 100%;
  }

  .title {
    display: flex;
    max-width: 450px;
    text-align: center;

    font-family: $georgia !important;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;

    line-height: 130%;
    color: $blue;
    padding-bottom: 85px;

    @include small {
      font-size: 24px;
      line-height: 135%;
      padding-bottom: 0;
    }
  }

  .blankaLogo {
    width: 96px;
    height: auto;
  }

  .text {
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;

    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;

    color: rgba(0, 0, 0, 0.6);
  }

  .flexContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }

  .testmonialContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 255px;
    max-width: 400px;

    .avatar {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 100%;
    }

    .testmonial {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: italic;
      font-weight: 700;

      line-height: 150%;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);

      max-width: 230px;
      padding: 24px 16px;
    }

    .caption {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;

      line-height: normal;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.6);
    }

    .shopify {
      width: 81px;
      height: auto;
    }
  }

  .handPositioning,
  .imgContainer {
    position: absolute;
    left: 0;
    bottom: 0px;
    z-index: 1;

    @include small {
      bottom: -10px;
    }

    img {
      width: 180px;
      height: 368px;
      object-fit: cover;

      @include small {
        width: 73px;
        height: 132px;
      }
    }
  }

  .stackContainer {
    display: flex;
    flex-direction: column;
    gap: 72px;
  }

  .ratingWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    gap: 8px;

    .rating {
      display: flex;

      justify-content: center;

      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      .starIcon {
        margin-left: 1px;
        width: 16px;
        height: auto;
        color: $white;
      }
    }

    .testmonial {
      font-family: $nunito !important;
      font-size: 10px;
      font-style: italic;
      font-weight: 700;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.shopify {
  width: 81px;
  height: auto;
}
