@import "styles/colors";
@import "styles/text";
@import "styles/mixins";

.checkoutDiscountForm {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  .couponInput {
    width: 100%;
    margin-bottom: 10px;
  }

  .errorMessage {
    font-size: 10px;
    margin-bottom: 10px;
    color: $error;
  }

  .couponCta {
    width: auto;
    height: auto;

    font-family: $nunito !important;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;

    padding: 7px 16px !important;

    &:disabled {
      background-color: $white !important;
      border: 1px solid $light-gray !important;
      color: $light-gray !important;
    }
  }
}
