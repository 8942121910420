@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

// code before that isn't used anywhere
// :global(.MuiTypography-root.MuiTypography-body1) {
//   font-weight: 700;
// }
// :global(.MuiTypography-root) {
//   //color:initial;
//   font-family: "Nunito Sans", sans-serif;
// }

// :global(.MuiTypography-h1) {
//   font-weight: 700;
// }

// :global(.MuiTypography-h2) {
//   font-weight: 700;
// }

// :global(.MuiTypography-h2) {
//   font-weight: 700;
// }

// :global(.MuiTypography-h4) {
//   font-weight: 700;
// }

// :global(.MuiTypography-h5) {
//   font-weight: 700;
// }

// :global(.MuiTypography-h5) {
//   font-weight: 700;
// }

.titleVariant {
  font-family: $georgia !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32.4px;
  color: $gray;
  display: block;
}

.subtitleVariant {
  font-family: $georgia !important;
  font-size: 21px;
  font-weight: 700;
  line-height: 29.4px;
  display: block;
}

.descriptionVariant {
  font-family: $nunito !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.37px;
  color: $gray;
  display: block;
}
