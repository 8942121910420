@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.paused {
  opacity: 0.5;
}

.planFeatures {
  font-family: $nunito !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4em !important;
  text-align: center;
  text-transform: uppercase;

  margin-bottom: 20px;
  width: 100%;

  .boxContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-top: 10px;
  }

  .title {
    font-family: $georgia !important;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $peach;
  }

  .subtitle {
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.8px;
    text-transform: uppercase;
    color: $gray;
  }
}

.boxContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10px;
}

.success {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  margin-top: 2px;
  color: $blue;
}

.upgradeMessage,
.plansLink {
  font-family: $nunito !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;

  color: $blue;
}

.plansLink {
  cursor: pointer;
  text-decoration: underline;
}
