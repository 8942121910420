@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.tag {
  border-radius: 2.5px !important;
  text-transform: uppercase;
  line-height: 22px;
  color: white !important;

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 0;
}
.statusPaymentRequired {
  background: $blue !important;
}

.statusUnfulfilled {
  background-color: $orange !important;
}

.statusOrdered {
  background-color: $orange !important;
}

.statusFulfilled {
  background-color: $green !important;
}

.statusCancelled {
  background-color: $error !important;
}

.MuiChip-root {
  border-radius: 2.5px;
  .MuiChip-label {
    text-transform: uppercase;
    line-height: 22px;
  }
}
