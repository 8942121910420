@import "styles/colors";
@import "styles/text";
@import "styles/mixins";

.publishedDiscontinuedProductNotification {
  position: fixed;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding: 10px;
  color: $white;
  background-color: $peach;
  font-family: $nunito !important;

  z-index: 10000;

  .title {
    display: flex;
    align-items: center;

    margin-right: 5px;
    text-align: left;

    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;

    svg {
      font-weight: bold;
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
  }

  .text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .link {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    color: $white;
    cursor: pointer;
  }

  @include small {
    position: relative;
    // position: fixed;

    flex-direction: column;
    align-items: flex-start;
    padding: 15px;

    .title {
      margin-bottom: 10px;
      font-size: 14px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .text {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
