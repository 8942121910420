@import "styles/colors.scss";

.availableCreditComponent {
    margin-top: 20px;
    max-width: 500px;
    padding-top: 20px;
    border-top: 1px solid $light-gray;
}

.title {
    margin-bottom: 20px;
}

.credit {
    border: 1px solid $gray;
    margin-top: 10px;
    background-color: $white;
    padding: 5px 10px;

    display: inline-block;
    border-radius: 4px;
}

.creditValue {
    font-weight: bold;
}

.usd {
    font-weight: bold;
}
