@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.mobileStepper {
  margin-top: -16px;
  margin-left: -15px;
  margin-right: -15px;
  height: 130px;
  width: 100vw;
  background: linear-gradient(63.36deg, #246071 16.7%, #143f4b 83.3%);
  margin-bottom: 10px;

  .divider {
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    margin: auto;
    width: 95%;
    background-color: $medium-gray;
    z-index: 1000;
  }

  .hidden {
    display: none;
  }

  .horizontalStepper {
    margin-top: 20px;
  }

  :global(.MuiStepConnector-lineHorizontal) {
    border-top-color: #fff;
    opacity: 0.1;
  }

  :global(.MuiSvgIcon-root) {
    background-color: transparent;
  }

  .labelBox {
    display: flex;
    flex-direction: column;
    padding-left: 55px;
    padding-top: 20px;
    gap: 5px;

    .title {
      font-family: $nunito;
      font-style: normal;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.4em;
      text-transform: uppercase;
      color: $white;
    }

    .subTitle {
      font-family: $nunito;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: $white;
      width: 100%;
    }
  }
}
