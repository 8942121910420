@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.taskSuccessModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;

  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 100vw !important;
  width: 100%;

  max-height: 100vh !important;
  height: 100%;

  background-image: url("/assets/images/bg-ocean.jpg");
  background-size: cover;

  .taskSuccessModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 430px;

    background-color: $white;
    background-image: url("/assets/images/stars.svg");
    background-position-y: center;
    background-position-x: 40px;
    background-repeat: none;
    box-shadow: 0px 3px 10px 0px #0000002e;
    padding: 40px;

    @include small {
      width: 90%;
    }

    .title {
      font-family: $georgia;
      font-size: 24px;
      font-weight: 700;
      line-height: 32.4px;
      text-align: center;
      color: $gray;
    }

    .subtitle {
      font-family: $nunito;
      font-size: 14px;
      font-weight: 400;
      line-height: 20.01px;
      text-align: center;
      margin-top: 0.25rem;
      color: $gray;
    }

    .star {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    .circle {
      width: 206px;
      height: 206px;
      border-radius: 100%;
      background-color: $peach;
      box-shadow: 0px 0px 29px 0px #d56f5b66;
      margin-top: 72px;
      margin-bottom: 140px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .priceWrapper {
        position: relative;
        top: -10px;
        left: 8px;
        .price {
          font-family: $georgia !important;
          font-size: 64px;
          font-weight: 700;
          line-height: 83.2px;
          text-align: center;
          color: $white;
        }

        .dollar {
          position: absolute;
          left: -24px;
          top: 8px;
          font-family: $georgia !important;
          font-size: 32px;
          font-weight: 700;
          line-height: 41.6px;
          text-align: center;
          color: $white;
        }
      }

      .creditLabel {
        font-family: $nunito !important;
        font-size: 14px;
        font-weight: 600;
        line-height: 19.1px;
        letter-spacing: 1px;
        text-align: center;
        color: white;
      }
    }
  }

  .confetti1,
  .confetti2 {
    background-image: url("/assets/images/brand-setup/confetti.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 390px;
    width: 100%;
    max-height: 280px;
    height: 100%;
  }

  .confetti1 {
    position: absolute;
    top: 0;
    left: 0;

    @include small {
      max-width: 100vw;
      width: 100%;
    }
  }

  .confetti2 {
    position: absolute;
    top: 0;
    right: 0;

    @include small {
      display: none;
    }
  }
}
