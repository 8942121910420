@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.outOfStockTag,
.outOfStockTagFull {
  position: relative;
  display: flex;
  align-items: center;

  color: $blue;
  background-color: $bg-blue-faded;
  border-radius: 100px;
  min-height: 22px;
  min-width: 22px;
  overflow: hidden;
  white-space: nowrap;
  z-index: 5;
  transition: width 0.3s ease;

  .tagIcon {
    position: absolute;
    left: 4px;
    height: 14px;
    width: 14px;
    transition: margin-right 0.3s ease;
    z-index: 2;
    display: flex;
    align-items: center;
  }

  .tag {
    position: relative;
    top: 1px;
    font-family: $nunito !important;
    font-size: 10px;
    font-weight: 700;
    opacity: 0;
    color: $blue;
    text-transform: uppercase;
    transition: opacity 0.3s ease;
    margin-left: 22px;
    line-height: 1;
    padding-right: 8px;
  }
}

.outOfStockTag {
  width: 11px;
  transition: width 0.3s ease;

  &:hover {
    width: auto;

    .tag {
      opacity: 1;
    }
  }
}

.outOfStockTagFull {
  width: auto;

  .tag {
    opacity: 1;
  }
}
