@import "styles/colors.scss";
@import "styles/mixins.scss";

.cartView {
  @include small {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cartError {
    color: $error;
    font-size: 16px;
    font-weight: 700;

    @include small {
      margin-top: 10px;
      font-size: 14px;
    }
  }
}
