@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandPlateOrderListItem {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  flex-grow: 1;

  .orderInfo {
    display: flex;
    flex: 2;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    @include small {
      // gap: 8px;
    }

    .logoStatusApproved {
      margin-left: 16px;

      @include small {
        margin-left: 8px;
      }
    }

    .title {
      display: flex;
      align-items: center;
      font-family: $georgia !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: $gray;

      @include small {
        flex-direction: column;
        align-items: flex-start;
        font-size: 12px;
      }
    }

    .date {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 4px;

      @include small {
        font-size: 9px;
      }
    }

    svg {
      width: 24px;
      height: 24px;
      padding: 8px;

      @include small {
        width: 16px;
        height: 16px;
        padding: 1px;
      }
    }
  }

  .orderStatus {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;

    .title {
      font-family: $nunito !important;
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.8px;
      text-transform: uppercase;

      @include small {
        font-size: 7px;
      }
    }

    .status {
      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 8px;

      @include small {
        font-size: 10px;
      }
    }

    .approvedText {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 8px;

      @include small {
        font-size: 9px;
      }
    }
  }

  .success {
    color: $green;
  }

  .error {
    color: $error;
  }

  .teal {
    color: $blue;
  }
}

.imgOverlay {
  width: 3.125rem;
  height: 3.125rem;
  padding: 2.5px;
  display: flex;
  justify-content: center;
  align-items: center;

  $size: 15px;
  $background-color: rgba($white, 1);
  $a: 0.3;
  $b: 0;
  $c: $a;
  $d: $b;
  $e: 0.5;
  background-color: $background-color;
  background-size: #{$size} #{$size};
  background-position:
    0 0,
    #{$size} #{$size};
  background-image: linear-gradient(to right, rgba($light-gray, $a) 50%, transparent 50%, transparent),
    linear-gradient(to right, transparent 0%, transparent 50%, rgba($light-gray, $b) 50%),
    linear-gradient(to bottom, rgba($light-gray, $c) 50%, transparent 50%, transparent),
    linear-gradient(to bottom, transparent 0%, transparent 50%, rgba($light-gray, $d) 50%),
    linear-gradient(to bottom, $background-color 50%, transparent 50%, transparent),
    linear-gradient(to right, transparent 0%, transparent 50%, rgba($light-gray, $e) 50%);

  .brandPlateLogo {
    width: 80%;
    //height: 100%;
    object-fit: contain;
    padding: 10px;

    @include small {
      margin-left: 5px;
    }
  }
}
