@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.cartShipping {
  @include small {
    padding-bottom: 20px;
  }

  .card {
    padding: 40px;
    background-color: $lighter-gray;

    @include small {
      padding: 10px;
      display: flex;
      flex-direction: column;
    }
  }

  .boxContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    margin: 20px 0;

    @include small {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
  }

  .title {
    font-family: $georgia !important;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $medium-gray;
    margin-top: 20px;
  }

  .subtitle {
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.8px !important;
    text-transform: uppercase;
    color: $gray;
    margin-bottom: 20px;

    @include small {
      font-size: 16px;
    }
  }

  .addressContainer {
    padding-top: 16px;

    :hover {
      cursor: pointer;
    }

    .tagActive {
      position: relative;
      border: 1px solid $blue;
      border-radius: 4px;

      svg {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }

  .showMoreBtn {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    margin-top: 16px;

    .label {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.4px;
      text-transform: uppercase;
      color: $gray;
      cursor: pointer;

      svg {
        margin-left: 10px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .shippingDetailsForm {
    padding-top: 20px;
    width: 1050px;
    display: flex;

    .largeInputContainer {
      width: 470px;
      margin-top: 30px;
    }

    .checkboxContainer {
      width: 470px;
      margin-top: 15px;
    }

    .fullNameContainer {
      width: 230px;

      &Name {
        width: 230px;
        padding-right: 10px;
      }
    }

    .form {
      display: flex;
      flex-wrap: wrap;
      width: 90%;
    }

    .addressDetailsContainer {
      margin-top: 30px;
      width: 150px;

      @include small {
        width: 100%;
      }
    }

    .continueToShippingButton {
      float: right;
    }
  }

  .actionContainer {
    display: flex;
    padding-top: 10px;
    justify-content: space-between;
  }

  .label {
    margin-bottom: 10px;
    span {
      font-size: 12px !important;
    }
  }

  .divider {
    border-bottom: 1px solid $medium-gray;
    opacity: 0.1;
    margin-top: 10px;
  }

  .limitCartTotals {
    margin-left: 20px;

    @include small {
      align-items: center;
    }
  }
}

.coupon {
  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
}

.stackContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
