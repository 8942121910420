@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.blackFridayModalNotification {
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 2rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
  border-radius: 8px;
  width: calc(100% - 4rem);

  background-color: $bg-blue-faded;

  @include small {
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 2rem 1rem;

    width: calc(100% - 2rem);
  }

  .ribbon {
    position: absolute;
    top: -15px;
    right: 0px;
    overflow: visible;
    max-width: 180px;
    width: 100%;
    max-height: 96px;
    height: 100%;
  }

  .info {
    font-family: $nunito !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 4px;

    color: rgba(0, 0, 0, 0.5);
  }

  .title {
    font-family: $georgia !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;

    line-height: 135%;
    margin-bottom: 4px;

    color: $blue;

    @include small {
      font-size: 16px;
    }
  }

  .disclaimer {
    display: flex;
    justify-content: flex-end;
    font-family: $nunito !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
  }
  .terms {
    font-size: 8px;
    color: $medium-gray;
    position: absolute;
    bottom: 5px;
    right: 10px;
  }
}
