@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins";

.noOrdersModal {
  .textHeader {
    width: 282px;
    font-family: $georgia;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 60px 31.5px 16px 31.5px;
  }

  .textWarning {
    text-align: center;
    font-family: $nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 0 45px 40px 45px;
  }
}
