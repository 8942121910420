@import "styles/colors";
@import "styles/text.scss";
@import "styles/mixins";

.sidePanelPlanSelectionB {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  .link {
    margin-top: 12px;
    margin-left: 16px;
    margin-bottom: 28px;

    display: flex;
    justify-self: flex-start;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;

    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;

    color: $white;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
  .stackContainer {
    display: flex;
    width: fit-content;
    margin: 0 auto;
    justify-content: center;
    align-items: center;

    gap: 32px;

    @include small {
      width: 100%;
      height: 100%;
    }

    .stackItem {
      display: flex;
      width: fit-content;
      flex-direction: column;
      align-items: center;

      &:first-child {
        @include small {
        }
      }

      @include small {
        width: 100%;
        height: 100%;
      }

      .stackText {
        align-self: flex-start;

        font-family: $nunito !important;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;

        line-height: normal;
        letter-spacing: 2px;
        text-transform: uppercase;

        color: $white;
        padding-bottom: 8px;

        @include small {
          align-self: center;
        }
      }
    }

    .logo {
      width: 96px;
      height: 24px;
      margin-bottom: 32px;
    }
  }

  .linkMobile {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;

    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;

    color: $white;
    cursor: pointer;

    // margin-left: 20px;
    padding: 8px;
    margin-bottom: 1rem;

    .stackTextMobile {
      width: 100%;
      display: flex;
      justify-content: center;

      font-family: $nunito !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;

      line-height: normal;
      letter-spacing: 2px;
      text-transform: uppercase;

      color: $white;
    }

    .iconMobile {
      position: absolute;
      left: 16px;
      display: flex;
      margin-left: 10px;
    }
  }
}
