@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins";

.orderDetailsModal {
  position: absolute;
  top: 60px;
  width: 100%;
  overflow-y: auto;

  .flexContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 25px 15px 20px 15px;
  }

  .leftSideBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .rightSideBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .orderNumber {
    font-family: $georgia !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .orderDate {
    font-family: $georgia !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 7px;
  }

  .orderStatus {
    font-family: $georgia !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
  }

  .divider {
    border-bottom: 1px solid $medium-gray;
    opacity: 0.1;
    margin: 0 15px;
  }

  .trackContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px 15px;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    :first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .trackInfo {
      font-family: $nunito;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-left: 6px;
    }

    .trackPaymentRequired,
    .trackUnfulfilled {
      font-family: $nunito;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    .trackFulfilled {
      font-family: $nunito;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 2px;
      text-decoration-line: underline;
      text-transform: uppercase;
    }
  }
}
