@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.colorSwatch {
  width: 50px;
  height: 50px;
}

.productImage {
  position: relative;
  max-width: 80px;
  img {
    width: 80px;
    border: 1px solid $light-gray;
  }

  .statusWrapper {
    position: absolute;
    top: -15px;
    left: -12px;
  }

  .productDiscontinued {
    position: absolute;
    top: -15px;
    z-index: 1;
    width: 105px;
  }

  .diagonal {
    width: calc(sqrt(2) * 80px);
    border-top: 1px solid $gray;
    transform: translate(-16px, 41px) rotate(-45deg);
  }

  .diagonalLines {
    background-image: url("/assets/images/discontinued.png");
    background-size: cover;
    // background-color: rgb($color: #000, $alpha: 0.9);
    background-repeat: repeat;
    // backdrop-filter: blur(10px);
    width: 81px;
    height: 81px;
    object-fit: cover;
  }

  .imageOutOfStock {
    opacity: 0.5;
  }

  .productTag {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 5px;
    top: -12px;
    left: -12px;
    width: 12px;
    height: 12px;
  }
}

.customTooltip {
  background-color: $white;
  border: 1px solid $light-gray;
  padding: 10px;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }
}

.tooltipContent {
  background-color: $white;
}

.tooltipTitle,
.tooltipText {
  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  color: $gray !important;
  cursor: pointer;
}

.tooltipText {
  font-weight: 400 !important;
}

.tooltip,
.tooltipError {
  height: 14px;
  width: 14px;
  color: $blue;
  right: -20px;

  cursor: pointer;
}

.tooltipError {
  color: $error;
}

.tooltipTitle,
.tooltipText,
.tooltipTitleError {
  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  color: $gray !important;
}

.tooltipTitleError {
  color: $error !important;
}

.tooltipText {
  margin-top: 3px;
  font-weight: 400 !important;
}

.withDiscount {
  font-family: $nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $blue;
  position: relative;
}

.withDiscountTooltip {
  font-family: $nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $blue;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.noDiscount {
  font-family: $nunito;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
}

.boxContainer {
  * {
    height: 14px;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: lowercase;
  }
}

.discountWarning {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: $nunito !important;
  text-transform: none;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  border-radius: 4px;
  background-color: rgb($color: $blue, $alpha: 0.2);
  width: max-content;
  padding: 5px 8px;
  top: 5px;
  left: 40px;
}

.minimumOrderQuantityWarning {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: $nunito !important;
  text-transform: none;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  border-radius: 4px;
  background-color: rgb($color: $blue, $alpha: 0.2);
  width: max-content;
  padding: 5px 8px;
  top: 5px;
  left: 40px;
}

.icon {
  width: 14px;
  height: 14px;
  margin-right: 3px;
}

.peach {
  color: $peach;
  font-weight: 700;
}

.discountRow {
  position: relative;
}

.discountItem {
  width: fit-content;
  position: relative;
}

.brandedBoxNotAvailableText {
  letter-spacing: 1.4px;
}

.errorText {
  color: $error;
  font-family: $nunito !important;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
}
