@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.addressAutocomplete {
  width: 100%;
}

.label {
  font-family: $nunito !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
