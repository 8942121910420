@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.appBar {
  background: $white;

  .text {
    &Link {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    &BlogLink {
      font-size: 12px;
      color: $gray;

      &:hover {
        text-decoration: none;
      }

      @include small {
        display: none;
      }
    }
  }

  .bellIcon {
    top: -7px;
    right: 7px;
  }

  .accountLinks {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: 10px;

    a {
      font-size: 12px;
      font-weight: 400;
      color: $gray;

      &:hover {
        text-decoration: underline;
      }

      &:first-child {
        padding-right: 10px;
        border-right: 1px solid $gray;
      }
      &:last-child {
        padding-left: 10px;
      }
    }
  }
}

.cartIconWrapper {
  display: flex;
  text-decoration: none;
  position: relative;
  top: 3px;
  span {
    font-size: 12px;
  }
}

.cartIcon {
  position: relative;
  top: 3px;

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;
  }

  .iconBadge {
    position: absolute;
    z-index: 4;
    top: -6px;
    right: -8px;
    width: 15px;
    height: 15px;
    border-radius: 100px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $peach;
    box-shadow: 0px 0px 4px 0px #d56f5b80;
    border: 1px solid #ffdfd8;
    color: $white;

    font-family: $nunito;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.03em;
  }

  .iconBadgeBig {
    min-width: 21px;
    // padding: 0px 5px;
    height: 15px;
  }
}

.leftActions {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-left: 16px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
