@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.blackFridaySidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $bg-blue-faded;
  margin: 0 auto;
  width: 184px;
  padding: 8px 10px 20px 10px;
  border-radius: 4px;
  gap: 12px;
  margin-bottom: 15px;

  .ribbon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .info {
    font-family: $nunito !important;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    padding-top: 2.5rem;
    color: $blue;
  }

  .title {
    font-family: $georgia !important;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    color: $blue;
    max-width: 164px;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    padding: 0 8px;
    margin-top: 4px;
  }

  .unlockCta {
    width: 132px;
    height: 32px;
  }

  .terms {
    font-size: 8px;
    color: $medium-gray;
  }
}
