@import "styles/colors.scss";
@import "styles/text.scss";

.planTableCard {
  position: relative;
  box-shadow: none;
  max-width: 200px;
  padding: 24px 0;

  &:hover {
    background: linear-gradient(270deg, #fff 0%, #f3f3f3 100%);
  }

  .stackWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    align-items: center;

    padding: 0 16px;
    margin: 0 auto;

    gap: 12px;

    .discountTagBox {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
    }

    .pricingBox {
      height: 40px;
    }

    .title,
    .tableTitle {
      font-family: $georgia !important;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $peach;
      text-align: center;

      svg {
        margin-left: 8px;
      }
    }

    .tableTitle {
      display: flex;
      flex-direction: column;
      text-align: start;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      color: $gray;
    }

    .description {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      text-align: center;
      height: 40px;
    }

    .ctaPlaceholder {
      height: 34px;
      width: 100%;
      background-color: red;
    }

    .cta {
      display: flex;
      padding: 9px 10px !important;
      justify-content: center;
      align-items: center;
      align-self: center;
      border-radius: 2px;

      width: 168px;

      max-height: 34px;
      height: 34px;

      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.152px !important;
      text-transform: uppercase;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    .price,
    .oldPrice {
      font-size: 13px;
      color: $blue;
      letter-spacing: normal;
    }

    .price {
      height: 40px;
    }

    .oldPrice {
      text-decoration: line-through;
      color: $peach;
    }

    .simpleText {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      color: $medium-gray;
      margin-bottom: 0px;
      margin-top: 2px;
    }

    .subText {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      color: $medium-gray;
      margin-top: 0px;
    }
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    margin-top: 24px;

    ul {
      list-style: none;
      padding: 0;
      width: 100%;
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 18px;

      padding: 8px 0;
      border-bottom: 1px solid $light-gray;

      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $blue;

      svg {
        height: 14px;
        width: 14px;
      }
    }

    li:last-child {
      border: none;
    }

    .featureNotProvided,
    .featureProvided {
      width: 18px;
      height: 18px;
    }

    .featureNotProvided {
      color: $light-gray;
    }

    .featureProvided {
      color: $blue;
    }

    .bold {
      font-size: 13px;
      font-weight: 800;
    }
  }

  .noPlanFeatureColumn {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 18px;

    .text {
      display: flex;
      justify-content: flex-start;
      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $gray;

      svg {
        margin-right: 5px;
      }
    }

    .bold {
      font-size: 13px;
      font-weight: 800;
    }
  }
}

.featureColumn {
  width: auto;
}

.cardEmphasis {
  position: relative;
  border: 3px solid $peach;
  border-radius: 4px;
  margin: -15px 0;
  padding: 35px 0;

  &:hover {
    background: linear-gradient(270deg, #fff 0%, #f3f3f3 100%);
  }
}

.noHover {
  &:hover {
    background: none;
  }
}

.boxContainer {
  height: 16px;
}
