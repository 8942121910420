@import "styles/colors";
@import "styles/text.scss";
@import "styles/mixins";

.pageNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  gap: 80px;

  @include small {
    margin-left: 30px;
    margin-right: 30px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    max-width: 600px;
    width: 100%;
    text-align: center;

    .headerText {
      font-family: $nunito !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 2px;
      text-transform: uppercase;

      color: $gray;
    }

    .title {
      font-family: $georgia !important;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;

      color: $blue;
    }

    .text {
      font-family: $nunito !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      color: $gray;
    }
  }

  .footerText {
    font-family: $nunito !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;

    color: $gray;
  }

  .image {
    width: 250px;
    height: 250px;
    object-fit: cover;
  }

  .link {
    font-family: $nunito !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    text-decoration-line: underline;
    text-decoration-style: solid;

    cursor: pointer;
  }
}
