@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.totals-container {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: 16px;
  padding-bottom: 15px;
  max-width: 300px;
  margin-left: auto;
  text-transform: uppercase;

  @include small {
    max-width: 215px;
    width: 100%;
    padding-right: 0;
    padding-top: 40px;
  }

  .MuiTableCell-root,
  td {
    text-transform: uppercase !important;
  }

  &__values-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .MuiTypography-body1 {
    min-width: 50px;
    margin-bottom: 10px;
  }

  &--border-top {
    // border-top: 1px solid #cccccc;
    border-top: 1px solid $medium-gray;
    padding-top: 10px;
  }

  .button--red-outline {
    margin-top: 25px;
  }
}
