@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.notificationRead {
  &::after {
    position: absolute;
    right: -5px;
    top: -5px;
    content: "";
    width: 10px;
    height: 10px;
    background-color: $peach;
    border-radius: 100%;
  }
}

.notificationCard {
  margin-right: 5px;
  position: relative;
  border-radius: 4px;
  background-color: $white;
  border: 1px solid $light-gray;
  box-shadow: 0px 4px 7px 0px #0000000d;
  padding: 12px;

  display: grid;
  grid-template-columns: 30px 1fr 84px;
  gap: 10px;

  &:hover {
    border-color: $peach;
  }

  .notificationIcon {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #e6888021;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $peach;

    svg {
      fill: currentColor;
    }
  }

  .notificationIconUrgent {
    background-color: $peach;
    color: $white;

    svg {
      fill: currentColor;
    }
  }

  .notificationTitle {
    font-family: $nunito;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.1px;
    text-align: left;
    color: $peach;
  }

  .notificationMetaData {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .notificationTime {
      font-family: $nunito;
      font-size: 8px;
      font-weight: 600;
      line-height: 10.91px;
      letter-spacing: 1px;
      text-align: right;
      color: $gray;
      text-transform: uppercase;
      text-align: right;
      margin-bottom: 8px;
    }
  }

  .notificationAction {
    font-family: $nunito;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.37px;
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
    display: inline-block;

    &:hover {
      opacity: 0.6;
    }
  }
}
