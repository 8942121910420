@import "styles/colors.scss";
@import "styles/mixins";
@import "styles/text";

.discountTag {
  height: fit-content;
  width: fit-content;
}

.primary {
  color: $white;
  background-color: $peach;

  &.faded {
    color: $white;
    background-color: rgba($peach, 0.2);
  }
}

.secondary {
  color: $white;
  background-color: $blue;

  &.faded {
    color: $blue;
    background-color: rgba($blue, 0.2);
  }
}

.small {
  width: fit-content;
  height: 16px;

  font-family: $nunito !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.medium {
}
