@import "styles/mixins";

.onboardingWrapperB {
  display: flex;
  flex-direction: row;

  width: 100%;

  height: 100%;
  min-height: 100dvh;

  @include small {
    flex-direction: column;
    // justify-content: flex-start;
    // height: 100%;
  }
}
