@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.sideBox {
  display: flex;
  width: 50%;
  height: 100vh;

  background-image: url("/assets/images/bg-ocean.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  @include small {
    width: 100%;
    height: auto;
  }

  .welcomeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    gap: 50px;

    @include small {
      padding: 32px;
      gap: 32px;
    }

    .blankaLogoGif {
      width: 269px;
      height: 269px;
      object-fit: cover;
      border-radius: 100%;

      @include small {
        width: 170px;
        height: 170px;
        object-fit: cover;
      }
    }
    .blankaLogo {
      transform: scale(1.2);
      @include small {
        transform: scale(1.1);
      }
    }
  }
}

.onboardingStepFiveB {
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: center;

  width: 50%;

  @include small {
    width: 100%;
  }

  a:hover {
    text-decoration: none;
  }
}

.confettiGif {
  position: relative;
  top: 0;
  height: 40%;
  background-image: url("/assets/images/lottie-confettie.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .upContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 56px;
    margin-top: 150px;
    padding: 0 80px;
    text-align: center;
    @include small {
      padding: 0 32px;
      margin-top: 48px;
    }
    @include medium {
      padding: 0px 20px;
    }
  }
}

.title {
  font-family: $nunito !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;

  line-height: 170%;
  letter-spacing: 4px;
  text-align: center;
  text-transform: uppercase;

  color: $peach;
  @include small {
    font-size: 16px;
  }

  @include large {
    font-size: 16px;
  }
}

.rightSideContent {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.onlineShop {
  font-family: $nunito !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  text-align: center;
  color: $gray;
}

.onlineShopText {
  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  padding-top: 8px;
  text-align: center;
  color: $gray;
}

.storesContainer {
  display: flex;
  justify-content: center;
  padding: 32px 0;
  flex-wrap: wrap;
}

.text {
  &ShopType {
    margin-top: auto !important;
  }

  &Redirect {
    cursor: pointer;
    text-decoration-color: $gray;
    margin-left: 5px;
  }

  &ComingSoon {
    font-weight: bold;
    visibility: hidden;
    position: absolute;
    text-transform: uppercase;
    left: 22px;
  }
}

.divider {
  position: relative;
  display: flex;
  top: -5px;

  align-items: center;
  text-align: center;

  color: $medium-gray;
  padding: 0px 80px 56px 80px;

  @include small {
    padding-top: 32px;
  }

  @include medium {
    padding-top: 42px;
  }

  @include large {
    padding-top: 56px;
  }
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid $light-gray !important;
}

.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}

.storeContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  align-content: center;

  width: 80px;
  height: 80px;
  margin: 8px;
  border-radius: 4px;

  background: #f6f6f6;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-bottom: 8px;
  }

  .text {
    &ShopType {
      position: absolute;
      font-family: $nunito !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      bottom: 8px;
      color: $gray;
    }
  }
}

.storeContainerActive:hover {
  background-color: $blue;

  .text {
    &ShopType {
      color: $white;
    }
  }
}

.storeContainerInactive:hover {
  background: #5e5e5e;
  opacity: 0.73;

  cursor: default;

  img {
    opacity: 0.1;
  }

  .text {
    &ComingSoon {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-family: $nunito !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      text-align: center;
      color: $gray;
      visibility: visible;
    }

    &ShopType {
      color: $medium-gray;
    }
  }
}

.noticeContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 80px;
}

.loading {
  padding: 75px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.successTagContainer {
  max-width: 600px;
  margin: 40px auto;

  @include small {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.active {
  background-color: $blue;
  color: $white;
  .text {
    &ShopType {
      color: $white;
    }
  }
}

.disabled {
  opacity: 0.73;
  cursor: default;
  img,
  p {
    opacity: 0.3;
  }
}
