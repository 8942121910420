@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.billingList {
  @include small {
    display: none;
  }
}

.title {
  font-family: $georgia !important;
  font-size: 24px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0;
  text-align: center;
}

.select {
  font-size: 14px;
}

/* Payment history */
/* Normal and widescreen */
@media (min-width: 601px) {
  .headerContainer {
    display: flex;
    border: 0.5px solid $light-gray;
    margin-top: 1.5rem;
  }
  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .filterContainer {
    display: flex;
  }

  .header {
    text-transform: uppercase;
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    color: #5e5e5e;
    font-size: 0.75rem;
    line-height: normal;
    letter-spacing: 0.15rem;
    font-weight: bold;
    flex: 1;
    align-items: end;
  }

  .header:nth-child(1) {
    padding-left: 1rem;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    border-top: 0.5px solid $light-gray;
  }

  .paginationControls {
    display: flex;
  }

  .itemContainer {
    display: flex;
    align-items: center;
    background: white;
    border-left: 0.5px solid $light-gray;
    border-right: 0.5px solid $light-gray;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .item {
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex: 1;
    color: #333;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15rem;
    text-transform: uppercase;

    .actionButton {
      align-items: flex-end;
      text-align: center;

      a {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.125rem;
        text-transform: uppercase;
        padding: 0;
        text-decoration: none;
        color: $primary;
      }
    }
  }

  .itemLabel {
    display: none;
  }
}

/* Smartphones */
@media (max-width: 600px) {
  .headerContainer {
    display: none;
  }
  .itemContainer {
    display: flex;
    flex-direction: column;
  }
  .item {
    text-align: left;
    padding: 0 0.5rem 0.5rem;
    line-height: 1.5rem;
    font-size: 11px;
    letter-spacing: 2.4px;
    flex: 1;

    .actionButton {
      align-items: flex-end;
      text-align: center;

      a {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.125rem;
        text-transform: uppercase;
        padding: 0;
        text-decoration: none;
        color: $primary;
      }
    }
  }

  .itemLabel {
    text-transform: uppercase;
    text-align: left;
    padding: 0.5rem 0.5rem 0;
    color: #5e5e5e;
    line-height: 1.5rem;
    font-size: 12px;
    letter-spacing: 2.4px;
    font-weight: bold;
    flex: 1;
  }

  .item:first-child {
    border: none;
  }

  .item:last-child {
    border: none;
  }
}

.refundIcon {
  font-size: 12px;
  svg {
    font-size: 14px;
  }
}
