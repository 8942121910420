@import 'styles/colors';
@import 'styles/mixins';

.storeConnectedWrapper{
    padding-bottom: 15px;
    padding-left: 0px;
    text-align: left;
  }


  .storeConnectContainer{
    padding: 20px;
  }

  .storeConnectedString{
    color: $gray;
  }