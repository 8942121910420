@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.cancelWrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.imgContainer {
  width: 200px;
  height: 200px;
  margin: 0 auto;

  .productImage {
    width: 200px;
    opacity: 0.7;
  }
}

.content {
  position: relative;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  @include small {
    width: auto;
    padding: 16px;
    margin-bottom: 24px;
  }

  .title {
    font-family: $georgia;
    font-size: 21px;
    font-weight: 700;
    line-height: 29.4px;
    text-align: center;
    color: $gray;
    margin-bottom: 30px;
  }

  .usageContainer {
    display: grid;
    grid-template-columns: 284px 284px;
    gap: 32px;

    @include small {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .usageBox {
      padding: 40px;
      background-color: #fff4f2;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;

      .icon {
        width: 60px;
        height: 60px;
        margin-bottom: 24px;
      }
    }
  }
}

.actions {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @include small {
    margin-bottom: 4rem;
  }

  .cancelSubscriptionButton {
    margin-top: 24px;

    font-family: $nunito;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.37px;
    text-align: left;
    text-decoration-line: underline;
    color: #979797;
    text-transform: initial;
    letter-spacing: initial !important;
    padding: 0 !important;

    &:hover {
      color: $peach;
    }
  }
}

.confirmModalWrapper {
  @include small {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 50px;
    min-width: initial !important;
    min-height: initial !important;
  }
}
