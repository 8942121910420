@import "styles/colors";
@import "styles/text";
@import "styles/mixins";

.sampleOrderMobileView {
  width: 100%;

  .mobileFlexContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .noOrdersToDisplay {
    font-family: $georgia !important;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
