@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.myLogoStepTemplate {
  height: 100%;
  width: 100%;

  .fileRejection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
    width: 300px;
    margin: 0 auto;
  }

  .errorText {
    color: $error;
  }

  .boxContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: -10px;
    width: 200px;
  }

  .textTitle {
    font-family: $nunito !important;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $blue;

    @include small {
      // font-family: $nunito !important;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 8px;
      margin-top: 10px;
      margin-left: 10px;
    }
  }

  .stepTextTitle {
    font-family: $nunito !important;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $blue;

    @include small {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 8px;
    }
  }

  .divider {
    border: 1px solid $light-gray;
    margin: 20px 0;
  }

  .backBtn {
    font-family: $nunito !important;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;

    display: flex;
    align-items: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    color: $blue;
    margin-left: -30px;
    margin-right: -10px;
    margin-top: -10px;
    margin-bottom: -10px;

    svg {
      margin-right: 5px;
    }
  }

  .stepContainer {
    display: flex;
    flex-direction: column;

    .sectionSubTitle {
      font-family: $nunito !important;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.4em;
      text-transform: uppercase;
      color: $blue;

      @include small {
        font-size: 12px;
        line-height: 16px;
        margin-left: 10px;
      }
    }

    .sectionLead {
      font-family: $nunito !important;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: $blue;

      @include small {
        margin-left: 10px;
      }
    }

    .stepImageSection {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      .imageCaption {
        width: 350px;
        position: absolute;
        bottom: 20px;
        font-family: $nunito;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4em;
        text-transform: uppercase;
        color: $gray;
        text-align: center;
        word-wrap: break-word;

        @include small {
          width: 300px;
        }
      }

      .stepImageContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 500px;
        height: 500px;
        border: 1px solid $light-gray;

        @include small {
          width: 345px;
          height: 381px;
        }

        .stepImage {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;

          @include small {
            width: 300px;
            height: 300px;
          }
        }

        .logoDefault {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          max-width: 138px;
          max-height: 138px;
          width: 100%;
          height: 100%;
          top: 151px;
          left: 100px;

          @include small {
            max-width: 95px;
            max-height: 95px;
            width: 100%;
            height: 100%;
            top: 120px;
            left: 70px;
          }

          @media only screen and (max-width: 350px) {
            left: 45px;
          }
        }

        .logoInverted {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          max-width: 136px;
          max-height: 100px;
          width: 100%;
          height: 100%;
          top: 151px;
          left: 99px;

          @include small {
            max-width: 98px;
            max-height: 75px;
            width: 100%;
            height: 100%;
            top: 120px;
            left: 65px;
          }

          @media only screen and (max-width: 350px) {
            left: 40px;
          }
        }

        .logoThin {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          max-width: 110px;
          max-height: 43px;
          width: 100%;
          height: 100%;
          top: 228px;
          left: 130px;

          @include small {
            max-width: 106px;
            max-height: 30px;
            width: 100%;
            height: 100%;
            top: 175px;
            left: 65px;
          }

          @media only screen and (max-width: 350px) {
            left: 40px;
          }
        }

        .logoInvertedThin {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          max-width: 110px;
          max-height: 12px;
          width: 100%;
          height: 100%;
          top: 245px;
          left: 178px;

          @include small {
            width: 88px;
            height: 12px;
            top: 184px;
            left: 125px;
          }

          @media only screen and (max-width: 350px) {
            left: 100px;
          }
        }

        .logoImg {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .imageDisclaimer {
      font-family: $nunito !important;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $gray;
      text-align: center;
      padding-top: 20px;
    }
  }

  .gridContainer {
    width: 500px;
    margin: 0 auto;
    row-gap: 10px;
    justify-content: space-between;

    @include small {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      width: 343px;
    }
  }

  .btnWidthConstraint {
    max-height: 34px;
    height: 100%;
    margin-top: 20px;

    width: 240px;
    font-family: $nunito !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16.37px;
    letter-spacing: 0.096em !important;
    text-transform: uppercase;

    @include small {
      width: 343px;
      margin-top: 5px;
    }

    @media only screen and (max-width: 350px) and (min-width: 200px) {
      width: 290px;
    }
  }

  .mockupGridWrapper {
    // width: 100%;
    justify-content: center;
    margin-top: 10px;

    row-gap: 10px;
    column-gap: 10px;

    @include small {
      margin-top: 20px;
      // justify-content: space-evenly;
      // row-gap: 7px;
      column-gap: 5px;
    }
  }

  .mockupGrid {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 300px;
    height: 300px;

    border: 1px solid $light-gray;

    @include small {
      width: 168px !important;
      height: 168px !important;
      p {
        margin-top: 20px;
        margin-bottom: 0px !important;
      }
    }

    @media only screen and (max-width: 350px) {
      width: 140px !important;
      height: 140px !important;
    }

    .mockupImage {
      width: 270px;
      height: 100%;
      object-fit: contain;

      @include small {
        width: 150px;
        height: 100px;
      }

      @media only screen and (max-width: 350px) {
        width: 120px !important;
        height: 80px !important;
      }
    }

    .mockupLogoImg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .mockupImageCaption {
      font-family: $nunito;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.4em;
      text-transform: uppercase;
      margin-bottom: 20px;

      @include small {
        font-family: $nunito;
        font-size: 8px;
        line-height: 11px;
      }
    }

    .mockupLogoDefault {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      max-width: 84px;
      max-height: 84px;
      width: 100%;
      height: 100%;
      top: 70px;
      left: 60px;

      @include small {
        max-width: 44px;
        max-height: 44px;
        width: 100%;
        height: 100%;
        top: 35px;
        left: 35px;
      }

      @media only screen and (max-width: 350px) {
        max-width: 36px;
        max-height: 36px;
        width: 100%;
        height: 100%;
        top: 27px;
        left: 30px;
      }
    }

    .mockupLogoInverted {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      max-width: 77px;
      max-height: 57px;
      width: 100%;
      height: 100%;
      top: 72px;
      left: 60px;

      @include small {
        max-width: 45px;
        max-height: 35px;
        top: 33px;
        left: 33px;
      }

      @media only screen and (max-width: 350px) {
        max-width: 36px;
        max-height: 26px;
        width: 100%;
        height: 100%;
        top: 27px;
        left: 29px;
      }
    }

    .mockupLogoThin {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 90px;
      height: 30px;
      top: 117px;
      left: 55px;

      @include small {
        width: 44px;
        height: 16px;
        top: 60px;
        left: 37.5px;
      }

      @media only screen and (max-width: 350px) {
        width: 40px;
        height: 12px;
        top: 42px;
        left: 37.5px;
      }
    }

    .mockupLogoInvertedThin {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      max-width: 60px;
      max-height: 8px;
      width: 100%;
      height: 100%;
      top: 127.5px;
      left: 110px;

      @include small {
        width: 33px;
        height: 5px;
        top: 65px;
        left: 65px;
      }

      @media only screen and (max-width: 350px) {
        width: 33px;
        height: 5px;
        top: 45.5px;
        left: 47px;
      }
    }
  }

  .mockupTitleText {
    font-family: $georgia !important;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    color: $blue;
    text-align: center;
    padding: 20px 0;
  }

  .mockupText {
    font-family: $nunito;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0 auto;
    text-align: center;

    @include small {
      max-width: 340px;
      width: 100%;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .center {
    justify-content: center;
    gap: 8px;
  }

  .mt {
    margin-top: 10px;
  }

  .mb {
    margin-bottom: 20px;
  }

  .centerWithMargin {
    display: flex;
    margin: 0 auto;
  }

  .hidden {
    display: none;
  }
}
