@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.myLogoErrorNotification {
  z-index: 99999;

  :global(.MuiPaper-root) {
    background-color: $peach;
  }

  .stackContainer {
    padding: 40px 20px;
  }

  .body {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    background-color: $peach;

    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 90%;

    @include small {
      align-self: center;
    }

    .title {
      font-family: $nunito !important;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 6.032px;
      text-transform: uppercase;
      color: $white;
    }

    .errorText {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $white;
      margin-top: 5px;
    }

    .closeButton {
      position: absolute;
      text-transform: uppercase;
      color: #5e5e5e;
      font-size: 8px;
      font-weight: 100;
      letter-spacing: 2.4px;
      cursor: pointer;

      top: 10px;
      right: 10px;
      color: $white;
    }
  }
}
