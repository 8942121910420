

.App .template-profile{


    .MuiPaper-root{
        background: #FCFCFC;
    }

    a{
        color: $gray;
    }

    .root{
        width: 100%;
    }


    .paper{
        width: 100%;
        margin-bottom: 16px;
    }

    .header{
        border-bottom: 1px solid $gray;
        margin-bottom: 50px;
    }

    .table{
        border-collapse: collapse;
        margin: 16;
        width: 50%;
        min-width: 350px;
    }

    .tableData{ 
        padding: 8 
    }

    .logo{
        width: 200px;
        margin: 20px 0px;
    }

    .card{
        width: 100%;
        padding: 20px;
        text-align: left;
        display: flex;
        flex-direction: row;



        &__right{
            display: flex;
            width: 40%;
            padding: 20px;
            text-align: center;
            flex-direction: column;
            align-items: center;  
            justify-content: center;       
        }

        &__left{
            width: 60%;
        }

        &__plan-title{
            margin-bottom: 25px; 
            font-size: 23px;
        }

        &__title{
            margin-bottom: 25px;
            letter-spacing: 0.1em !important;
            font-size: 28px;
        }

        &__payment-info-title{
            font-size: 26px;
            margin-top: 25px;
        }

        &__secure-message{
            font-size: 12px;
        }

        &__update-billing-message{
            font-size: 10px;
            letter-spacing: 0.1em;
        }

        &__text-logo{
            font-size: 27px;
        }

        &__update-logo-button{
            font-size: 10px;
            cursor: pointer;
        }
    }

    .text {
        &__card-navigation {
            font-size: 13px;
            letter-spacing: 0.3em;
            color: $gray !important;
            text-decoration: none;
            text-transform: uppercase;
        }

        &__is-active {
            font-weight: bold !important;
        }

        &__update-logo {
            font-size: 10px;
            cursor: pointer;
            letter-spacing: 2.5px;
        }
    }

    .MuiButton-label {
        letter-spacing: 0.2em !important;
    }
}


.StripeElement {
    border: transparent;
    padding: 15px;

}


  

  .template-profile-password{
    margin-bottom:10px
  }