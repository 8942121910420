@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.accountAddressesForm {
  .stackContainer {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    gap: 24px;
  }

  .singleLine {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;

    @include small {
      flex-direction: column;
    }
  }

  .autoCompleteContainer {
    width: 100%;
  }
}
