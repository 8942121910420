@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.outerStepper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  background: linear-gradient(63.36deg, #246071 16.7%, #143f4b 83.3%);
  height: 100%;

  .outerStepperChildren {
    padding: 20px;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  // utils:
  .textTitle {
    font-family: $georgia;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
  }

  .divider {
    border: 1px solid $light-gray;
    margin: 20px 0;
  }

  .stepperLabel {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    justify-content: flex-start;
  }

  .stepperTitle {
    color: $light-gray;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
  }

  .stepperSubTitle {
    color: $light-gray;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  .brandingTipsContainer {
    // position: absolute;
    // bottom: 140px;
    // left: 50%;
    // transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    margin-bottom: 2rem;

    .title {
      font-family: $georgia;
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 24px;
      color: $white;
    }

    .subTitle {
      font-family: $nunito;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $white;
    }
  }

  .helpIconStyling {
    fill: $white;
    width: 52px;
    height: 52px;
  }
}
