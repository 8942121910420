@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.activeTag {
  border-radius: 100px;
  background: rgba($blue, 0.1);
  color: $blue;
  padding: 4px 10px;

  font-family: $nunito !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;

  @include small {
    padding: 0;
    font-size: 8px;
    margin-top: 5px;
    height: 25px;
  }
}
