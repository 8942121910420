@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.dashboardNewsCardWithImage {
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 16px;
}

.dashboardNewsCard {
  background: #fcfcfc;
  border: 1px solid #0000001f;
  padding: 16px;

  gap: 16px;
  position: relative;

  font-family: $nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.37px;

  color: $gray;

  .image {
    width: 80px;
    height: 80px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-family: $nunito;
      font-size: 12px;
      font-weight: 600;
      line-height: 16.37px;
      letter-spacing: 2px;
      color: $peach;
      text-transform: uppercase;
    }

    .content {
      p {
        margin: 0;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        &:hover {
          color: $peach;
          cursor: pointer;
        }
      }
    }
  }
}
