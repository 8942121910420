

.icon-root{
    
    .icon--normal{
        display: inline-block;
    }
    .icon--hover{
        display: none;
    }
    &:hover{
        .icon--normal{
            display: none;
        }   
        .icon--hover{
            display: inline-block;

        }
    }
}

