@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.overlayWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($white, 0.8);

  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  .overlayCard {
    background: linear-gradient(63.36deg, #246071 16.7%, #143f4b 83.3%);
    box-shadow: 0px 3px 10px 0px #0000002e;
    width: 430px;
    padding: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: -25%;
    transform: translateX(25%);

    .title {
      font-family: $georgia;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: $white;
      margin-top: 1.5rem;
    }

    .description {
      font-family: $nunito;
      font-size: 14px;
      font-weight: 400;
      line-height: 20.01px;
      text-align: center;
      color: $white;
      margin: 12px 24px;
      margin-bottom: 40px;
    }

    @include small {
      left: 0;
      transform: translateX(0);
    }
  }
}
