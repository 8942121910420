@import "styles/mixins";
@import "styles/colors";
@import "styles/text";

/* Container styles */
.onboardingQuestionForms {
  position: relative;

  .dotsContainer {
    position: absolute;
    bottom: 72px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 18px;

    @include small {
      bottom: 56px;
    }

    .dot {
      width: 6px;
      height: 6px;

      background-color: rgba(#000, 0.12);
      border-radius: 50%;
      transition: background-color 0.3s;

      &.active {
        background-color: $blue;
      }
    }
  }
}

.snapContainer {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100dvh;
  scroll-behavior: smooth;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.snapSection {
  scroll-snap-align: start;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  flex-direction: column;

  @include small {
    justify-content: flex-start;
    padding-top: 114px;
    height: calc(100dvh - 114px);
  }

  .answersContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .answerInputContainer {
      height: 40px;
      max-height: 40px;
      display: flex;
      gap: 12px;

      @include small {
        height: 32px;
        max-height: 32px;
      }

      .answerInput {
        border: 1px solid $medium-gray;
        font-size: 14px;
        line-height: 19.1px;
        letter-spacing: 1px;
        border-radius: 0px !important;
        padding: 10.5px 12px;
        flex: 1;

        &:hover {
          border-color: #000;
        }

        &:focus {
          outline: none;
          box-shadow: none;
          border-color: $peach;
        }
      }

      .answerButton {
        width: 56px;
      }
    }
  }

  .questionText {
    font-family: $georgia !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: $blue;
    line-height: 140%;
    text-align: center;
    margin-bottom: 32px;
    width: 60%;

    @include small {
      font-size: 21px;
      width: 95%;
      margin: 16px;
    }
  }
}

.checkboxButton {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid $peach;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Arial, sans-serif;
  color: $peach;
  min-width: 280px;

  @include small {
    padding: 8px 16px;
  }

  &:hover {
    background-color: rgba($peach, 0.15);
  }

  &.checked {
    background-color: rgba($peach, 0.7);
    color: white;
  }

  .checkboxInput {
    display: none;
  }

  .checkboxCircle {
    min-width: 16px;
    min-height: 16px;
    border: 1px solid $peach;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;

    background-color: white;

    .checkMark {
      font-size: 14px;
      color: #d87769;
    }
  }

  .checkboxLabel {
    font-size: 12px;
    font-weight: 600;
    color: inherit;
    font-family: $nunito;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.skip {
  position: absolute;
  bottom: 72px;
  right: 24px;

  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.2px;
  text-transform: uppercase;

  color: $gray;

  &:hover {
    cursor: pointer;
    color: $peach;
  }

  @include small {
    margin: auto;
    right: initial;
    bottom: 24px;
  }
}

.formLoader {
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}
