@import "styles/colors.scss";
@import "styles/text.scss";

.planTableCardMobile {
  position: relative;

  display: flex;
  flex-direction: column;
  border: none;
  box-shadow: none;
  padding: 20px;

  .stackWrapper {
    display: flex;
    flex-direction: row;
    justify-content: justify-content;
    align-items: center;
    width: 100%;

    .discountTagBox {
      margin-right: 10px;
    }

    .planTypeBox {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 4px;

      .title {
        font-family: $georgia !important;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $peach;
        text-align: center;

        svg {
          margin-left: 8px;
        }
      }

      .price,
      .oldPrice {
        font-size: 12px;
        color: $blue;
        letter-spacing: normal;
        text-align: start;
      }

      .price {
        font-family: $georgia !important;
      }

      .oldPrice {
        text-decoration: line-through;
        color: $peach;
      }

      .simpleText {
        font-family: $nunito !important;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: start;
        color: $medium-gray;
        margin-top: 4px;
        margin-bottom: 0px;
      }
      .subText {
        font-family: $nunito !important;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        color: $medium-gray;
        margin-top: 0px;
      }
    }
  }

  .cta {
    display: flex;
    padding: 9px 18px !important;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 2px;
    margin: 12px auto;

    width: 100%;
    height: 34px;

    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.152px !important;
    text-transform: uppercase;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  .description {
    font-family: $georgia !important;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 14px;
  }

  .divider {
    width: 47px;
    height: 1px;
    background-color: $lighter-gray;
    margin-top: 16px;
    align-self: center;
  }

  .cardContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;

    ul {
      list-style: none;
      padding: 0;
      width: 100%;
    }

    li {
      font-style: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 18px;
      padding: 18px 0;
    }

    .cardContentLeft {
      width: 100%;
      li {
        display: flex;
        width: 100%;
        justify-content: flex-start;
      }

      .bold {
        font-weight: 800;
      }
    }

    .cardContentRight {
      li {
        display: flex;
        justify-content: center;
        color: $blue;
        margin-left: 12px;

        svg {
          height: 18px;
          width: 18px;
          color: $blue;
        }
      }

      .featureNotProvided {
        color: $light-gray;
      }

      .featureProvided {
        color: $blue;
      }

      .bold {
        font-weight: 800;
      }
    }
  }

  .noPlanFeatureColumn {
    display: flex;
    flex-direction: column;

    .text {
      display: flex;
      justify-content: flex-start;
      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $gray;
    }
  }

  .bold {
    font-size: 13px;
    font-weight: 800;
  }
}

.cardEmphasis {
  position: relative;
  border: 3px solid $peach;
  border-radius: 4px;
}
