@import "src/styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 360px;
}

.planDetails {
  display: flex;
  justify-content: space-between;
  max-width: 250px;
  margin-bottom: 25px;
}

.header {
  font-family: $georgia !important;
  font-size: 24px;
  font-weight: bold;
  color: $gray;
}

.planDetailsContainer {
  max-width: 250px;
  min-width: 250px;
}

.planName {
  font-weight: bold;
  text-align: left;
  min-width: 250px;
  margin: 25px 0px;
  text-align: center;
}

.TandC {
  margin-top: 16px;
  text-align: center;
}

.poweredByStripe {
  margin-top: 40px;
  width: 100px;
}

.backIcon {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.keepCurrentPlanText {
  font-family: $nunito !important;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 2px;
  text-align: center;
  color: $gray;
  text-transform: uppercase;
  margin-top: 1.5rem;
  cursor: pointer;

  &:hover {
    color: $peach;
  }

  svg {
    width: 15px;
    height: 15px;
    margin-bottom: -4px;
  }
}
