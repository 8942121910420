@import "styles/colors.scss";
@import "styles/mixins.scss";

.card {
  padding: 24px;
  background-color: $lighter-gray;
  margin-top: 30px;

  .overide {
    text-decoration: none;
  }

  .cardButton {
    font-size: 12px;
  }
}

@include small {
  .card {
    padding: 20px;
  }
}
