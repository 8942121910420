@import "styles/text";
@import "styles/colors.scss";

.tag {
  color: $white;

  font-family: $nunito !important;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;

  width: fit-content;
  padding: 2px 6px;
  position: relative;
}

.tagBlanka {
  background: $gray;
}

.tagCustom {
  // background: rgba($peach, 0.8);
  background: $peach;
}

.tagUnbranded {
  background: #999999;
}

.tagBlankaLabs {
  // background: rgba($peach, 0.8);
  background: $peach-faded;
  color: $peach;
}

.premiumStar {
  position: absolute;
  right: -18px;
  top: 0px;
  z-index: 999;
  background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;

  svg {
    height: 12px;
    width: 12px;
    color: $white;
  }
}
