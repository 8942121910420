@import "styles/mixins";
@import "styles/colors";

.productCard {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  padding-bottom: 118%; // Keeps the same aspect ratio as the actual product card
  border: 1px solid transparent;

  // need this in order to have aspect ratio proper with current figma designs but dynamic width
  padding-bottom: 100%;

  @include small {
    padding-bottom: 120%;
  }

  .productVariantsSide {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 12px;
    top: 12px;

    .variantCircleSkeletonSide {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #a3a3a3;
      margin-bottom: 4px;
      animation: pulse 3s ease-in-out infinite;
    }
  }

  .productImages {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 30%; // Keeps the same height as the product image container
    border-radius: 100%;
    .imageSkeleton {
      width: 100%;
      height: 100%;
      background: #a3a3a3;
      border-radius: 8px;
      animation: pulse 3s ease-in-out infinite;
    }
  }

  .productContent {
    min-height: 68px;
    padding: 8px 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    z-index: 5;

    .productVariants {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      padding: 8px 16px;

      .variantCircleSkeleton {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #a3a3a3;
        animation: pulse 3s ease-in-out infinite;
      }
    }

    .nameSkeleton {
      width: 70%;
      height: 16px;
      background-color: #a3a3a3;
      margin: 12px 0;
      animation: pulse 3s ease-in-out infinite;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@import "styles/mixins";
@import "styles/colors";
