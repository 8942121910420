@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.planDescription {
  width: 100%;

  .title {
    font-family: $georgia !important;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $gray;
  }

  .subtitle {
    font-family: $nunito !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.009px;
    color: $gray;
  }

  .stackPlans {
    justify-content: space-between;
    align-items: center;
  }

  .stackTitle {
    font-family: $georgia !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $peach;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  .featureRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;

    .featureText {
      font-family: $nunito !important;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: gray;
    }

    .featureFormat {
      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $blue;
    }

    .underline {
      text-decoration: underline;
    }

    .strikeThrough {
      color: $light-gray;
      text-decoration: line-through;
    }

    .divider {
      width: 100%;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    }

    .closeIcon {
      color: $light-gray;
      width: 14px;
      height: 14px;
    }

    .checkCircleIcon {
      color: $blue;
      width: 14px;
      height: 14px;
    }
  }
}
