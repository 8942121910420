@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandPlateStatusIndicator {
  // LABEL props:
  .stepLabelRoot {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    padding: 3px 0;

    gap: 8px;

    color: $light-gray;
    font-family: $nunito !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.009px;
  }

  .stepLabelAlternativeLabel {
    margin-top: 0;
  }

  .stepLabelActive,
  .stepLabelCompleted {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    color: $blue;
  }

  // ICON props:
  .stepIconRoot {
    color: $light-gray;
    height: 14px;
    width: 14px;
  }
  .stepIconCompleted {
    color: $light-gray;
  }
  .stepIconActive {
    color: $blue;
  }

  // CONNECTOR props:
  .stepConnectorRoot {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    margin-left: 6px;
  }

  .subtitle {
    text-align: start;
    font-family: $nunito !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @include small {
    margin: 0 auto;
  }
}

.showInRevision {
  display: block;
}
.hideInRevision {
  display: none;
}
