@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.ConfirmRemoveAddressModal {
  max-width: 522px;

  .contentContainer {
    display: flex;
    text-align: left;
    flex-direction: column;
    gap: 35px;
  }

  .buttonContainer {
    align-self: center;
  }

  .textSpacing {
    margin: 0px 0px;
    padding-bottom: 30px;
    font-size: 12px;
  }

  .text {
    &Header {
      font-family: $georgia !important;
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
      color: $gray;
    }

    &Content {
      font-size: 12px;
      font-weight: 400;
      margin-top: 10px;
      padding-bottom: 26px;
    }
  }

  .actionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .cta {
      font-family: $nunito !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 2px;
      text-transform: uppercase;
      width: auto;
      height: 48px;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
}

@include small {
  .ConfirmDeleteModal {
  }
}
