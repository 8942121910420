@import "styles/mixins.scss";

.main {
  margin-left: 220px;
  min-width: 1024px;
  padding-bottom: 74px;
  padding-top: 72px;
  position: relative;

  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.mainClosed {
  position: relative;
  margin-left: 62px;
  margin-top: 64px;
  // padding-top: 100px;
}

@include small {
  .main {
    position: relative;
    margin-left: 0px;
    // margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    min-width: 100vw;
    width: 100%;

    // for testing
    margin-top: 100px;
  }

  .mainClosed {
    margin-left: 0px;
    margin-top: 0px !important;
    padding-top: 0px;
  }
}

.mainWrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.outletContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

.adjustToastContainer {
}
