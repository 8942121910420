@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.trackingContainer {
  display: flex;
  align-items: center;
}

.helpIcon {
  color: $blue;
  margin-right: 10px;
  width: 20px;
  margin-top: -2px;
}

.productImage {
  max-width: 80px;
  img {
    width: 80px;
    border: 1px solid $light-gray;
  }
}

.hide {
  display: none;
}

.backBtn {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  cursor: pointer;

  font-family: $nunito !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-align: left;
  color: $gray;
  text-transform: uppercase;

  svg {
    width: 16px;
    height: 16px;
  }
}
.title {
  font-family: $nunito !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $gray;
}

.peachText {
  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $peach;
  margin-top: 4px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $light-gray;

  margin: 16px 0;
}

.cardContent {
  padding: 0;
  margin-bottom: 16px;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.4;
}

.appendedHeader {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin-top: 16px;
  background-color: $lighter-gray;
  border: 1px solid #979797;
  border-bottom: none;

  @include small {
    display: flex;
    gap: 16px;
    margin-top: 0;
    flex-direction: column;
    padding: 16px;
    padding-top: 24px;

    border: none;
  }
}
