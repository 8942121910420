@import "styles/colors.scss";
@import "styles/mixins.scss";

.drawerWrapper {
  background-color: rgba($gray, 0.75);
  height: fit-content;
  width: fit-content;
  z-index: 99999;

  :global(.MuiDrawer-paper) {
    max-width: 1240px;
    max-height: 980px;
    width: 100%;
    height: 94%;
    margin: auto;
    top: 0;
    bottom: 0;
  }
}
