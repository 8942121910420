@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandSetupHeader {
  display: grid;
  grid-template-columns: 380px 2fr;
  margin-bottom: 2rem;
  @include small {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }

  .brandSetupNav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .groupFilters {
      display: flex;
      align-items: center;
      height: 32px;
      max-height: 32px;
      justify-content: flex-end;

      .groupFilterLinkWrapper {
        &:first-child {
          padding-right: 20px;
        }
        padding-left: 20px;
        border-left: 1px solid $gray;
        display: flex;
        align-items: center;
        gap: 0.25rem;

        &:first-child {
          padding-left: 0;
          border-left: none;
        }
      }

      .groupFilterLinkWrapperInactive {
        pointer-events: none;
      }

      .groupFilterLinkWrapperNoBox {
        padding-right: 0 !important;
      }

      .groupFilterLink {
        position: relative;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }

      .groupFilterLinkActive {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 98%;
          height: 5px;
          background: rgba(212, 110, 90, 0.5);
          left: -2px;
          bottom: 2px;
        }
      }
    }
  }

  .productName {
    position: relative;
    top: 6px;
    z-index: 5;

    font-family: $nunito;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 16.37px;
    letter-spacing: 0.4em;
    text-align: center;
    text-transform: uppercase;

    @include small {
      text-align: left;
    }
  }
}

.brandSetupCustomize {
  display: grid;
  grid-template-columns: 380px 2fr;
  height: 100%;
  flex: 1;

  @include small {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 8rem;
  }

  .brandSetupForms {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include small {
      margin-top: 1rem;
    }
  }

  .brandSetupImages {
    max-width: 480px;
    margin: auto;
    position: relative;
    top: -60px;

    @include small {
      max-width: 100%;
      position: initial;
      margin: auto;
    }
  }
}

.errorIcon {
  height: 14px;
  width: 14px;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $error;
  color: #fff;
  border: 1px solid $white;
  font-size: 10px;
}

.editedIcon {
  height: 14px;
  width: 14px;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $blue;
  color: #fff;
  border: 1px solid $white;
  font-size: 10px;

  .icon {
    height: 8px;
    width: 8px;
  }
}
