@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.dashboardView {
  margin-top: 1rem;
  .title {
    font-family: $georgia;
    font-size: 21px;
    font-weight: 700;
    line-height: 29.4px;
    text-transform: capitalize;
    color: $gray;
  }

  .tasksWrapper {
    padding: 24px 60px;
    background: #fcfcfc;
    border: 1px solid #0000001f;
    margin-top: 1.5rem;

    .tasksTitle {
      font-family: $georgia;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: $gray;
      text-align: center;
    }

    .taskCards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 32px;
      margin-top: 32px;

      @include small {
        display: flex;
        flex-direction: column;
      }
    }

    .taskCaption {
      font-family: $nunito;
      font-size: 14px;
      font-weight: 400;
      line-height: 20.01px;
      display: block;
      text-align: center;
      margin-top: 32px;
      color: $gray;
    }
  }

  .newsWrapper {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;

    @include small {
      display: flex;
      flex-direction: column;
    }
  }

  @include small {
    padding-bottom: 80px;
  }
}
