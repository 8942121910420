$peach: #d56f5b;
$peach-faded: #ffdfd8;
$faded-peach: rgba(213, 111, 91, 0.08);
$faded-peach: #f9e8e3;
$light-peach: #fff4f2;
$white: #fff;
$dark-gray: #333;
$gray: #5e5e5e;
$medium-gray: #979797;
$light-gray: #cccccc;
$lighter-gray: #fcfcfc;
$primary: #d46e5a;
$beige: #e7c1a9;

$light-blue: rgba(36, 96, 113, 0.3);
$blue: #246071;
$dark-blue: #284b54;
$error: #ab0000;
$dark-gray: #999999;

$orange: #ff9000;
$green: #00aa51;
$light-green: #e3fcef;

$success: #00aa51;
$success-light: #e3fcef;

$bg-blue-faded: #e9eff1;

$box-shadow: 0px 4px 4.7px rgba(0, 0, 0, 0.3);

$blue-gradient: linear-gradient(63.36deg, #246071 16.7%, #143f4b 83.3%);

$border: 1px solid rgba(0, 0, 0, 0.12);
