@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.boxContainer {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @include small {
    margin-top: 1.5rem;
  }
}

.logosContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  max-width: 736px;
  width: 100%;

  border: $border;
  background-color: $lighter-gray;
  padding: 24px;

  @include small {
    gap: 0;
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .title {
      font-size: 24px;
      font-weight: 600;

      @include small {
        font-size: 21px;
      }
    }

    .subtitle {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    gap: 1rem;
    width: 100%;
    height: 100%;

    @include small {
      padding-top: 1.5rem;
    }

    .logos {
      display: flex;

      justify-content: center;
      align-items: center;

      width: 160px;
      height: 180px;

      border: $border;
      gap: 1.5rem;

      img {
        width: calc(160px - 40px);
        height: calc(160px - 40px);
        object-fit: contain;
      }
    }

    .text {
      font-size: 10px;
      color: $gray;
      text-align: center;
      text-transform: uppercase;
      margin-top: 0.5rem;
    }
  }
}

.alertText,
.alertLink {
  color: $blue;

  @include small {
    font-size: 12px;
  }
}

.alertLink {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.mockupsButton {
  font-family: $nunito !important;
  color: $gray;

  @include small {
    padding: 0 !important;
    font-size: 12px;
  }
}

.darkBg {
  background-color: $gray;
}

.lightBG {
  background-color: rgba($gray, 0.1);
}

.footerTitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 1rem;

  @include small {
    font-size: 16px;
  }
}

.footerSubitle {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 1rem;
}
