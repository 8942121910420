@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.groupAccordion {
  .accordionHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .accordionTitle {
      text-transform: capitalize;
      margin: 0;
      font-size: 1.25rem !important;
      color: $gray;

      @include small {
        font-size: 1rem !important;
      }
    }

    .accordionSubTitle {
      text-transform: uppercase;
      font-size: 0.625rem;
      color: $gray;
      letter-spacing: 2px;
      margin-right: 16px;
    }
  }

  .accordionContent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 30px;

    @include xlarge {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @include large {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include medium-down {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include small {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      column-gap: 16px;
      row-gap: 32px;
    }

    @include xsmall {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  .groupFilters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 16px;

    @include small {
      row-gap: 16px;
      max-height: 100%;
      height: initial;
    }

    .groupFilterLinkWrapper {
      padding: 0 20px;
      border-left: 1px solid $gray;

      &:first-child {
        padding-left: 0;
        border-left: none;
      }
    }

    .groupFilterLink {
      position: relative;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }

    .groupFilterLinkActive {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 98%;
        height: 5px;
        background: rgba(212, 110, 90, 0.5);
        left: -2px;
        bottom: 2px;
      }
    }
  }
}

:global(.MuiAccordion-root) {
  background-color: $lighter-gray;
  border-radius: 0 !important;
  box-shadow: none;
  border: 1px solid #0000001f;

  &::before {
    display: none;
  }
}

:global(.MuiAccordionSummary-root) {
  padding: 16px 24px;
  height: 64px;
}

:global(.MuiAccordion-root.Mui-expanded) {
  margin: 0;
}

:global(.MuiAccordionSummary-content.Mui-expanded) {
  margin: 0;
}

:global(.MuiAccordionDetails-root) {
  padding: 0 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
