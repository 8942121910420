@import "styles/colors";
@import "styles/mixins";

.brandSetupCustomizeImages {
  .errorContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #e000004d;
    border: 3px dashed $error;
    z-index: 9999;

    .errorIcon {
      position: absolute;
      right: -12px;
      top: -12px;
      height: 20px;
      width: 20px;
      border-radius: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: $error;
      color: #fff;
      border: 1px solid $white;
    }
  }

  .imageContainer {
    position: relative;
    margin: auto;

    .logoBoundary {
      position: relative;
      display: inline-block;

      .logoBoundaryTopLeft,
      .logoBoundaryTopRight,
      .logoBoundaryBottomLeft,
      .logoBoundaryBottomRight {
        position: absolute;
        border: 3px solid $peach;
        width: 8px; // Size of corner
        height: 8px; // Size of corner
      }

      .logoBoundaryTopLeft {
        top: 0;
        left: 0;
        border-right: none;
        border-bottom: none;
      }

      .logoBoundaryTopRight {
        top: 0;
        right: 0;
        border-left: none;
        border-bottom: none;
      }

      .logoBoundaryBottomLeft {
        bottom: 0;
        left: 0;
        border-right: none;
        border-top: none;
      }

      .logoBoundaryBottomRight {
        bottom: 0;
        right: 0;
        border-left: none;
        border-top: none;
      }
    }

    .logoWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .logoContainerError {
      background-color: #e000004d;
      border-color: #e000004d;
    }

    .productImage {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }

    .productImageIsSize {
      opacity: 0.4;
    }

    .logoBorder {
      z-index: 99 !important;
      position: relative;
      border: 2px dashed $peach !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .logo {
      height: 100%;
      width: 100%;
      display: block;
      object-fit: contain;
      position: relative;
    }

    .logoSizeActive {
      box-shadow: 0px 0px 12px 0px #00000080;
    }

    .logoSizeActive {
      filter: grayscale(100%) !important;
    }
  }
  :global(.carousel .thumb) {
    border: 1.5px solid $light-gray !important;
    cursor: pointer;
  }

  :global(.carousel .thumb:focus) {
    border: 1.5px solid $gray !important;
  }

  :global(.carousel .thumb.selected) {
    border: 1.5px solid $gray !important;
  }

  :global(.carousel .thumb:hover) {
    border: 1.5px solid $gray !important;
  }

  :global(.carousel .thumbs) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }

  :global(.carousel .thumbs-wrapper) {
    margin: 0 !important;
  }

  @include small {
    :global(.carousel.carousel-slider) {
      max-height: 300px !important;
      height: 300px !important;
    }
  }

  img {
    pointer-events: all !important;
  }
}
