@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandPlateFileUpload {
  display: flex;
  gap: 16px;
}

.boxContainer {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border: $border;
  padding: 16px;
  gap: 16px;

  .description {
    font-family: $nunito !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.009px;
    text-align: justify;
  }

  .inProgressDescription {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $success;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  .error {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $error;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  .logoDropzone {
    border: 2px dashed $light-gray;
    padding: 16px;
    background: $lighter-gray;
    border-radius: 4px;
  }

  .dropArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: inherit;

    svg {
      width: 20px;
      height: 20px;
      fill: $blue;
    }
  }

  .dropzoneText {
    cursor: pointer;
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $blue !important;
  }
}

.filesContainer {
  display: flex;
  flex-direction: column;

  & p {
    color: #5e5e5e;
    font-family: Nunito Sans;
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1125rem;
    text-transform: uppercase;
  }
}

.fileContainerRow {
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;

  .fileName {
    color: #5e5e5e;
    font-family: Nunito Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: none;
    flex: 1;
  }

  .fileItem {
    flex: 1;
    color: #5e5e5e;
    font-family: Nunito Sans;
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1125rem;
    text-transform: uppercase;
  }

  .removeButton {
    padding: 0.125rem 0;
    justify-content: center;
    align-items: center;

    border: none;
    outline: none;
    background: none;
  }
}

.ctaSubmit {
  display: flex;
  justify-content: flex-start;
  padding: 9px 18px !important;

  width: fit-content;
  max-height: 40px;
  height: fit-content;
  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.152px !important;
  text-transform: uppercase;
}

.action {
  display: flex;
  gap: 1rem;

  @include small {
    flex-direction: column;
  }
}
