@import "styles/colors.scss";
@import "styles/mixins.scss";

.headerBar {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue;
  // z-index: 9999;
  position: fixed;

  top: 0px;
  color: $white;
  font-size: 12px;

  @include small {
    position: relative;
    width: 100%;
    height: 64px;
    flex-direction: column;
    gap: 5px;
    // top: calc(100vh - 64px);
  }
  @include small {
    display: none;
  }
}

.littleText {
  font-weight: 400;
  letter-spacing: 4.8px;

  @include small {
  }
}

.boldText {
  font-weight: 800;
  letter-spacing: 4.8px;
}
