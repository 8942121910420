@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.review {
  max-width: 800px;
  display: flex;
  flex-direction: column;

  .ctaButton {
    margin: 0 auto;
    max-width: 145px;
    max-height: 34px;
    width: 100%;
    height: 100%;

    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.152px;
    text-transform: uppercase;
    padding: 18px 9px;
  }

  .freeText {
    font-family: $nunito !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.009px;
    margin-bottom: 24px;
  }

  .title {
    font-family: $georgia;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $gray;

    @include small {
      margin: 460px 0 30px 0;
    }
  }

  .stackContainer {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin: 30px 0;

    @include small {
      flex-direction: column;
    }

    .stackItem {
      width: 100%;
      padding: 24px;
      display: flex;
      flex-direction: column;

      border: 1px solid rgba($color: #000000, $alpha: 0.2);
      background-color: $lighter-gray;

      @include small {
        width: auto;
      }

      .stackText {
        font-family: $nunito !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $gray;
        text-transform: uppercase;
        margin-bottom: 24px;
      }

      .stackTitle {
        font-family: $georgia !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: $peach;
        text-transform: uppercase;
      }

      .stackBox {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;

        min-height: calc(70px - 40px);

        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        padding: 20px 16px;
        gap: 16px;
        cursor: pointer;

        // @include small {
        //   flex-direction: row;
        // }

        .boxTitle {
          font-family: $georgia !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          color: $peach;
          width: 100%;
          text-align: left;
        }

        .boxPricing {
          display: flex;
          flex-direction: column;
          width: 100%;

          @include small {
            flex-direction: row;
          }
        }

        .oldCost {
          text-decoration: line-through;
          color: $peach;
          font-family: $georgia !important;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .currentCost {
          font-family: $georgia !important;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          color: $blue;
        }

        .warning {
          font-family: $georgia !important;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $blue;
          margin-top: 4px;
        }
      }

      .saveCost {
        font-family: $nunito !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
      }

      .active {
        border: 2px solid $blue; // Increase the border width to 2px on hover

        // Compensate for the increased border width by reducing padding
        padding: calc(20px - 1px) calc(16px - 1px); // Subtract the increased border width

        svg {
          position: absolute;
          top: 5px;
          right: 5px;
        }
      }
    }
  }
}

.mt-8 {
  margin-top: 8px;
}

.checkCircleIcon {
  color: $blue;
  width: 21px;
  height: 21px;
}
