@import "styles/colors.scss";
@import "styles/mixins";

.priceTable {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 0 24px;

  & > *:first-child {
    max-width: 280px !important;
    width: 100% !important;
  }
}

.priceTableMobile {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
}
