.App {
  position: relative;

  .toast-success-text,
  .toast-content-text,
  .toast-error-text,
  .toast-link {
    width: 100%;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $white;
  }

  .toast-error-text {
    display: flex;
    align-items: center;
  }

  .toast-success-text {
    margin-right: 3px;
  }

  // Toastify styles
  .Toastify__toast-container {
    display: flex;
    max-width: 368px;
    width: 100%;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    padding: 1rem 0.5rem;

    @include small {
      max-width: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $white;
    }
  }

  .Toastify__toast-icon {
    display: none;
  }

  .notifications__close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      opacity: 70%;
    }

    svg {
      width: 14px;
      height: 14px;
      // margin-right: 3px;
    }
  }

  //   .Toastify__toast-container--top-left {
  //   }
  //   .Toastify__toast-container--top-center {
  //   }
  //   .Toastify__toast-container--top-right {
  //   }
  //   .Toastify__toast-container--bottom-left {
  //   }
  //   .Toastify__toast-container--bottom-center {
  //   }
  //   .Toastify__toast-container--bottom-right {
  //   }

  //   /** Classes for the displayed toast **/
  //   .Toastify__toast {
  //   }
  //   .Toastify__toast--rtl {
  //   }
  //   .Toastify__toast--dark {
  //   }
  //   .Toastify__toast--default {
  //   }
  //   .Toastify__toast--info {
  //   }

  .Toastify__toast--success,
  .Toastify__toast--error {
    display: flex;
    width: 100%;
    align-items: center;

    position: absolute;
    top: 64px;
    right: 0;
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 14px;
    font-weight: 600;

    color: $white;

    @include small {
      top: 80px;
      margin: 0 auto;
    }

    .Toastify__toast-body {
      display: flex;
      align-items: center;
      text-align: start;

      margin-right: 1rem;
    }

    // .Toastify__toast-icon {
    // }
  }

  .Toastify__toast--success {
    background-color: $blue;
  }

  .Toastify__toast--error {
    background-color: $error;

    svg {
      width: 20px;
      height: 20px;
      fill: $white;
      margin-right: 3px;
    }
  }

  //   /** Classes for the progress bar **/
  //   .Toastify__progress-bar {
  //   }
  //   .Toastify__progress-bar--animated {
  //   }
  //   .Toastify__progress-bar--controlled {
  //   }
  //   .Toastify__progress-bar--rtl {
  //   }
  //   .Toastify__progress-bar--default {
  //   }
  //   .Toastify__progress-bar--dark {
  //   }
}
