.templateLogin {
  display: flex;
  background-image: url('../../assets/images/onboarding_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .card {
    padding: 100px;
    display: flex;
    width: 100vh;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;
  }

  

  .blankaLogo {
    width: 150px;
    height: auto;
    padding: 10px;
    margin-left: 2px;
  }

  .welcomeContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;
  }

  .headerTextContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
  }

  .resetPasswordContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-right: 150px;
    padding-top: 5px;
    padding-bottom: 15px;
  }

  .formContainer {
    display: flex;
    justify-content: center;
    padding-top: 35px;

    .form {
      display: flex;
      flex-direction: column;
    }
  }

  .controllerContainer {
    width: 100%;
    padding-top: 30px;
  }

  .text {
    &Link {
      font-size: 16px;
      cursor: pointer;
      text-decoration: underline;
    }
    &Reset {
      font-size: 9px;
    }
    &Notice {
      max-width: 300px;
      padding-top: 45px;
    }

    &Error {
      margin: 0 auto;
      position: absolute;
      left: 0px;
      bottom: -20px;
    }
  }
}

.MuiOutlinedInput-root {
  border-radius: 0px;
}

.loginButtonWrapper{
  position: relative;
  width: 100%;

  .MuiButton-root{
    width: 100%;
  }
}

.textLink{
  font-size: 16px;
}