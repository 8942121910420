@import "styles/colors";
@import "styles/text.scss";
@import "styles/mixins";

.passwordStrengthBar {
  position: relative;
  margin-bottom: 5px;
  width: 100%;
}

.labelTextTop {
  position: absolute;
  top: -20px;
  right: 0px;
  margin-top: 0px !important;
}

.labelTextBottom {
  position: absolute;
  top: 10px;
  right: 0px;
  margin-top: 0px !important;
}

.flexContainer {
  display: flex;
  margin-top: 25px !important;

  .labelPasswordFeedback {
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;

    line-height: normal;

    text-align: justify;
    color: $blue;
  }

  .iconPasswordFeedback {
    color: $white;
    stroke: $blue;
    width: 12px;
    height: 14px;
    margin-right: 5px;
  }
}
