@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.progressBarContainer {
  margin-top: 20px;

  & + & {
    margin-top: 30px;
  }

  .flexContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .linearBar {
    border: 1px solid $light-gray;
    border-radius: 5px;
    background-color: $white;
    height: 8px;

    .error {
      color: $error;
    }

    .success {
      color: $success;
    }
  }

  .mt {
    margin-top: 5px;
  }

  .mb {
    margin-bottom: 5px;
  }
}

.badgeIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: $error;
  background-color: $white;
}

.exposeNumber {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.009px;
  color: $gray;
}
