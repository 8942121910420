@import "styles/colors.scss";
@import "styles/mixins";

.onboardingStepOne {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 0%;

  .signUpText {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .text {
    &Login {
      font-size: 12px;
      cursor: pointer;

      text-decoration: none;
      width: fit-content;

      span {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.redText {
  color: $peach;
  font-weight: 700;
}

@include large {
  .onboardingStepOne {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }
}
