@import 'styles/colors';
@import 'styles/mixins';

.onboardingStepFour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
 

  a:hover{
    text-decoration: none;
  }
}

.storesContainer {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  flex-wrap: wrap;
 
}

.text {
  &ShopType {
    margin-top: auto !important;
  }

  &Redirect {
    cursor: pointer;
    text-decoration-color: $gray;
    margin-left: 5px;
  }

  &ComingSoon {
    font-weight: bold;
    visibility: hidden;
    position: absolute;
    text-transform: uppercase;
    left: 22px;
  }
}

.storeContainer {
  min-height: 120px;
  border: 1px solid #EDEDED;
  border-radius: 2px;
  background-color: #F7F7F7;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
  margin-right: 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.10);
  cursor: pointer;



  img {
    padding: 13px 30px;
    align-self: center;
    max-width: 125px;
    margin-top: auto !important;
    @include small{
      padding: 5px 10px;
    }
  }
  p{
    padding-bottom: 10px;
  }

  @include small{
    width: 46%;
    margin: 5px;
   }

}

.storeContainerActive:hover {
  background-color: $blue;
  
  .text {
    &ShopType {
      color: $white;
    }
  }
}

.storeContainerInactive:hover {
  background: #5E5E5E;
  opacity: 0.73;

  cursor: default;

  img {
    opacity: 0.1;
  }

  .text {
    &ComingSoon {
      visibility:visible;
    }

    &ShopType {
      color: $medium-gray;
    }
  }
}

.noticeContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.loading{
  padding: 75px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}