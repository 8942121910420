@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandSetupReplaceBrandModal {
  display: flex;
  flex-direction: column;

  max-width: 415px;
  width: 100%;
  min-height: auto;

  gap: 2rem;

  .title {
    font-family: $georgia !important;
    font-size: 21px;
    font-weight: 700;

    text-align: center;
    color: $gray;
    margin-bottom: 16px;

    @include small {
      margin-top: 1rem;
    }
  }

  .info,
  .link {
    font-family: $nunito !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: $gray;
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
  }

  .productCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 1rem 1.5rem;

    width: calc(100% - 3rem);
    height: auto;

    border: $border;
    background-color: $lighter-gray;

    .productInfo {
      font-family: $georgia !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;

      line-height: 24px;
      color: $gray;
    }
  }
}
