@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.ordersViewContent {
  @include small {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.orderSkeletonContainer {
  width: 100%;
  margin-bottom: 50px;
}

.mobileFlexContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapperOrderDetailsCta {
  display: flex;
  align-items: center;

  .backBtn {
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
      fill: $gray;
    }
    cursor: pointer;
  }

  .text {
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    line-height: normal;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    margin: 0 auto;

    color: $gray;
  }
}
