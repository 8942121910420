@import "styles/mixins";
@import "styles/colors";
@import "styles/text";

.leftSide,
.rightSide {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.leftSide {
  background-color: $beige;
  background-image: url("/assets/images/onboarding_handbackground.jpg");
  background-position: center right;
  background-size: cover;

  @include small {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    width: 100%;
    height: 150px;

    background-position-y: 210px;
  }
}

.rightSide {
  position: relative;

  justify-content: center;
  align-items: center;

  @include small {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    justify-content: flex-start;
  }

  .userUI {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 430px;
    width: 100%;

    height: 80%;
    gap: 24px;
    position: relative;
    z-index: 2;

    @include small {
      // max-width: 320px;
      width: auto;
      padding: 54px 24px 24px;
    }

    .title {
      font-family: $georgia !important;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      color: $blue;
      line-height: 140%;

      text-align: center;

      @include small {
        font-size: 21px;
      }
    }

    .customDropdown {
      display: flex;
      align-items: center;

      max-width: calc(430px - 16px);
      width: 100%;

      height: 40px;
      padding: 0 16px 0 2px;

      border: 1px solid $gray;
      background: $white;

      @include small {
        max-width: calc(320px - 16px);
        width: 100%;
      }

      * {
        &::after {
          content: none;
        }
      }
    }

    .otherMessage {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      gap: 4px;

      .txt,
      .txtCounter {
        font-family: $nunito !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $gray;
        visibility: hidden;
      }

      .txtCounter {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }

      .input {
        background-color: transparent;
        height: 16px;
        padding: 16px;
        border: 1px solid $gray;
        height: 40px;
        padding: 0 16px;
        visibility: hidden;
        &:focus,
        &:visited,
        &:active,
        &:hover {
          outline: none;
        }
      }

      .visible {
        visibility: visible;
      }
    }
  }

  .skip {
    position: absolute;
    bottom: 80px;
    right: 33px;

    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;

    color: $gray;

    &:hover {
      cursor: pointer;
    }

    @include small {
      margin: auto;
      right: initial;
      bottom: 40px;
    }
  }
}

.blankaLogo {
  max-width: 150px;
  margin: 20px auto;

  @include small {
    width: 80px;
    height: 20px;
    margin: 0 0 0 24px;
  }
}

.quote {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 200px;
  position: relative;
}

.quoteText {
  font-weight: 400;
  text-align: center;
}

.quoteAuthor {
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
  text-transform: uppercase;
}

.closeQuote {
  position: absolute;
  bottom: 20px;
  right: 0px;
  font-size: 20px;
  color: $gray;
  cursor: pointer;
}
