@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.discontinuedTag {
  position: absolute;
  top: 20px;
  left: 11px;
  display: flex;
  flex-direction: column;
  color: $white;
  background-color: rgba($color: #000000, $alpha: 0.6);
  border-radius: 8px;
  padding: 4px 8px;
  gap: 4px;

  @include small {
    flex-direction: row;
    top: -10px;
    left: 20px;
  }

  .text {
    width: 100%;
    font-family: $nunito !important;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;

    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;

    @include small {
      width: max-content;
      overflow: visible;
    }
  }
}
