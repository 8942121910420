@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.trackingContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.colorSwatch {
  width: 50px;
  height: 50px;
}

.productImage {
  max-width: 80px;
  img {
    width: 80px;
    border: 1px solid $light-gray;
  }
}

.boxContainer {
  display: block;
  width: 330px;
  margin-left: auto;
  margin-right: 16px;
}

.boxDiscountTag {
  display: flex;
  align-items: end;
  justify-content: end;

  & > * {
    font-family: $nunito;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
  }
}

.cost {
  font-family: $nunito !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $blue;
  text-align: end;
  margin: 0px !important;

  &NoDiscount {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: line-through;
    margin: 5px 0 !important;
    text-align: end;
  }
}

.cell {
  display: flex;
  align-items: center;
  width: 100%;
}

.title {
  font-family: $nunito !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $gray;
}

.peachText {
  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $peach;
  margin-top: 4px;
}

.appendedHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  background-color: $lighter-gray;
  // border: 1px solid $light-gray;
  border: 1px solid #5e5e5e;
  border-bottom: none;

  @include small {
    gap: 32px;
    flex-direction: column;
  }
}

.backBtn {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  cursor: pointer;

  font-family: $nunito !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-align: left;
  color: $gray;
  text-transform: uppercase;

  svg {
    width: 16px;
    height: 16px;
  }
}

.hide {
  display: none;
}

.cardContent {
  padding: 0;
  margin-bottom: 16px;
}

.appendedHeader {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin-top: 16px;
  background-color: $lighter-gray;
  border: 1px solid #979797;
  border-bottom: none;

  @include small {
    display: flex;
    gap: 32px;
    flex-direction: column;
    padding: 0;

    border: none;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $light-gray;

  margin: 16px 0;
}
