@import "styles/colors";
@import "styles/text";
@import "styles/mixins";

.cartProductStatusModal {
  display: flex;
  gap: 35px;
  max-width: 375px;
  width: 100%;

  @include small {
    gap: 20px;
    padding: 30px !important;
  }

  .title {
    color: $gray;

    font-family: $georgia !important;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .message {
    color: $gray;

    font-family: $georgia;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .cta {
    font-family: $nunito !important;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2px;
  }

  @include small {
  }
}
