@import "styles/colors";
@import "styles/text";
@import "styles/mixins";

.dropdownWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 100%;
  padding: 2px 9px;
  border: 1px solid $light-gray;
  border-radius: 4px;
}

.selectContainer {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.selectFormControl {
  width: auto;
  background: transparent;

  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  color: $gray;
  letter-spacing: 1.152px;
  appearance: none;
  // padding-right: 20px; // Added padding to avoid text overlap with icon
  padding: 16px;

  &:focus,
  &:visited,
  &:active,
  &:hover {
    color: inherit;
  }
}

.dropdownIcon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: translateY(-50%) rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: -2px;
  display: flex;
  width: 100%;

  padding: 16px 0 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid $light-gray;
  background: white;
  box-shadow: 0px 4px 4px 0px rgba(204, 204, 204, 0.3);
}

.optionItem {
  position: relative;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  width: 100%;
  color: $gray;

  span {
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background: rgba(230, 136, 128, 0.9);
      opacity: 0;
      transition: opacity 0.3s ease; // transition for smooth hover effect
    }

    &:hover::after,
    &.selected::after {
      opacity: 0.5;
    }
  }
}
