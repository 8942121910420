@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.accountAddressesView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid $light-gray;
  background-color: $white;
  height: auto;

  @include small {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    padding: 16px;
  }

  .leftSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;

    .username,
    .company {
      font-family: $nunito !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .addressAndPhone {
      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $gray;
    }

    .date {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $medium-gray;
    }
  }

  .rightSide {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;

    .selector {
      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.009px;
      text-decoration-line: underline;
      color: $gray;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
