@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandSetupMockupsCheckoutView {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 80px;

  @include small {
    display: block;
    height: 100%;
  }

  .leftSide {
    .title {
      font-family: $georgia !important;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;

      color: $gray;

      @include small {
        margin-top: 1rem;
      }
    }

    .subsection1 {
      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      color: $gray;
      margin-top: 0.5rem;
    }
  }

  .gridWrapper {
    display: grid;
    grid-template-columns: repeat(2, 276px);
    gap: 1rem;

    @include small {
      height: 100%;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      margin-bottom: 5rem;
      gap: 0.5rem;
    }
  }

  .rightSide {
  }

  .bottomNavigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 16px 10%;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.backNav {
  cursor: pointer;
  .backNavIcon {
    font-size: 14px;
    color: $gray;
  }

  @include small {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.link {
  padding-top: 12px;
  font-weight: 600;
  line-height: 12px;
  text-decoration-line: underline;
  cursor: pointer;

  &:hover {
    color: $peach;
  }
}
