@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.UpgradePlanNotification {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $error;
  background-color: rgba($color: $error, $alpha: 0.05);
  border-radius: 3px;
  // padding: 7px 4px;
  padding-top: 8px;
  padding-bottom: 10px;
  margin-bottom: 20px;

  width: 100%;
  // margin-bottom: 20px;

  @include small {
    position: relative;
    display: block;
    padding-left: 8px;
    padding-right: 8px;

    margin-top: 20px;
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-family: $nunito !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.2em;
    text-align: left;
    text-transform: uppercase;
    // margin-bottom: 12px;

    @include small {
      padding-top: 5px;
      padding-left: 8px;
      width: 100%;
      justify-content: flex-start;
    }
  }

  .errorTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: $nunito !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: $error;
    margin-left: 8px;

    @include small {
      display: block;
      font-size: 12px;
    }
  }

  .error {
    margin-right: 10px;
    color: $error;

    // @include small {
    //   margin-right: 5px;
    // }
  }
}

.reinforce {
  margin-left: 3px;
  text-decoration: underline;
  font-weight: 700;
  margin-right: 3px;
  cursor: pointer;
  color: $error;
}

.red {
  color: $peach;
}
