@import "styles/mixins";
@import "styles/text";

.sideBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  min-height: 100vh;
  background-image: url("/assets/images/bg-ocean.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  @include small {
    width: auto;
    min-height: auto;
    padding: 2rem;
    padding-bottom: 4rem;
  }
}

.sideBox,
.onboardingStepThreeB {
  @include medium {
    min-width: 500px;
  }

  .paymentContainer {
    max-width: 540px;
    margin: auto;
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include medium {
      padding: 4rem;
    }

    @include small {
      padding: 1rem;
    }
  }
}

.onboardingStepThreeB {
  max-height: 100vh;
  overflow: auto;
  position: sticky;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  display: flex;

  @include medium {
    position: initial;
    overflow: initial;
  }

  @include small {
    width: 100%;
    position: initial;
  }
}

.paymentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0 0 40px 0px;
}

.upsellContainer {
  display: flex;
  width: 100%;

  text-align: center;
  text-align: center;
  justify-content: space-between;

  .title {
    font-family: $georgia !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
    color: $peach;
  }

  .subtitle {
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 8px;
  }
}

.divider {
  margin-top: 16px;
  margin-bottom: 16px;
}

.stripeContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.submitButton {
  margin-top: 20px;
}

.loading {
  padding: 75px 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.poweredBy {
  width: 120px;

  @include small {
    padding-bottom: 40px;
  }
}

.poweredByContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  a {
    margin-bottom: 5px;
    font-size: 9px;
  }
}

.planDetails {
  display: flex;
  justify-content: space-between;

  margin-bottom: 25px;
}

.planDetailsContainer {
  min-width: 250px;
  margin: 0 auto;
  width: 100%;

  @include small {
    width: 93%;
  }
}

.onBoardingTermsAndConditions {
  font-size: 10px;
  font-family: $nunito;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20px 0;
  max-width: 220px;
  text-align: center;
}

.payment {
  font-family: $nunito !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;

  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: start;
  color: $gray;

  padding-left: 15px;
  padding-top: 40px;
}

.errorMessage {
  font-family: $nunito !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: red;
  margin-bottom: 12px;
}
