@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.dashboardTaskCardDone {
  background-color: #e9eff1 !important;
  border-color: transparent !important;

  .step {
    filter: grayscale(100%) !important;
    opacity: 0.4 !important;
  }

  .title,
  .icon,
  .content {
    filter: grayscale(100%) !important;
    color: $blue !important;
    opacity: 0.4 !important;
  }

  .doneOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .doneIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      background-color: $blue;
      width: 56px;
      height: 56px;
      border-radius: 100%;
      z-index: 12;

      @include small {
        width: 32px;
        height: 32px;
      }

      svg {
        position: relative;
        top: 11px !important;
        left: 9px !important;

        height: 40px;
        width: 40px;

        @include small {
          width: 24px;
          height: 24px;

          top: 7px !important;
          left: 6px !important;
        }
      }
    }
  }
}

.dashboardTaskCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include small {
    align-items: center;
  }

  .dashboardTaskCardContent {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include small {
      flex-direction: row;
      column-gap: 12px;
    }
  }

  border: 1px solid #cccccc;
  background-color: $white;
  box-shadow: 0px 4px 7px 0px #0000000d;
  padding: 24px;
  padding-top: 32px;
  padding-bottom: 40px;
  border-radius: 4px;

  text-align: center;
  gap: 16px;
  position: relative;

  @include small {
    padding: 12px;
    display: flex;
    text-align: left;

    .title {
      margin: 0 !important;
    }
  }

  .step {
    background-color: $peach;
    color: $white;
    font-family: $nunito;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
    padding: 3px 6px;
    border-radius: 100px;
    position: absolute;
    top: -9px;
    left: 50%;
    transform: translateX(-50%);
  }

  .icon {
    opacity: 0.4;
  }

  font-family: $nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.1px;
  color: $gray;

  .title {
    font-family: $georgia;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: $peach;
    margin: 16px 0;
  }

  .content {
    p {
      margin: 0;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      &:hover {
        color: $peach;
        cursor: pointer;
      }
    }
  }
}
