@import "styles/colors.scss";
@import "styles/mixins.scss";

.card {
  padding: 40px;
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  background-color: $lighter-gray;
  border: 0.5px solid #979797;

  .upsellContainer {
    flex-grow: 1;
    background-image: url("/assets/images/checkout-upsell-background.png");
    background-size: cover;
    padding: 50px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 900px;
    height: 300px;
    margin: 0 auto;
    margin-top: 25px;
    ul li {
      color: $white;
    }
  }
}

@include small {
  .card {
    margin-top: 100px;
    padding: 20px;
    height: 488px;

    .upsellContainer {
      padding: 98px 15px;
      margin: auto;
    }

    .btnFont {
      font-size: 12px;
    }
  }
}
