@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.innerStepper {
  padding-left: 40px;
  border: none;

  .stepperLabel {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .step {
      padding: 10px 0;

      .stepLabel {
        font-family: $nunito;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: $light-gray;
        margin-left: 12px;
      }
    }
  }
}
