@import "styles/colors";
@import "styles/text";
@import "styles/mixins";

.unavailableProductNotification {
  position: absolute;
  left: -220px;
  width: 100vw !important;

  &.slideIn {
    animation: slideIn 0.5s ease-in-out forwards;
  }

  &.slideOut {
    animation: slideOut 0.5s ease-in-out forwards;
  }

  @keyframes slideIn {
    0% {
      top: -100px;
    }
    100% {
      top: -32px;
    }
  }

  @keyframes slideOut {
    0% {
      top: -32px;
    }
    100% {
      top: -100px;
    }
  }

  display: flex;
  align-ites: center;
  justify-content: center;
  gap: 10px;

  padding: 10px;
  color: $white;
  background-color: $gray;
  font-family: $nunito !important;

  z-index: 10000;

  .title {
    display: flex;
    align-items: center;

    margin-right: 5px;
    text-align: left;

    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;

    svg {
      font-weight: bold;
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
  }

  .text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .link {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.924% */
    text-decoration-line: underline;
    color: $white;
    cursor: pointer;
  }

  .closeIcon {
    position: absolute;
    display: flex;
    top: 8px;
    right: 30px;
    cursor: pointer;
  }

  @include small {
    top: 0;
    left: 0;

    flex-direction: column;
    align-items: flex-start;
    padding: 15px;

    @keyframes slideIn {
      0% {
        top: -160px;
      }
      100% {
        top: -80px;
      }
    }

    @keyframes slideOut {
      0% {
        top: -80px;
      }
      100% {
        top: -160px;
      }
    }

    .title {
      margin-bottom: 10px;
      font-size: 14px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .text {
      font-size: 16px;
      line-height: 24px;
    }

    .closeIcon {
      top: 10px;
      right: 10px;
    }
  }
}
