@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.productImg {
  width: 100%;
  max-width: 181px;
  height: 100%;
  max-height: 180px;
  margin: 0 auto;
  display: block;
  margin-bottom: 28px;
}

.blankaLabsContainer {
  background-image: url("/assets/images/bl_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;

  @include small {
    margin-top: 40px;
  }
}

.stepCard {
  display: flex;
  margin-bottom: 20px;
  @include small {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.alignCenter {
  display: flex;
  justify-content: center;
}

.stepLeft {
  width: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 16px;
  svg {
    margin-top: 15px;
    width: 35px;
    height: 35px;
    @include small {
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
}
.stepRight {
  width: 75%;
}

.stepList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style-type: none;
  padding-left: 0px;
  li {
    padding: 5px;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 600;
  }
  li {
    padding-left: 0px;
    padding-right: 10px;
  }
  @include small {
    justify-content: center;
    align-items: center;
    ul {
      text-align: center;
    }
  }
}

.logo {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  margin-bottom: 32px;

  display: block;
}

.infoBox {
  max-width: 400px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 50px;
  p,
  h1 {
    margin-bottom: 24px;
  }
  @include small {
    margin: 50px 0px;
  }
}

.textNumber {
  font-weight: bold;
  font-size: 14px;
  font-family: $georgia;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  margin-top: 64px;

  @include small {
    flex-direction: column-reverse;
    align-items: center;
  }

  .logoBox {
    width: 100%;
    max-width: 256px;
    height: 100%;
    max-height: 140px;
    border-radius: 4px;

    border: 1px solid rgba($color: #000, $alpha: 0.2);
    padding: 58px 31px calc(58px - 32px) 31px;

    @include small {
      margin: 40px 0;
    }
  }

  .textContainer {
    align-self: center;
    padding: 0 32px;

    @include small {
      text-align: center;
    }

    .title {
      font-family: $georgia !important;
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      color: $gray;
    }

    .subtitle {
      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      margin-top: 16px;
      color: $gray;
    }
  }
}
