@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.cartDesktopView {
  display: flex;
  flex-direction: column;
  .card {
    background-color: $lighter-gray;
    border: 0.5px solid $medium-gray;
    border-radius: 0px;

    table {
      border: none;
    }
  }

  .actionContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }

  .footerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .adjustCartTotals {
    width: 333px;
    margin-left: auto;
    padding-right: 10px;
  }

  td {
    padding-top: 20px !important;
    padding-bottom: 30px !important;

    &:first-child {
      padding: 20px 16px 30px 24px !important;
    }
    &:last-child {
      padding-right: 16px !important;
    }
  }
}
