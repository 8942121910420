@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.myLogoStepperProgress {
  position: relative;
  padding-top: 33px;
  padding-left: 20px;
  padding-right: 20px;
  height: fit-content;

  @include small {
    height: fit-content;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .modalCloseButton {
    display: inline-block;
    position: absolute;
    text-transform: uppercase;
    color: #5e5e5e;
    font-size: 8px;
    font-weight: 700;
    letter-spacing: 2.4px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    &:hover {
      color: $peach;
    }
  }

  .textTitle {
    font-family: $georgia;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $blue;

    @include small {
      margin-bottom: 10px;
    }
  }

  .divider {
    border: 1px solid $light-gray;
    margin: 20px 0;
  }

  .stepContainer {
    justify-content: space-between;

    @include small {
      flex-direction: column-reverse;
      align-items: center;
      height: 100%;
    }

    .leftSection {
      max-width: 515px;
      width: 100%;
      max-height: 282px;
      height: 100%;

      @include small {
        width: 100%;
        height: 100%;
      }

      .warningText {
        font-family: $nunito;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray;
        padding-top: 20px;
      }

      .warningList {
        margin: 0;
        padding: 0;
        font-family: $nunito;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $gray;
        margin: 20px 0;
        list-style-type: none;
        counter-reset: li;

        @include small {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: 600;
        }

        li {
          counter-increment: li;
          position: relative;
          padding-left: 30px; // padding to the left of the text to accommodate the number
          margin-bottom: 10px;

          @include small {
            display: flex;
            align-items: center;
            align-content: center;
          }

          &::before {
            content: counter(li);
            position: absolute;
            left: 0;
            top: 0;
            background: $peach; // color of the background circle
            color: white; // color of the number
            border-radius: 50%; // make the background circle round
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            // font-family: $georgia !important;
            font-family: $nunito !important;

            @include small {
              top: 50%;
              left: 10px;
              transform: translate(-50%, -50%);
              font-size: 10px;
            }
          }
        }
      }

      .sectionFAQ {
        font-family: $georgia;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: $blue;
        padding-top: 20px;
        padding-bottom: 3px;

        @include small {
          text-align: center;
          height: 100%;
        }
      }

      .faq {
        font-family: $nunito;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $gray;

        @include small {
          text-align: center;
          height: 100%;
        }
      }

      .logoImage {
        max-width: 260px;
        max-height: 274px;
        width: 100%;
        height: 100%;
        object-fit: contain;

        @include small {
          width: 100%;
          height: 100%;
        }
      }
    }

    .rightSection {
      max-width: 260px;
      width: 100%;
      max-height: 272px;
      height: 100%;

      @include small {
        margin-top: -30px;
      }

      .logoImage {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .sectionTitle {
    font-family: $georgia !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 27.27px;
    color: $blue;
    padding-bottom: 20px;
  }

  .sectionSubTitle {
    font-family: $nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: $blue;

    @include small {
      font-size: 13px;
    }
  }

  .sectionLead {
    font-family: $nunito;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $blue;
  }

  .backBtn {
    font-family: $nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;

    display: flex;
    align-items: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $blue;

    svg {
      margin-right: 5px;
    }
  }

  .btnStyle {
    font-family: $nunito;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: 0.096em;
    text-transform: uppercase;
    min-width: 240px;
  }

  @include small {
    .stepperLabel {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      justify-content: flex-start;
    }

    .stepperTitle {
      color: $light-gray;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.4em;
      text-transform: uppercase;
    }

    .stepperSubTitle {
      color: $light-gray;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }

    .brandingTipsContainer {
      background: linear-gradient(63.36deg, #246071 16.7%, #143f4b 83.3%);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      @include small {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 24px;
        height: 100px;
      }

      .title {
        font-family: $georgia;
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 24px;
        color: $white;
      }

      .subTitle {
        font-family: $nunito;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $white;
      }
    }

    .helpIconStyling {
      fill: $white;
      width: 52px;
      height: 52px;
    }
  }
}
