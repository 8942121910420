@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.brandSetupLogoExampleModal {
  max-width: 600px;
  width: 100%;
  max-height: 494px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 16px;

  @include small {
    gap: 0px;
  }

  .title {
    font-family: $georgia !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    color: $gray;
  }
}

.logoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

  width: 100%;
  padding: 24px;

  @include small {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 0px;
  }

  .logoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    justify-content: center;

    .label {
      font-family: $nunito !important;
      font-size: 8px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: center;
      width: 220px;
      min-height: 14px;

      color: $gray;
    }

    .logoWrapper {
      position: relative;
      background-color: $white;
      border-radius: 4px;

      &.correct {
        border-color: $success;
      }

      &.incorrect {
        border-color: $error;
      }

      .logo {
        width: 220px;
        height: 87px;
        object-fit: contain;
      }

      .icon {
        position: absolute;
        top: -16px;
        right: -16px;
        width: 32px;
        height: 32px;
      }
    }

    .description {
      font-family: $nunito !important;
      font-size: 8px;
      font-style: normal;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 1px;
      width: 220px;

      min-height: 16px;

      &.correctText {
        color: $success;
      }

      &.incorrectText {
        color: $error;
      }
    }
  }
}
