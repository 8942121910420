@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins";

.newLoadingScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: $white;
  gap: 180px;

  .quoteContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 720px;
    width: 100%;

    // border: 1px solid red;
    // padding: 0 10px;

    .openQuote {
      position: absolute;
      top: -12px;
      left: -5px;
      z-index: 1;
    }

    .closeQuote {
      position: absolute;
      bottom: -12px;
      right: -5px;
      z-index: 1;
      transform: rotate(180deg);
    }

    .text {
      font-family: $nunito !important;
      font-size: 16px;
      font-style: italic;
      font-weight: 400;

      line-height: normal;
      text-align: center;
      color: $blue;
      z-index: 10;
    }
  }
}
