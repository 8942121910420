@import "styles/colors";
@import "styles/text";
@import "styles/mixins";

.commonLayout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;

  @include small {
    padding: 20px;
  }

  .commonLayoutContent {
    margin: 100px 0;
    margin-bottom: 40px;
  }

  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid $light-gray;
    margin: 0 24px;
    height: 60px;

    .logo {
      width: 65px;
      height: auto;
    }

    .backButton {
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      left: 30px;
      cursor: pointer;

      &:hover {
        .text,
        svg {
          color: $peach !important;
        }
      }

      @include small {
        top: 24px;
        left: 0px;
      }

      svg {
        width: 16px;
        height: 16px;
        color: $gray;
      }

      .text {
        font-family: $nunito !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        margin-left: 10px;

        @include small {
          display: none;
        }
      }
    }
  }

  .ctaContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }

  .ctaText {
    font-family: $nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.4px;
    color: $gray;
    text-transform: uppercase;
    text-decoration: none;
  }

  .ctaBtn {
    display: flex;
    padding: 9px 18px;
    justify-content: center;
    align-items: center;
    height: 40px;
    letter-spacing: 2.4px;
  }

  .cancelSubscriptionButton {
    font-family: $nunito;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.37px;
    text-align: left;
    text-decoration-line: underline;
    color: #979797;
    text-transform: initial;
    letter-spacing: initial !important;
    padding: 0 !important;

    &:hover {
      color: $peach;
    }
  }
}

.removePaddingLeft {
  padding-left: 0 !important;
}

.formatkeepCurrentPlanLink {
  padding: 0;
  margin: 30px;
}
