@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandSetupUpload {
  height: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;

  &Header {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 80px;

    @include small {
      grid-template-columns: 1fr;
      gap: 0;
      margin-top: 1rem;
    }

    .boxContainer {
      @include small {
        margin-bottom: 1.5rem;
      }
    }

    .title {
      font-family: $georgia !important;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;

      color: $gray;
    }

    .subsection1 {
      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      color: $gray;
      margin-top: 0.5rem;
    }

    .subsection2 {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      color: $gray;
      margin-top: 1rem;
    }

    .link {
      font-weight: 600;
      line-height: 12px;
      text-decoration-line: underline;
      cursor: pointer;

      &:hover {
        color: $peach;
      }
    }
  }

  &Title {
    display: flex;
    align-items: center;
    gap: 1rem;

    font-family: $nunito !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-top: 3rem;
    margin-bottom: 1rem;

    @include small {
      margin-top: 2rem;
    }
  }

  &Content {
    display: flex;

    max-width: calc(621px - 42px);
    width: 100%;

    border: $border;
    background-color: $lighter-gray;

    padding: 20px;
    margin-bottom: 2rem;
    gap: 2rem;

    @include small {
      flex-direction: column;
      gap: 1rem;
      height: 100%;
      margin-bottom: 5rem;
    }
  }

  .desktopCta {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .mobileCta {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 16px 10%;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
