@import "styles/colors";
@import "styles/mixins";

.inProgressContent {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .inProgressAlert {
    margin-top: 1rem;
    background-color: #2460711a;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imagesWrapper {
    position: relative;

    @include small {
      display: flex;
      flex-direction: column;
    }
  }

  .imagesDescription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin-left: 200px;
    margin-top: 2rem;

    @include small {
      margin-left: 0;
      margin-top: 200px;
      align-items: inherit;
      text-align: center;
    }
  }

  .mockupImageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    position: absolute;
    opacity: 0;
    animation: fadeInOut 16s infinite ease-in-out;

    @include small {
      left: 24%;
    }

    img {
      -webkit-user-drag: none;
    }

    &:nth-child(1) {
      opacity: 1;
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 4s;
    }

    &:nth-child(3) {
      animation-delay: 8s;
    }

    &:nth-child(4) {
      animation-delay: 12s;
    }
  }

  .mockupImage {
    width: 140px;
    height: 100%;
    object-fit: contain;
  }

  .mockupLogoImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .mockupLogoDefault {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    max-width: 40px;
    max-height: 40px;
    width: 100%;
    height: 100%;
    top: 58px;
    left: 47px;
  }

  .mockupLogoInverted {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    max-width: 24px;
    max-height: 24px;
    width: 100%;
    height: 100%;
    top: 62px;
    left: 52px;
  }

  .mockupLogoThin {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 56px;
    height: 20px;
    top: 80px;
    left: 40px;
  }

  .mockupLogoInvertedThin {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    max-width: 42px;
    max-height: 4px;
    width: 100%;
    height: 100%;
    top: 88px;
    left: 70px;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
