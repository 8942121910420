@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.featureUsage {
    margin: auto;

    .ctaBtn {
        margin: 0 auto;
        margin-top: 25px;
    }

    .freeText {
        font-family: $nunito !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.009px;
        margin-bottom: 24px;
    }

    .stackContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        gap: 24px;

        @include small {
            flex-direction: column;
        }

        .stackItem {
            width: 100%;
            padding: 24px;
            display: flex;
            flex-direction: column;

            border: 1px solid rgba($color: #000000, $alpha: 0.2);
            background-color: $lighter-gray;

            .stackBox {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: white;

                min-height: calc(70px - 40px);

                border: 1px solid rgba($color: #000000, $alpha: 0.2);
                padding: 20px 16px;
                gap: 16px;
                cursor: pointer;

                // @include small {
                //   flex-direction: row;
                // }

                .boxTitle {
                    font-family: $georgia !important;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    color: $peach;
                    width: 100%;
                    text-align: left;
                }

                .boxPricing {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    @include small {
                        flex-direction: row;
                    }
                }

                .oldCost {
                    text-decoration: line-through;
                    color: $peach;
                    font-family: $georgia !important;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .currentCost {
                    font-family: $georgia !important;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    color: $blue;
                }

                .warning {
                    font-family: $georgia !important;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: $blue;
                    margin-top: 4px;
                }
            }

            .saveCost {
                font-family: $nunito !important;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 24px;
            }

            .active {
                border: 2px solid $blue; // Increase the border width to 2px on hover

                // Compensate for the increased border width by reducing padding
                padding: calc(20px - 1px) calc(16px - 1px); // Subtract the increased border width

                svg {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
            }
        }
    }
}

.mt-8 {
    margin-top: 8px;
}

.checkCircleIcon {
    color: $blue;
    width: 21px;
    height: 21px;
}

.usagesBoxesContainer {
    display: flex;
    justify-content: center;
    gap: 24px;

    @include small {
        flex-direction: column;
    }
}

//Feature usage specific
.featureBox {
    padding: 15px 40px;
    flex: 1;
    background: $bg-blue-faded;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $blue;
    flex-direction: column;
    text-transform: uppercase;
}

.pageTitle {
    margin-bottom: 30px;
    color: $gray;
    margin-top: 20px;
}

.featureBoxTitle {
    letter-spacing: 2px;
    color: $blue;
}

.featureBoxValue {
    font-family: $georgia !important;
    font-weight: 700;
    font-size: 24px;
    color: $blue;
}

.divider {
    margin-top: 40px;
    margin-bottom: 40px;
}
.stackItem {
    position: relative;
    padding-top: 40px !important;
    @include small {
    }
}

.stackTextCurrent {
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $gray;
    text-transform: uppercase;
    margin-bottom: 24px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;

    background: $blue;
    color: $white;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 12px;
}
.stackTextDowngrade {
    font-family: $nunito !important;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $gray;
    text-transform: uppercase;
    margin-bottom: 24px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    background: rgba(0, 0, 0, 0.04);
    color: $gray;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 12px;
}

.arrowContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    gap: 24px;
    min-width: 100px;
    @include small {
        display: none;
    }
}

.arrowIcon {
    color: $gray;
    width: 32px;
    height: 32px;
}

.stackItemContainer {
    display: flex;
    flex-direction: column;

    align-items: center;
    padding: 0px 24px;

    @include small {
    }
}

.usageContainer {
    display: flex;
    gap: 32px;
    max-width: 560px;
    margin: 0 auto;

    @include small {
        flex-direction: column;
        gap: 20px;
    }
}

.usageBoxWideContainer {
    max-width: 560px;
    margin: 0 auto;
    margin-top: 25px;
}

.usageBoxWide {
    display: flex;
    flex-direction: row;
    background-color: #fff4f2;
    padding: 20px;
}

.usageBox {
    padding: 40px;
    background-color: #fff4f2;
    flex: 1 1 0px;
}

.productImage {
    width: 200px;
    opacity: 0.7;
}

.imgContainer {
    width: 200px;
    height: 200px;
    margin: 0 auto;
}

.icon {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
}

.iconGear {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    margin-right: 15px;
}
.specialPricingText {
    text-align: left;
}
