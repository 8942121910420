@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins";

.cartTotals {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  width: 100%;

  .cartTotalsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 13px;

    & > *:last-child {
      width: max-content;
    }
  }

  .text {
    font-family: $nunito !important;

    &Title {
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-align: left;
      width: 100%;
    }

    &Discount {
      color: $peach !important;
    }

    &Cost {
      font-weight: 700;
    }

    &OldCost {
      font-weight: 400;
      text-decoration: line-through;
      color: $medium-gray !important;
      transform: scale(0.8);
    }
  }

  .divider {
    margin: 10px 0;
    width: 100%;
  }
}
