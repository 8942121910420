@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins";

.startTrialTag {
  height: fit-content;
  width: fit-content;

  color: $white;
  background-color: $peach;

  padding: 5px 3px;

  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.iconSize {
  height: 18px;
  width: 18px;
  color: $white;
  //font-size: 12px;
}
