@import "styles/colors";
@import "styles/text.scss";
@import "styles/mixins";

.billingCycleSelector {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: $white;
  width: 370px;
  padding: 24px;
  border-radius: 4px;

  @include small {
    border: none;
    border-radius: 0;
    padding: 24px;
    width: auto;
  }

  .stackText {
    font-family: $nunito !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 16px 4px 0 4px;
    text-align: center;

    .link {
      text-decoration-line: underline;
    }
  }

  .stackBox {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    min-height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px 16px;
    gap: 16px;
    cursor: pointer;

    .boxTitle {
      font-family: $georgia !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: $peach;
      width: 100%;
      text-align: left;
    }

    .boxPricing {
      display: flex;
      flex-direction: column;
      width: 100%;

      .oldCost {
        text-decoration: line-through;
        color: $peach;
        font-family: $georgia !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .currentCost {
        font-family: $georgia !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: $blue;
      }

      .warning {
        font-family: $georgia !important;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $blue;
        margin-top: 4px;
      }
    }
  }

  .active {
    border: 2px solid $blue;

    svg {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}

.mt-8 {
  margin-top: 8px;
}

.checkCircleIcon {
  color: $blue;
  width: 21px;
  height: 21px;
}
