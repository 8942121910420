@import "styles/colors";
@import "styles/text";
@import "styles/mixins";

.checkoutPayment {
  .card {
    padding: 40px;
    background-color: $lighter-gray;
  }

  .creditCardDisplay {
    padding: 10px 70px;
    border: 0.5px solid $light-gray;
    width: fit-content;
    background-color: $white;
  }

  .stars {
    margin: 0px;

    span {
      vertical-align: sub;
    }
  }
}

@include small {
  .checkoutPayment {
    height: 100%;
    width: 100%;

    .card {
      padding: 22px 15px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .creditCardDisplay {
      width: 100%;
      padding: 10px 10px;
      border: 0.5px solid $light-gray;
      background-color: $white;
    }

    .divider {
      border-bottom: 1px solid $medium-gray;
      opacity: 0.1;
      margin-top: 10px;
    }

    .textLink {
      font-size: 8px;
      font-family: $nunito;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}

.limitCartTotals {
  margin-left: 20px;
}
