@import "styles/mixins";
@import "styles/colors";

.container {
  max-width: 1024px;
  margin: 0 auto;
}

.productView {
  display: grid;
  grid-template-columns: 430px 1fr;
  height: 540px;
  gap: 1rem;

  @include small {
    margin-top: 1.5rem;
    grid-template-columns: 1fr;
    grid-template-rows: 350px 1fr;
  }

  .left {
    background: #a3a3a3;
    animation: pulse 3s ease-in-out infinite;
    border-radius: 4px;
  }

  .right {
    display: grid;
    grid-template-rows: 1fr 2fr;
    gap: 1rem;

    div {
      background: #a3a3a3;
      animation: pulse 3s ease-in-out infinite;
      border-radius: 4px;
      height: 100%;
    }
  }
}

.bottom {
  background: #a3a3a3;
  animation: pulse 3s ease-in-out infinite;
  border-radius: 4px;
  height: 120px;
  margin-top: 1rem;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
