@import "styles/colors";
@import "styles/text";
@import "styles/mixins";

.confirmBrandPlateModal {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 390px;

  .title {
    font-family: $georgia !important;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $gray;
    text-align: center;
  }

  .contentContainer {
    margin-bottom: 10px;
  }

  .contentContainer,
  .planContainer,
  .priceTableContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 16px 24px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    background-color: $lighter-gray;

    .bpType {
      font-family: $georgia !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: $gray;
    }

    .priceContainer {
      display: flex;
      align-items: baseline;
      gap: 4px;

      .oldPrice {
        text-align: right;
        font-family: $georgia !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration: line-through;
        color: $medium-gray;
      }

      .newPrice {
        font-family: $georgia !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  .priceTableContainer {
    flex-direction: column;
    align-items: normal;
    margin: -12px 0 0 0;

    .field {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: $gray;
    }

    .boxRow {
      display: flex;
      justify-content: space-between;
    }

    .divider {
      margin: 10px 0;
    }

    .success {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: italic;
      font-weight: 700;
      line-height: normal;
      margin-top: 10px;
      color: $success;
    }
  }

  .text {
    margin-top: -24px;
    font-family: $nunito !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.009px;
  }

  .warning {
    margin-top: -24px;
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $medium-gray;
  }

  .cta {
    max-width: fit-content;
    font-family: $nunito !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 6.032px;
    text-transform: uppercase;
    margin: auto;
  }

  .listInfo {
    margin: -12px 0 0 0;
    padding: 0 8px 0 18px;

    max-width: 390px;

    .listItem {
      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $gray;
      text-align: justify;
    }
  }

  .planContainer {
    flex-direction: column;

    .fieldTitle {
      display: flex;
      justify-content: center;
      width: 100%;

      font-family: $nunito !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      text-align: center;
      text-transform: uppercase;
    }
  }
}
