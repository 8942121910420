@import "styles/mixins";

.mainContainer {
  margin: 0 auto;
  cursor: pointer;
  height: 100%;
  width: 100%;

  .productImage {
    transition: transform 0.5s ease;
    position: absolute;
    top: 40%;
    left: 50%;
    height: fit-content;
    transform: translate(-50%, -50%);
    max-width: 85%;
    max-height: 85%;
    object-fit: contain;

    @include small {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    &:hover {
      transform: translate(-50%, -50%) scale(1.25, 1.25);
    }
  }
}

.circle {
  margin: auto;
  margin-top: 25px;
}
