@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.breadcrumbs {
  display: flex;
  padding-top: 10px;
  justify-content: space-between;

  .MuiStepper-root {
    cursor: pointer;

    .MuiSvgIcon-root {
      display: none;
    }
    .MuiSvgIcon-root {
      display: none;
    }

    .MuiStepLabel-label {
      color: $gray;
      font-family: "Nunito", sans-serif;
      font-size: 8px;
      letter-spacing: 3.2px !important;
      text-transform: uppercase !important;
      .Mui-active {
        font-weight: bold !important;
      }
    }

    .MuiStepLabel-active {
      color: $gray;
    }

    .MuiStepConnector-root {
      flex: 0;
      margin-bottom: 3px;
      cursor: default;

      .MuiStepConnector-line {
        border: none;
        color: $gray;

        &::after {
          content: "\f054";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          font-size: 8px;
          line-height: 23px;
        }
      }
    }
  }

  .MuiStepLabel-label .Mui-active {
    font-weight: bold !important;
  }

  &--onboarding {
    justify-content: center;
    align-items: center;
  }
}

@include small {
  .breadcrumbs {
    .MuiStep-root {
      &:first-child {
        padding-left: 0;
      }
    }
    .MuiStepper-root {
      margin-left: -10px;
    }
  }
}
