@import "styles/mixins";
@import "styles/colors";
@import "styles/text";

.leftSide,
.rightSide {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.leftSide {
  background-color: $beige;
  background-image: url("/assets/images/onboarding_handbackground.jpg");
  background-size: cover;
  background-position-x: right;

  @include small {
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    width: 100%;
    height: 108px;

    background-position-y: 180px;
  }
}

.rightSide {
  position: relative;

  justify-content: center;
  align-items: center;

  @include small {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    justify-content: flex-start;
  }

  .userUI {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 430px;
    width: 100%;

    height: 80%;
    gap: 24px;
    position: relative;
    z-index: 2;

    @include small {
      // max-width: 320px;
      width: auto;
      padding: 54px 24px 24px;
    }

    .title {
      font-family: $georgia !important;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      color: $blue;
      line-height: 140%;

      text-align: center;

      @include small {
        font-size: 21px;
      }
    }

    .customDropdown {
      display: flex;
      align-items: center;

      max-width: calc(430px - 16px);
      width: 100%;

      height: 40px;
      padding: 0 16px 0 2px;

      border: 1px solid $gray;
      background: $white;

      @include small {
        max-width: calc(320px - 16px);
        width: 100%;
      }

      * {
        &::after {
          content: none;
        }
      }
    }

    .otherMessage {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      gap: 4px;

      .txt,
      .txtCounter {
        font-family: $nunito !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $gray;
        visibility: hidden;
      }

      .txtCounter {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }

      .input {
        background-color: transparent;
        height: 16px;
        padding: 16px;
        border: 1px solid $gray;
        height: 40px;
        padding: 0 16px;
        visibility: hidden;
        &:focus,
        &:visited,
        &:active,
        &:hover {
          outline: none;
        }
      }

      .visible {
        visibility: visible;
      }
    }
  }

  .skip {
    position: absolute;
    bottom: 80px;
    right: 33px;

    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;

    color: $gray;

    &:hover {
      cursor: pointer;
    }

    @include small {
      margin: auto;
      right: initial;
      bottom: 40px;
    }
  }
}

.blankaLogo {
  max-width: 100px;
  margin: 40px auto;
  margin-bottom: 0px;

  @include small {
    width: 80px;
    height: 20px;
    margin: 0 0 0 40px;
  }
}

.quoteContainer {
  position: relative;
  padding-bottom: 40%;
}

.quote {
  margin: auto;
  position: relative;
  padding: 16px 40px;
  max-width: 360px;
}

.quoteText {
  font-family: $nunito;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: $gray;
}

.quoteAuthor {
  margin-top: 24px;
  font-family: $nunito;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 2px;
  text-align: center;
}

.openQuote {
  position: absolute;
  top: 0px;
  left: -8px;
  font-size: 32px;
  color: $gray;
}

.closeQuote {
  position: absolute;
  bottom: 32px;
  right: 0px;
  font-size: 32px;
  color: $gray;
}
