@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins";

.orderWithIssuesModal {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px;
  max-width: 340px;
  width: 100%;

  @include small {
    max-width: 100%;
  }

  .title {
    font-family: $georgia !important;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .text {
    font-family: $nunito !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .boxWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 16px;
  }

  .cta {
    height: 40px;
    text-align: center;
    font-family: $nunito !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;

    @include small {
      font-size: 14px;
    }
  }

  .divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
    width: 100%;
  }

  .divider::before,
  .divider::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid $light-gray !important;
  }

  .divider:not(:empty)::before {
    margin-right: 0.25em;
  }

  .divider:not(:empty)::after {
    margin-left: 0.25em;
  }
}
