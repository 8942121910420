@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.paywallUpgradeModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 420px;
  gap: 32px;
  width: 100%;

  svg {
    height: 40px;
    width: 40px;
  }

  .title {
    font-family: $georgia !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $gray;

    margin-bottom: 8px;
  }

  .body {
    font-family: $nunito !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.009px;
    color: $gray;
  }

  .ctaFont {
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.152px;
    text-transform: uppercase;

    svg {
      height: 14px;
      width: 14px;
      margin-left: 8px;
    }

    padding: 9px 18px !important;
  }

  .boxContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .boxText {
      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.009px;
      color: $gray;
      margin-bottom: -16px;
    }

    svg {
      height: 12px;
      width: 12px;
    }
  }
}
