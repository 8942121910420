@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins";

.enableAutomaticPaymentModal {
  max-width: 495px;
  width: auto;
  max-height: 200px;
  height: auto;

  .textHeader {
    font-family: $georgia !important;
    font-size: 21px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: $medium-gray;
  }

  .textWarning {
    max-width: 400px;
    width: auto;
    text-align: center;
    font-family: $nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 12px;
  }

  .textDanger {
    max-width: 400px;
    width: auto;
    font-family: $nunito !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 40px;
    color: $error;
  }
}
