@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.brandPlatePage {
  .brandPlateHeading {
    width: 55%;
    max-width: 680px;
    margin-top: 1rem;

    @include small {
      width: 100%;
    }
  }

  .newBrandPlateContent {
    min-width: 682px;

    @include small {
      min-width: 100%;
    }
  }

  .newBrandPlateProcess {
    position: relative;
    top: -7rem;

    @include small {
      top: 0;
    }
  }

  .newBrandPlateContainer {
    display: grid;
    grid-template-columns: min-content auto;
    gap: 4rem;
    margin-top: 2rem;
    @include small {
      display: flex;
      flex-direction: column-reverse;
      gap: 2rem;
      padding-bottom: 4rem;
    }
  }

  .brandPlateModalLink {
    text-decoration: underline;
    &:hover {
      color: $peach;
      cursor: pointer;
    }
  }
}
