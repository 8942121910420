@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.accountCard {
  .card {
    margin-top: 0.25rem;
    .cardWrapper {
      padding: 24px;
      background-color: $lighter-gray;
      border-radius: 0;
    }
  }
}
