@import "styles/colors";

.comingSoon {
    color: $blue;
    font-size: 10px;
    height: 20px;
    padding: 0px 0px;

    :global(.MuiChip-label) {
        padding: 0px 5px;
    }
}
