@import "styles/mixins";
@import "styles/colors";
@import "styles/text";

.header {
  display: flex;
  align-items: center;
  align-content: center;

  background-image: url("/assets/images/bg-ocean.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 24px 64px;

  .logo {
    width: 96px;
    height: 24px;
    object-fit: cover;
  }

  .headerBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    transform: translateX(-45px);

    @include small {
      justify-content: center;
      align-items: center;
      align-content: center;
      transform: translateX(0px);
      text-align: center;
    }

    .headerTitle {
      font-family: $nunito !important;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: $white;
    }

    .headerText {
      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $white;
    }
  }
}

.onboardingStepTwoB {
  display: flex;
  flex-direction: column;

  align-items: center;
  width: 100%;

  .attention {
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $gray;
    margin-top: 20px;
  }

  .planCardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;

    @include medium-down {
      flex-direction: column;
    }

    @include small {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      width: fit-content;
      height: 100%;
      width: 100%;

      padding: 20px 0px !important;
    }
  }

  .boxContainer {
    display: flex;
    flex-direction: row;
    height: 40px;
    width: 164px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin: 20px;

    @include small {
      margin: 10px 0 0;
      align-items: center;
    }
  }

  .planText {
    font-family: $nunito !important;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: $gray;

    @include small {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.3em;
    }
  }
}

.tryFreeContainer {
  display: flex;
  justify-content: center;
  align-items: baseline;

  li span {
    position: relative;
    left: -10px;
  }

  p {
    margin-right: 5px;
  }
}

.getStartedButton {
  font-size: 12px !important;
  margin-top: 5px;

  div {
    display: flex;
  }
}

.warning {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  align-self: flex-start;
  margin-top: 22px;

  font-family: $nunito !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: $gray;

  padding-bottom: 30px;

  @include small {
    margin-top: 0;
    padding: 0px 20px;
  }
}
