@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandPlateStatusProcessTitle {
  color: $peach;
  font-size: 18px;
  font-weight: 700;
}

.brandPlateStatusProcess {
  .stepperRoot {
    @include small {
    }
  }

  .stepLabelRoot {
    padding: 0 !important;

    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    color: $light-gray;
    font-family: $nunito !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;

    @include small {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }

  .stepLabelAlternativeLabel {
    margin-top: 0;
    @include small {
      font-size: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .stepLabelActive,
  .stepLabelCompleted {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    color: $blue;
    @include small {
      font-size: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .stepLabelDisabled {
    @include small {
      font-size: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  // ICON props:
  .stepIconRoot {
    display: flex;
    align-items: center;
    justify-content: center;
    // color: $light-gray;
    // height: 24px;
    // width: 24px;

    // @include small {
    //   height: 14px;
    //   width: 14px;
    // }
  }

  .stepIconCircle {
    background-color: $light-gray;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;

    @include small {
      width: 14px;
      height: 14px;
    }
  }

  .stepIconActive .stepIconCircle {
    background-color: $blue;
    color: $white;
  }

  .stepIconCompleted .stepIconCircle {
    background-color: $light-gray;
    color: $white;
  }

  // .stepIconIncompleted {
  //   background-color: $light-gray;
  //   color: $white;
  // }

  .stepLabelContent {
    padding-left: 28px;
  }

  .stepIconCompleted {
    color: $blue;
  }
  .stepIconActive {
    color: $blue;
  }

  .stepIconText {
    @include small {
      font-family: $nunito !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
    }
  }

  // CONNECTOR props:
  .stepConnectorRoot {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    margin-left: 9px;
  }

  .stepConnectorLine {
    min-height: 12px;

    @include small {
      margin-top: -6px;
    }
  }

  .subtitle {
    text-align: start;
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @include small {
    margin: 0 auto;
  }
}

.showInRevision {
  display: block;
}
.hideInRevision {
  display: none;
}
