.brand-plate {
  width: 100%;

  .table {
    border-collapse: collapse;
    margin: 16;
    width: 50%;
    min-width: 350px;
  }

  table-data {
    padding: 8;
  }

  .img {
    width: 100%;
    padding-right: 10px;
    margin: 0 auto;
    object-fit: cover;
    max-width: 350px;
    margin: auto;
  }

  .brand-plate__content {
    max-width: 500px;
    margin-right: auto;
    p {
      margin-bottom: 10px;
    }
  }
  .brand-plate__actions {
    margin-bottom: 50px;
  }

  .brandplate__options {
    // max-width: 500px;
    max-width: 580px;

    margin-right: auto;
    .radio-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 36px;
    }
    .color-type {
      text-align: left;
    }
    .MuiFormControlLabel-root {
      margin-left: 20px;
    }

    .row {
      &__title {
        font-weight: 700;
        font-size: 24px;
        color: $peach;
      }
      &__subtitle {
        font-weight: 700;
        font-size: 12px;
        color: $peach;
      }
    }

    .radio__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100px;
      }
    }
  }

  .brandplate__options-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 500px;
    margin-right: auto;

    .row__header--hidden {
      visibility: hidden;
    }
    .row__header {
      font-size: 10px;
      font-weight: 400;
    }
  }
  .free-plan-upgrade {
    text-align: left;

    .text--upper {
      text-transform: uppercase !important;
    }

    .MuiButtonBase-root {
      margin-top: 25px;
    }
  }

  .text {
    &__title {
      text-transform: initial;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 24px;
      color: $gray;
      font-family: "Georgia" !important;

      margin-bottom: 1px;
    }

    &__subtitle {
      font-size: 24px;
      font-weight: 300;
      color: $blue;
      text-transform: initial;
    }

    &__invalid-billing {
      font-size: 12px;
      padding-top: 10px;
      color: $error !important;
    }
  }

  &__actions {
    text-align: right;
  }
}
