@import "styles/colors.scss";
@import "styles/mixins";

.sideBox {
  display: flex;
  width: 50%;

  @include small {
    width: 100%;
    justify-content: center;
  }
}

.onboardingStepOneB {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @include small {
    width: 100%;
    padding: 24px;
  }

  .blankaLogo {
    width: 150px;
    height: auto;
    padding: 10px;
    margin-left: 2px;
  }

  .welcomeContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;

    @include medium-down {
      flex-direction: column;
      align-items: center;
    }
  }

  .headerTextContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
  }

  .signUpText {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .text {
    &Login {
      font-size: 12px;
      cursor: pointer;

      text-decoration: none;
      width: fit-content;

      span {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.redText {
  color: $peach;
  font-weight: 700;
}

// @include large {
//   .onboardingStepOne {
//     display: flex;
//     flex-direction: column;
//     flex-wrap: wrap;
//     justify-content: center;
//   }
// }
