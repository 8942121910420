@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.cartMobileView {
  width: 100%;
  // padding-top: 80px;

  th {
    padding-bottom: 15px !important;
    &:first-child {
      padding-left: 0 !important;
    }
  }

  td {
    padding-bottom: 10px;
  }

  .divider {
    border-bottom: 1px solid $medium-gray;
    opacity: 0.1;
    margin: 10px 0;
  }

  .card {
    margin: 20px 0;
    border-radius: 0;
    box-sizing: border-box;
    box-shadow: none;
    border: none !important;

    .columnName {
      font-family: $nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 8px;
      line-height: 11px;
      letter-spacing: 0.1em;
      padding-top: 0 !important;
      padding-right: 0 !important;
    }
  }

  .actionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;

    .warning {
      font-family: $nunito;
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 11px;
      display: flex;
      align-items: flex-end;
      text-align: right;
      text-transform: uppercase;
      color: $gray;
    }
  }

  .footerContainer {
    padding-bottom: 11px;

    .importantNote {
      font-family: $nunito;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 144%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: $peach;
    }
  }
}
