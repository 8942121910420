@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandSetupCard {
  background-color: $light-peach;
  border-radius: 4px;
  margin: 32px 15px;
  margin-bottom: 0px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  @include small {
    margin: 0;
    margin-bottom: 8px;
    margin-top: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 2rem;
  }

  .brandSetupCardContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include small {
      align-items: flex-start;
    }

    .brandSetupCardText {
      font-family: $nunito;
      font-size: 12px;
      font-weight: 600;
      line-height: 16.37px;
      letter-spacing: 2px;
      text-align: left;
      color: $peach;
      text-transform: uppercase;
      margin-bottom: 4px;
    }
  }
}
