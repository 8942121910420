@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandSetupUploadFile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px dashed rgba(0, 0, 0, 0.25);
  background-color: $white;
  gap: 2rem;

  width: 100%;
  height: 100%;

  padding: 16px 18.5px 32px 18.5px;
  cursor: pointer;

  &:hover {
    border: 1px dashed $blue;
  }

  .errorIcon {
    position: absolute;
    color: $error;
    width: 20px;
    height: 20px;
    top: -0.7rem;
    right: -0.5rem;
    background-color: white;
  }

  .title {
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;

    line-height: normal;
    letter-spacing: 2px;

    text-transform: uppercase;
    text-align: center;

    color: $blue;
    margin-bottom: 6px;
  }

  .subtitle {
    font-family: $nunito !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    color: $gray;
  }

  &:hover .blankaDefault::before {
    opacity: 0.4;
  }

  .blankaDefault {
    position: relative;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    width: 243px;
    height: 160px;
    margin-top: 1rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("/assets/images/brand-plate/blanka-my-logo-drop.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
    }

    .logoFrame,
    .logoFrameCta,
    .logoPreview {
      position: absolute;
      top: 20%;
      transform: translateY(-20%);
      left: 41.5px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 84px;
      height: 84px;
    }

    .logoPreview {
      object-fit: contain;
    }

    .logoFrame {
      background-image: url("/assets/images/brand-plate/logo-frame.svg");
    }

    .logoFrameCta {
      background-image: url("/assets/images/brand-plate/logo-button.svg");
    }

    .logoDrop {
      position: absolute;
      top: 28px;
      left: 49px;
      background-image: url("/assets/images/brand-plate/your-logo.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 65px;
      height: 65px;
    }
  }
}

.error {
  border: 1px dashed $error !important;
}
