:global(#appleid-signin) {
  background: rgb(255, 255, 255);

  width: 100%;
  border: 0.5px solid rgb(204, 204, 204);
  text-align: center;
  //box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px;

  font-family: "Nunito Sans";
  user-select: none;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 4px;
  flex: 0 0 auto;
  order: 1;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
}

.appleSsoWrapper {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 100%;

  display: flex;
  border-radius: 4px;

  border: 0.5px solid rgb(204, 204, 204);
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  &:hover {
    cursor: pointer;
    transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s,
      box-shadow 0.218s ease 0s;
    box-shadow: rgb(66 133 244 / 30%) 0px 0px 3px 3px;
  }
}

.appleButton {
  display: flex;
  color: #000;
  justify-content: space-between;
  align-items: center;
  span {
    margin-left: 20px;
  }
}
