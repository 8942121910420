@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.sidebarMobile {
  // position: relative;
  height: fit-content;

  // for testing
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;

  .appBarWrapper {
    position: relative;
    border-bottom: $border;
    box-shadow: none;
  }

  .toolbarWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: "80px";

    .cartIcon {
      position: relative;
      top: 3px;

      &:hover {
        opacity: 0.8;
        transition: opacity 0.2s ease-in-out;
      }

      .iconBadge {
        position: absolute;
        z-index: 4;
        top: -6px;
        right: -8px;
        width: 15px;
        height: 15px;
        border-radius: 100px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: $peach;
        box-shadow: 0px 0px 4px 0px #d56f5b80;
        border: 1px solid #ffdfd8;
        color: $white;

        font-family: $nunito;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.03em;
      }

      .iconBadgeBig {
        min-width: 21px;
        // padding: 0px 5px;
        height: 15px;
      }
    }

    .toolbarWrapperLeft {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .toolbarWrapperRight {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .blankaLogo {
    width: 110px;
    height: 28px;
    text-align: center;
  }

  .cartIconWrapper {
    display: flex;
    text-decoration: none;

    span {
      font-size: 12px;
      margin-top: -3px;
    }
  }

  .draweHeaderWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .accountLinks {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-left: 10px;

      a {
        font-size: 12px;
        font-weight: 400;
        color: $gray;
        padding: 0px 10px;
        border-right: 1px solid $gray;

        &:hover {
          text-decoration: underline;
        }

        // &:first-child {
        //   padding-right: 10px;
        // }
        &:last-child {
          border: none;
          padding-right: 0;
        }
      }
    }
  }
}

.submenu {
  color: $gray;
  margin-left: 6px;

  .navItem.activeItem .MuiListItemText-root span::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0px;
    bottom: 3px;
  }
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 18px;
  gap: 10px;

  position: absolute;

  height: 40px;
  left: 15px;
  top: 732px;

  background: #d46e5a;
  border-radius: 2px;

  & > * {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.096em;
    text-transform: uppercase;
  }
}

.hasHeader {
  margin-top: 145px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mtd-16 {
  margin-bottom: 16px;
}

.adjustSize {
  // padding: 16px 0px;
  height: 100px;
}

.closeIcon {
  color: $peach;
}
