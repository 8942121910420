@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.reviewWrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.content {
  .title {
    font-family: $georgia;
    font-size: 21px;
    font-weight: 700;
    line-height: 29.4px;
    text-align: center;
    color: $gray;
    margin-bottom: 30px;

    @include small {
      margin-bottom: 16px;
    }
  }

  .contentMain {
    display: grid;
    grid-template-columns: 385px 385px;
    gap: 30px;
    margin-bottom: 30px;

    @include small {
      display: flex;
      flex-direction: column;
      padding: 16px;
      margin-bottom: 0px;
    }

    .pauseContent {
      padding: 24px;
      background-image: url("/assets/images/bg-ocean.jpg");
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;

      .pauseCta {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .pauseContentIconAnnual {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;

        opacity: 0.4;
      }

      .pauseContentIcon {
        background-color: $white;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        padding: 12px;
      }

      .pauseContentTitle {
        color: $white !important;
        font-family: $georgia !important;
        font-size: 21px;
        font-weight: 700;
        line-height: 29.4px;
        text-align: center;
        margin-bottom: 8px;
      }

      .pauseContentDescription {
        color: $white !important;
        font-family: $nunito !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.1px;
        text-align: center;
      }
    }

    .planContent {
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      border: 1px solid $light-gray;
      background-color: $lighter-gray;

      .planContentTitle {
        font-family: $nunito !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $gray;
        text-transform: uppercase;
        margin-bottom: 8px;
      }
    }
  }

  .pauseContentFooter {
    background-color: #0000001f;
    color: $white;
    padding: 16px;

    .pauseContentFooterTitle {
      font-family: $nunito !important;
      font-size: 12px;
      font-weight: 700;
      line-height: 16.37px;
      color: $white;
    }

    .pauseContentFooterList {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      gap: 4px;

      .pauseContentFooterRow {
        display: flex;
        align-items: flex-start;
        gap: 6px;

        svg {
          position: relative;
          top: 2px;
        }

        .pauseContentFooterText {
          color: $white;
        }

        .pauseContentFooterTextBold {
          font-weight: 600;
          color: $white;
        }

        .pauseContentFooterTextStrike {
          font-weight: 600;
          color: $white;
          text-decoration: line-through;
          opacity: 0.8;
          display: inline-flex;
        }

        .pauseContentTextInline {
          font-weight: 600;
          color: $white;
          display: inline-flex;
        }
      }
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  @include small {
    width: auto;
    margin: initial;
    padding: 16px;
    margin-bottom: 4rem;
  }

  .cancelSubscriptionButton {
    font-family: $nunito;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.37px;
    text-align: left;
    text-decoration-line: underline;
    color: #979797;
    text-transform: initial;
    letter-spacing: initial !important;
    padding: 0 !important;

    &:hover {
      color: $peach;
    }
  }
}

.confirmModalWrapper {
  @include small {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 50px;
    min-width: initial !important;
    min-height: initial !important;
  }
}
