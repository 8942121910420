@import "styles/mixins";

.order-card {
  width: 100%;
  box-shadow: none !important;

  .root {
    width: 100%;
    border: none;
  }

  .MuiCardContent-root {
    padding-left: 0px;
    padding-right: 0px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
    h5 {
      color: $gray;
    }
  }

  .totals-container {
    p {
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }

  &__details {
    .MuiFormControlLabel-root {
      margin-left: 0;
    }
  }

  &__details {
    .MuiFormControlLabel-root {
      margin-left: 0;
    }
  }

  .branded-boxes-cell {
    padding-top: 55px;
  }

  .loading-inactive {
    display: none;
  }

  .fulfill-form {
    width: auto;
    margin-bottom: 10px;
  }

  .totals-container {
    display: flex;
    flex-direction: column;
    text-align: right;
    padding-right: 16px;
    padding-bottom: 15px;
    max-width: 300px;
    margin-left: auto;

    &__values-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .MuiTypography-body1 {
      min-width: 50px;
      text-align: left;
    }

    &--border-top {
      border-top: 1px solid #cccccc;
      padding-top: 10px;
    }
  }

  .btn-submit-order {
    width: 300px;
  }

  .MuiCheckbox-root {
    padding: 0px;
  }

  .MuiTableCell-head {
    font-size: 10px;
    vertical-align: text-top;
  }

  .MuiCheckbox-colorSecondary.Mui-disabled {
  }

  .check {
    border-radius: 1px;
  }

  .MuiTableCell-body {
    font-size: 12px;
  }

  .MuiButton-label {
    //width: 160px;
  }

  .MuiChip-root {
    margin-left: 15px;
  }

  .color-square {
    width: 50px;
    height: 50px;
  }

  .MuiCardActions-root {
    flex-direction: column;
    float: right;
  }

  .MuiFormControlLabel-label,
  .text--label p {
    font-size: 12px;
    color: $gray;
    margin-left: 10px;
    text-transform: uppercase;
    font-family: "Nunito Sans", sans-serif !important;
    color: #5e5e5e;
  }

  .text--brandedbox p {
    font-size: 12px;
    color: $gray;
    margin-left: 10px;
    text-transform: uppercase;
    font-family: "Nunito Sans", sans-serif !important;
    color: #5e5e5e;
  }

  .branded-checkbox-container {
    display: flex;
    .MuiTypography-root {
      width: 50%;
    }
    .MuiButtonBase-root {
      margin-right: 10px;
      margin-top: 5px;
    }
    .MuiSvgIcon-root {
      width: 22px;
      margin-right: 20px;
    }
  }

  .icon--check {
    width: 15px;
    margin-right: 5px;
    height: auto;
  }

  .tracking-number-container {
    display: flex;
    justify-content: flex-end;

    align-items: center;

    .flex--flex {
      align-items: center;
    }
    .icon--truck {
      margin-right: 10px;
    }
  }

  .fulfill-inventory-container {
    display: flex;
    margin-left: auto;

    .MuiFormControlLabel-label {
      font-size: 11px;
      color: $gray;
      letter-spacing: 2.4px;
    }
    .MuiFormControlLabel-root {
      margin-left: -6px;
    }
  }

  &__status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .text {
    &--cell-details {
      letter-spacing: 2.4px;
      color: $gray;
    }

    &__order-error {
      width: 300px;
      padding-top: 5px;
      text-align: left;
      font-size: 12px;
    }

    &__branded-box {
      font-size: 12px;
      letter-spacing: 2.4px;
    }

    &--header {
      color: $medium-gray;
    }

    &__totals {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 2px;
    }

    &__tracking-header {
      font-size: 10px;
      letter-spacing: 2px;
      color: $gray;
      padding-right: 10px;
    }

    &__tracking-number {
      font-size: 10px;
      letter-spacing: 2.4px;
      color: $gray;
    }

    &--label {
      display: flex;
      color: $gray;
      align-items: center;
      font-size: 11px !important;
      letter-spacing: 2.4px;
      padding-right: 15px;
    }
  }

  .MuiFormControlLabel-label {
    letter-spacing: 2.4px;
  }

  .MuiTable-root {
    background-color: #fcfcfc;
  }

  .button {
    &--back {
      margin-right: 8px;
    }
  }

  .instructions {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .actions {
    justify-content: flex-end;
  }

  .order-image {
    width: 100%;
  }
}
