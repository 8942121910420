.fileMockupContainer {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
}

.fileDownload {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    margin-bottom: 5px;
}
