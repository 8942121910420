table{
    
    border: 0.5px solid $medium-gray;
    box-shadow: none !important;

    th{
        color: $gray;
        border-bottom: none;
        
    }

    .MuiTableCell-root {
        border-bottom: none;
        font-family: $nunito;
    }

    .MuiTableCell-head {
        color: $gray;
        font-weight: 400;
        line-height: 1.5rem;
        font-size: 12px;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .MuiTableCell-root{
        text-transform: uppercase;
    }

    .inventoryCell{
        position: relative;
        min-width: 150px;
        text-align: center;

        &::before{
            content: '';
            background: $light-gray;
            height:118px;
            width: 1px;
            position: absolute;
            left: 0px;
            top: 10px
        }

    }

}

:global(.MuiTableContainer-root){
    box-shadow: none !important;
    border-radius: 0px;
}
.MuiTableContainer-root{
    box-shadow: none !important;
}


:global(.Mui-MuiPaper-root-MuiTableContainer-root){
    box-shadow: none !important;
    border-radius: 0px;
}
