@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.downgradeLayout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  background-color: $white;
  overflow-y: auto;

  .downgradeLayoutHeader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid $light-gray;
    margin: 0 24px;
    height: 60px;
    z-index: 999;

    @include small {
      margin: 0 16px;
    }

    .logo {
      width: 72px;
      height: auto;
    }

    .backButton {
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      left: 0px;
      cursor: pointer;

      &:hover {
        .text,
        svg {
          color: $peach !important;
        }
      }

      @include small {
        top: 24px;
        left: 0px;
      }

      svg {
        width: 16px;
        height: 16px;
        color: $gray;
      }

      .text {
        font-family: $nunito !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        margin-left: 10px;

        @include small {
          display: none;
        }
      }
    }
  }

  .downgradeLayoutContent {
    display: flex;
    flex-direction: column;
    margin-top: 84px;
  }
}
