@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.cartItemMobile {
  position: relative;

  .adjustDiscontinued {
    height: 30px;
  }

  .statusWrapper {
    position: absolute;
    top: 5px;
  }

  .brandedBoxProduct {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    left: 100%;
    font-family: $nunito !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: $gray;
    width: 220px;
  }

  .discountWarning {
    position: absolute;
    display: flex;
    top: 10px;
    left: calc(100vw - 275px);
    font-family: $nunito !important;
    font-size: 10px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    color: $blue;
    word-wrap: unset;
    padding: 5px 8px;
    border-radius: 4px;
    background-color: rgba($blue, 0.2);
    width: 240px;

    .icon {
      width: 14px;
      height: 14px;
      margin-right: 3px;
    }
  }

  td {
    position: relative !important;
    padding-top: 20px !important;
    padding-bottom: 30px !important;
    padding-right: 0 !important;

    &:first-child {
      padding-left: 0 !important;
    }
  }

  .imgWrapper {
    position: relative;
    width: 32px;
    height: 32px;
    margin-right: 10px;

    .imgBox {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border: 1px solid $light-gray;
    }

    .diagonal {
      width: calc(sqrt(2) * 31px);
      border-top: 1px solid $gray;
      transform: translate(-5px, 16px) rotate(-45deg);
    }

    .diagonalLines {
      position: absolute;
      width: 33px;
      height: 33px;
      background: url("/assets/images/discontinued.png");
      background-size: 100% 100%;
      background-repeat: repeat;
    }

    .imageOutOfStock {
      opacity: 0.5;
    }
  }

  .brandedBoxes {
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
  }

  .productInfo {
    display: flex;
    flex-direction: column;
    max-height: 206px;
    width: 100%;

    .name {
      font-family: $nunito;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      letter-spacing: 2.3px;
      text-transform: uppercase;
      color: $gray;
    }

    .skuCode {
      font-family: $nunito;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      padding-top: 2px;
      padding-bottom: 6px;
    }
  }

  .quantityInput {
    position: absolute;
    top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50px;
  }

  .totalCost {
    position: absolute;
    top: 20px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .removeBtn {
      font-family: "Avenir Next";
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding-top: 20px;
      color: $gray;
      justify-content: flex-end;
      text-decoration: none;
      margin-bottom: 15px;
    }
  }

  .stackContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    gap: 5px;
  }

  .boxContainer {
    & > * {
      height: 15px;
      font-size: 9px;
      text-transform: lowercase;
    }
  }

  .withDiscount {
    font-family: $nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $blue;
    //margin-top: 10px;
  }

  .withDiscountLower {
    margin-top: 10px;
  }

  .noDiscount {
    font-family: $nunito;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: line-through;
  }

  .hidden {
    display: none;
  }
}

.divider {
  position: absolute;
  width: 100%;
  left: 0;

  border-bottom: 1px solid rgba($medium-gray, 0.8);
}

.customTooltip {
  background-color: $white;
  border: 1px solid $light-gray;
  padding: 10px;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }
}

.tooltipContent {
  background-color: $white;
}

.tooltipTitle,
.tooltipText {
  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  color: $gray !important;
  cursor: pointer;
}

.tooltipText {
  font-weight: 400 !important;
}

.tooltip,
.tooltipError {
  position: absolute;
  height: 14px;
  width: 14px;
  color: $blue;
  right: -20px;

  cursor: pointer;
}

.tooltipError {
  color: $error;
}

.tooltipTitle,
.tooltipText,
.tooltipTitleError {
  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  color: $gray !important;
}

.tooltipTitleError {
  color: $error !important;
}

.tooltipText {
  margin-top: 3px;
  font-weight: 400 !important;
}
