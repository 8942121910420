@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.navContainer {
    display: flex;
    align-items: baseline;

    @include small {
        text-align: center;
        margin-top: 15px;
    }
}

.navItem {
    font-family: $nunito !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    letter-spacing: 4.80225px;
    text-transform: uppercase;
    color: $gray;
    position: relative;

    @include small {
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 3.80179px;
    }

    &:first-child {
        margin-left: 0px;
    }
}

.navItem:hover {
    cursor: pointer;
}

.navDivider {
    &:first-child {
        display: none;
    }
    margin: 0px 12px;
    color: $gray;
}

.navItemFirst {
    composes: navItem;
    padding-left: 0px;
    margin-left: 0px;
}

.navItemFirstActive {
    composes: navItemFirst;
}

.navItemActive {
    composes: navItem;

    position: relative;
    width: auto;
    &::after {
        content: "";
        position: absolute;
        width: 98%;
        height: 5px;
        background: rgba(212, 110, 90, 0.5);
        left: 0px;
        bottom: 2px;
    }
}

.mt-10 {
    margin-top: 10px;
}
