@import "styles/colors.scss";
@import "styles/mixins";

.carousel {
  :global(.carousel .thumb) {
    border: 1px solid $light-gray !important;
  }

  :global(.carousel .thumb) {
    cursor: pointer;
  }

  :global(.carousel .thumb:focus) {
    border: 1px solid $gray !important;
  }

  :global(.carousel .thumb.selected) {
    border: 1px solid $gray !important;
  }

  :global(.carousel .thumb:hover) {
    border: 1px solid $gray !important;
  }

  :global(.carousel .thumbs) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }

  :global(.carousel .thumbs-wrapper) {
    margin: 0 !important;
  }

  :global(.carousel.carousel-slider) {
    // max-height: 500px !important;
    // height: 500px !important;
  }

  @include small {
    :global(.carousel.carousel-slider) {
      max-height: 300px !important;
      height: 300px !important;
    }
    :global(.thumb) {
      width: 56px !important;
      height: 56px !important;
      padding: 0px;
    }
    :global(.thumb img) {
      pointer-events: all !important;
      width: 56px !important;
      height: 56px !important;
    }
  }
}
