@import "styles/colors";

.productImage {
  position: relative;
  justify-self: center;
  max-width: 80px;
  max-height: 80px;
  img {
    width: 80px;
    border: 1px solid $light-gray;
  }

  .statusIndicator {
    position: absolute;
    display: flex;
    top: -15px;
    left: -12px;
  }

  .diagonal {
    width: calc(sqrt(2) * 80px);
    border-top: 1px solid $gray;
    transform: translate(-16px, 41px) rotate(-45deg);
  }

  .diagonalLines {
    width: 81px;
    height: 81px;
    background: url("/assets/images/discontinued.png");
    background-size: 100% 100%;
    background-repeat: repeat;
  }

  .imageOutOfStock {
    opacity: 0.5;
  }
}
