@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandSetupUploadValidator {
  display: flex;
  flex-direction: column;
  align-items: start;

  width: 100%;
  height: 100%;
  gap: 1.5rem;

  .title {
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.8px;
    text-transform: uppercase;

    color: $gray;
  }

  ul {
    list-style-type: none;
    padding-left: 0px;
    margin: 0;

    li {
      display: flex;
      align-items: center;

      font-family: $nunito !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      margin-bottom: 6px;

      svg {
        width: 10px;
        height: 10px;
        margin-right: 6px;
      }
    }
  }

  .uploadCta {
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 145px;
    width: 100%;

    padding: 8px 16px !important;

    font-family: $nunito !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $peach;

    & > *:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .iconCta {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    @include small {
      margin-bottom: 1rem;
    }
  }

  .descriptionError {
    color: $error;
    font-weight: 600;
  }
}
