@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.accountAddressesUpdateView {
  .stackContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .title {
    font-family: $georgia !important;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $medium-gray;
  }

  .label {
    font-family: $nunito !important;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 2px;
    text-align: left;
    padding-top: 20px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
}
