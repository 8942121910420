@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandPlateCurrentPage {
  padding-bottom: 6rem;
  .brandPlateCardWrapper {
    border: $border;
    background-color: $lighter-gray;
    border-radius: 4px;
    padding: 24px;
    margin-top: 1rem;

    .brandPlateCard {
      display: grid;
      grid-template-columns: min-content auto;
      gap: 4rem;

      @include small {
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
        margin-top: 1rem;
      }

      .brandPlateContent {
        min-width: 682px;

        @include small {
          min-width: 100%;
        }
      }

      .brandPlateProcess {
        position: relative;
        top: -2rem;

        @include small {
          top: 0;
        }
      }
    }
  }
}
