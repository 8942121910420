@import "styles/colors.scss";

.successTag {
    background-color: $light-green;
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-radius: 2px;
    color: $green;
}

.successText {
    margin-left: 10px;
    p,
    h5,
    h4,
    h3,
    h2,
    h1 {
        color: $success !important;
    }
}
