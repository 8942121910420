@import "styles/colors";
@import "styles/mixins";
@import "styles/text";


.userMenu {
  position: relative;

  .iconWrapper {
    position: relative;
    color: $gray !important;
    top: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      opacity: 0.8;
      transition: opacity 0.2s ease-in-out;
    }
  }

  .userMenuLink {
    text-decoration: none !important;
    font-family: $nunito !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 19.1px !important;
    text-align: left;
  }

  .userMenuCta {
    font-family: $nunito;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.37px;
    text-align: left;
    text-transform: initial !important;
    letter-spacing: initial !important;
    padding: 0 !important;
    color: $gray;
    min-width: auto;

    &:hover {
      opacity: 0.8;
      transition: opacity 0.2s ease-in-out;
    }

    svg {
      font-size: 18px;
    }
  }

  .popoverContent {
    position: absolute;
    background-color: $white !important;
    border-radius: 4px;
    box-shadow: 10px 10px 40px 0px #00000029;
    z-index: 10;
    right: 0;
    text-align: center;
    white-space: normal;
    overflow-wrap: break-word;
    z-index: 9999;

    .popoverBody {
      text-wrap: initial !important;
      text-align: left;
      position: relative !important;
    }

    .popoverBody {
      border: 1px solid #cccccc;
      border-radius: 4px;
      background-color: #fff;
      font-size: 12px;
      color: $gray;
      text-wrap: nowrap;
      position: relative !important;

      display: flex;
      flex-direction: column;
    }
  }
}
