@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.myLogoMobileStepper {
  .header {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 80px;
    z-index: 1000;

    background: linear-gradient(63.36deg, #246071 16.7%, #143f4b 83.3%);

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .headerBackIcon,
    .modalCloseButton {
      width: 24px;
      height: 24px;
      cursor: pointer;
      color: $white;
      font-weight: 400;
      text-align: center;
    }

    .textTitle {
      font-family: $nunito !important;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 14px;
      letter-spacing: 0.4em !important;
      text-transform: uppercase;
      color: $white;
      text-align: center;
      margin: 0 27px 0 30px;
    }
  }

  .stepper {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: fit-content;
    // padding: 15px 15px 15px;
  }

  .body {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: fit-content;
    padding: 15px 15px 15px;
  }

  .hidden {
    display: none;
    color: transparent;
  }
}
