@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.expiringTag,
.expiringTagFull {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  color: $error;
  background-color: $peach-faded;
  border-radius: 100px;
  min-height: 22px;
  min-width: 22px;
  overflow: hidden;
  z-index: 100;
  margin-right: 12px;

  .tagIcon {
    position: absolute;
    left: 4px;
    height: 14px;
    width: 14px;
    transition: margin-right 0.3s ease;
    z-index: 2;
    display: flex;
    align-items: center;
  }

  .tag {
    font-family: $nunito !important;
    font-size: 10px;
    font-weight: 700;
    opacity: 0;
    color: $error;
    text-transform: uppercase;
    transition: opacity 0.3s ease;
    margin-left: 22px;
    line-height: 1;
    position: relative;
    top: 1px;
    padding-right: 8px;
  }
}

.expiringTag {
  width: 11px;
  transition: width 0.3s ease;

  &:hover {
    width: auto;

    .tag {
      opacity: 1;
    }
  }
}

.expiringTagFull {
  width: auto;
  line-height: 1;

  .tag {
    opacity: 1;
  }
}
