@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.feedback {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 10%;
  width: 100%;

  .title {
    font-family: $georgia;
    font-size: 21px;
    font-weight: 700;
    line-height: 29.4px;
    text-align: center;
    color: $gray;
    margin-bottom: 30px;
  }

  .cardWrapper {
    padding: 24px 30px;
    background-color: $lighter-gray;
    border-radius: 0;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    // width: 480px;
  }

  .questions {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .questionWrapper {
    margin: 8px 0;
    .checkboxAndQuestion {
      display: flex;
      flex-direction: row;
      align-items: center;

      .question {
        font-family: $nunito !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.1px;
        text-align: left;
        text-transform: initial !important;
        letter-spacing: initial !important;
      }
    }

    .replyWrapper {
      display: flex;
      margin-left: 34px;

      .reply {
        margin-top: 8px;
        height: 50px;
        width: 295px;
        margin-bottom: 16px;
      }
    }
  }
}
