@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandSetupMockup {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 0.5rem;

  padding: 8px 0;

  width: 276px;
  height: 276px;

  @include small {
    width: 100%;
  }

  border: 1px solid $light-gray;
  background-color: $white;

  .uploadLogoOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: linear-gradient(45deg, #246071, #143f4b);
    z-index: 1;

    .overlayContent {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 0.625rem;

      padding: 12px 24px;

      @include small {
        padding: 12px 16px;
        gap: 1.8rem;
      }

      .uploadLogoText {
        font-family: $nunito !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        line-height: normal;
        letter-spacing: 0.5px;

        text-align: center;
        text-transform: uppercase;

        color: $white;

        @include small {
          font-size: 12px;
          font-weight: 700;
        }
      }

      .inputDropzone {
        display: flex;
        flex-direction: column;
        align-items: center;

        border: 1px dashed $white;
        border-radius: 4px;
        padding: 14px 8px;

        cursor: pointer;

        &:hover {
          border-color: $blue !important;
        }

        @include small {
          min-width: 140px;
          width: 100%;
          padding: 8px 6px;
        }

        background: linear-gradient(45deg, #3b7585, #1f6274);

        .info {
          font-family: $nunito !important;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 4px;

          text-align: center;
          color: $white;

          @include small {
            font-size: 10px;
            font-weight: 700;
          }
        }

        .sub {
          font-family: $nunito !important;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 8px;

          color: $white;

          @include small {
            font-size: 6px;
            font-weight: 700;
          }
        }

        svg {
          fill: $white;
          width: 20px;
          height: 20px;
        }
      }

      .uploadLogoIcon {
        height: 24px;
        width: 24px;
      }

      .closeCta {
        position: absolute;
        top: 12px;
        right: 8px;

        height: 18px;
        width: 18px;

        color: $white;

        cursor: pointer;
      }

      ul {
        display: flex;
        flex-direction: column;
        width: 100%;

        align-items: start;
        list-style-type: none;
        padding-left: 0px;

        margin: 0;

        li {
          display: flex;
          align-items: center;

          font-family: $nunito !important;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 12px;
          margin-bottom: 6px;
          color: $white;

          @include small {
            font-size: 8px;
            font-weight: 700;
          }

          svg {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            margin-right: 6px;
            background-color: #fff;
          }
        }
      }
    }

    .uploadLogoText {
      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;

      line-height: normal;
      letter-spacing: 0.5px;

      text-align: center;
      text-transform: uppercase;

      color: $white;

      @include small {
        font-size: 12px;
        font-weight: 700;
      }
    }

    .uploadLogoIcon {
      height: 24px;
      width: 24px;

      @include small {
        height: 20px;
        width: 20px;
      }
    }

    .closeCta {
      position: absolute;
      top: 8px;
      right: 8px;

      height: 18px;
      width: 18px;

      color: $white;

      cursor: pointer;

      @include small {
        top: 4px;
        right: 4px;

        height: 142px;
        width: 14px;
      }
    }
  }

  &Action {
    position: relative;
    top: 0;
    left: 0;
    padding: 0 8px;
    align-self: flex-end;

    @include small {
      align-self: center;
    }

    .changeLogoCta {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 8px 16px;

      width: fit-content;

      font-family: $nunito !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 2px;

      border-radius: 2px;

      @include small {
        padding: 6px 10px !important;
        font-size: 8px;
        font-weight: 700;
      }

      .pencilLogo {
        height: 14px;
        width: 14px;
      }
    }
  }

  .mockup {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    .image1,
    .image2,
    .image3,
    .image4 {
      position: relative;
      top: 0;
      left: 0;

      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 8px 20px 36px 20px;

      width: 100%;

      @include small {
        width: 160px;
      }
    }

    .image1 {
      background-image: url("/assets/images/brand-plate/blanka-my-logo-drop.svg");
    }

    .image2 {
      background-image: url("/assets/images/blanka-my-logo-preview.png");
    }

    .image3 {
      background-image: url("/assets/images/blanka-my-logo-thin-light-preview.png");
    }

    .image4 {
      background-image: url("/assets/images/blanka-my-logo-thin-dark-preview.png");
    }

    .logo1 {
      position: absolute;
      top: 40px;
      left: 17%;
      width: 80px;
      height: 80px;
      object-fit: contain;

      @include small {
        top: 54px;
        left: 28px;
        width: 52px;
        height: 52px;
      }
    }

    .logo2 {
      position: absolute;
      top: 20px;
      left: 16%;
      height: 70px;
      width: 70px;
      object-fit: contain;

      @include small {
        top: 40px;
        left: 18%;
        height: 42px;
        width: 42px;
      }
    }

    .logo3 {
      height: 33px;
      left: 25%;
      object-fit: contain;
      position: absolute;
      top: 66px;
      width: 60px;

      @include small {
        top: 68px;
        left: 26%;
        height: 24px;
        width: 24px;
      }
    }

    .logo4 {
      height: 10px;
      left: 45%;
      object-fit: contain;
      position: absolute;
      top: 77px;
      width: 20px;

      @include small {
        top: 75.5px;
        left: 45%;
        height: 8px;
        width: 8px;
      }
    }
  }

  .productType {
    font-family: $nunito !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.8px;
    text-transform: uppercase;
    text-align: center;

    color: $gray;

    @include small {
      font-size: 8px;
    }
  }
}
