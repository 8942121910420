@import "styles/mixins";
@import "styles/colors";
@import "styles/text";

.stripeModalWrapper {
  display: flex;
  flex-direction: column;
  max-width: 540px;
  min-width: 540px;
  min-height: 300px;

  @include small {
    max-width: 100%;
    min-width: 100%;
  }

  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1;
  }
}

.changePlanWrapper {
  @include small {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 50px;
  }

  .warning {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    align-self: flex-start;
    margin-top: -10px;

    font-family: $nunito !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $gray;

    @include small {
      padding: 16px 20px;
    }
  }

  .positioningFreeTrialTag {
    position: absolute;
    top: 30px;
    left: calc(30px + 10px);
    z-index: 1;

    @include small {
      position: relative;
      top: 0px;
      margin-right: auto;
      margin-bottom: 20px;
    }
  }

  .plans {
    display: flex;
    margin-top: 20px;
    margin-bottom: 25px;

    @include small {
      flex-direction: column;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .loading {
    margin-top: 25px;
  }

  .tryFreeContainer {
    display: flex;
    justify-content: center;
    align-items: baseline;

    li span {
      position: relative;
      left: -10px;
    }

    p {
      margin-right: 5px;
    }
  }

  .getStartedButton {
    margin-top: 5px;
    font-size: 14px !important;
    //letter-spacing: normal;

    &:hover {
      text-decoration: none !important;
    }
  }

  .planCardsContainer {
    position: relative;
    padding-top: 30px;
    padding-bottom: 36px;
    display: flex;
    flex-direction: row;
  }

  .modalTitle {
    font-family: $nunito !important;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    letter-spacing: 6px;
    text-transform: uppercase;
    color: $peach;
    margin-bottom: 12px;

    img {
      width: 170px;
      height: 47px;
    }

    @include small {
      font-family: $georgia !important;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: 0em;
      text-transform: none;
      color: $gray;

      img {
        width: 100px;
        height: 25px;
      }
    }
  }

  .modalSubTitle {
    font-family: $nunito !important;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 33px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: $blue;
    margin-bottom: 20px;

    @include small {
      font-size: 12px;
      line-height: 25px;
      margin-top: 10px;
    }
  }

  .boxContainer {
    display: flex;
    flex-direction: row;
    height: 40px;
    width: 164px;
    // margin-top: 20px;
    gap: 10px;
    justify-content: center;
    align-items: center;

    @include small {
      margin-top: 10px;
    }
  }
}

.planText {
  font-family: $nunito !important;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  color: $gray;

  @include small {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.3em;
    padding-top: 20px;
  }
}

.errorMessage {
  font-family: $nunito !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: red;
  margin-bottom: 12px;
}
