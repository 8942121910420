@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.trackingContainer {
  display: flex;

  width: 100%;
  align-items: center;
  justify-content: flex-start;

  @include small {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.colorSwatch {
  width: 50px;
  height: 50px;
}

.productImage {
  max-width: 80px;
  img {
    width: 80px;
    border: 1px solid $light-gray;
  }
}

.cartBillContainer {
  margin-left: auto;
  max-width: 330px;
  width: 100%;
  margin-right: 15px;

  @include small {
    display: flex;
    width: 100%;
  }
}

.appendedHeader {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin-top: 16px;
  background-color: $lighter-gray;
  border: 1px solid #979797;
  border-bottom: none;

  @include small {
    display: flex;
    gap: 32px;
    flex-direction: column;
    padding: 0;

    border: none;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $light-gray;

  margin: 16px 0;
}

.cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.title {
  font-family: $nunito !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $gray;
}

.peachText {
  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $peach;
  margin-top: 4px;
}

.cardContent {
  padding: 0;
  margin-bottom: 16px;
}

.backBtn {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  cursor: pointer;

  font-family: $nunito !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-align: left;
  color: $gray;
  text-transform: uppercase;

  &:hover {
    opacity: 0.6;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.hide {
  display: none;
}
