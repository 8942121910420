@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.navItem {
  font-family: $nunito !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $gray;

  padding: 0 20px;
  border-left: 1px solid $gray;

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &:hover {
    opacity: 0.6;
  }

  @include small {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 2px;
  }
}

.navItemFirst {
  composes: navItem;
}

.navItemFirstActive {
  composes: navItemFirst;
  font-weight: bold;
  text-decoration: underline;
}

.navItemActive {
  composes: navItem;
  font-weight: bold;
  text-decoration: underline;
}

.navItemPaused {
  opacity: 0.6;
  text-decoration: none !important;
  pointer-events: none;
}

.mt-10 {
  margin-top: 10px;
}
