@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.form {
  max-width: 350px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  input {
    width: 100%;
  }
  label {
    top: -12px;
  }
}

.formContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.passwordStrength {
  font-size: 9px;
}

.errorMessage {
  max-width: 400px;
  text-align: center;
}

.success {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin-right: 5px;
  }
}

.passwordStrengthBar {
  margin-bottom: 0px;
}

.signInLink {
  display: flex;
  width: 100%;
  justify-content: start;
  margin: 10px 0;

  font-family: $nunito !important;
  font-size: 12px;
  font-weight: 600;
}

.resetButton {
  width: 100% !important;
}

.inputFields {
  margin-top: 30px;
  width: 100%;
}
