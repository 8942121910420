@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandPlateStepOne {
  position: relative;
  .logosCard {
    padding: 24px;
    padding-top: 16px;
    border: $border;
    border-radius: 4px;
    background-color: $lighter-gray;

    .logosCardHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include small {
        flex-direction: column;
        gap: 8px;
        margin-top: 8px;
      }
    }

    .logoWizardBtn {
      padding: 8px !important;

      .logoWizardBtnContent {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        @include small {
          padding: initial;
        }
      }
    }
  }

  .noLogos {
    display: flex;
    align-items: flex-start;

    .lipStick {
      width: 240px;
      height: auto;
      position: relative;
      top: -32px;

      @include small {
        position: absolute;
        top: -16rem;
        right: -1rem;
      }
    }

    .wizardCard {
      padding: 24px;
      border: $border;
      box-shadow: 0px 4px 7px 0px #0000000d;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @include small {
        width: 100%;
      }

      .wizardCardHeader {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }

  .mockupGridWrapper {
    justify-content: center;
    margin-top: 1rem;
    justify-content: space-between;
    gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include small {
      display: flex;
      flex-direction: column;
      justify-content: initial;
      align-items: center;
    }

    .mockupGrid {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 300px;
      height: 300px;

      .mockupImageWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border: $border;
        width: 300px;
        height: 300px;
      }

      .mockupImage {
        width: 270px;
        height: 100%;
        object-fit: contain;
      }

      .mockupLogoImg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .mockupImageCaption {
        font-family: $nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.4em;
        text-transform: uppercase;
        margin-bottom: 20px;
        position: relative;
        top: 8px;
      }

      .mockupLogoDefault {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        max-width: 84px;
        max-height: 84px;
        width: 100%;
        height: 100%;
        top: 70px;
        left: 60px;
      }

      .mockupGridWrapper {
        justify-content: center;
        margin-top: 10px;
        row-gap: 10px;
        column-gap: 10px;
      }

      .mockupLogoInverted {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        max-width: 77px;
        max-height: 57px;
        width: 100%;
        height: 100%;
        top: 72px;
        left: 60px;
      }

      .mockupLogoThin {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 90px;
        height: 30px;
        top: 117px;
        left: 55px;
      }

      .mockupLogoInvertedThin {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        max-width: 60px;
        max-height: 8px;
        width: 100%;
        height: 100%;
        top: 127.5px;
        left: 110px;
      }
    }
  }

  .brandPlateStepOneAction {
    display: flex;
    margin-top: 2rem;
    align-items: center;
    gap: 2rem;

    @include small {
      flex-direction: column;
      gap: 1rem;
    }
  }
}
