@import "styles/colors";
@import "styles/mixins";

.confirmModal {
  .confirmModalActions {
    display: flex;
    margin-top: 1rem;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    @include small {
      flex-direction: column;
      justify-content: initial;
      align-items: initial;
      gap: 1rem;
      margin-top: 0.5rem;
    }
  }
}
