.switch {
    margin: 0px;
    span {
        margin: 0px;
    }
}

.progress {
    position: relative;
    z-index: 9;
}
