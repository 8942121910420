@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.accountBillingView {
  .stackContainer {
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 510px;
    min-width: 315px;
    width: 100%;

    .creditCardWarning,
    .creditCardLabel {
      font-family: $nunito !important;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 2px;
      text-align: left;
      text-transform: uppercase;
      color: $medium-gray;
      margin-top: 20px;
      margin-bottom: 8px;
    }

    .creditCardWarning {
      text-transform: none;
      margin-top: 8px;
      padding-bottom: 20px;
    }

    input {
      padding: 13px 15px;
      border: 0.5px solid $light-gray;

      font-family: $nunito !important;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 2px;
      text-align: left;
    }

    .updateButton {
      max-width: 340px;
      width: 100%;
    }

    .subTitle {
      //styleName: Header 2 - Georgia Bold;
      font-family: $georgia !important;
      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      margin: 30px 0 20px 0;
    }
  }

  .boxContainer,
  .flexContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .boxContainer {
    gap: 10px;
  }

  .poweredByStripe {
    width: 150px;
    height: 34px;
  }

  .addMethodMessage {
    //styleName: Header 2 - Georgia Bold;
    font-family: $georgia !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .billingMethodBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 50px;
    gap: 20px;
    padding-bottom: 30px;

    .imgStyle {
      width: 70px;
      height: 70px;
      object-fit: cover;
    }
  }
}

.title {
  font-family: $georgia !important;
  font-size: 24px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
}

.encryptionMessage {
  font-family: $nunito !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: $medium-gray;
  margin-top: 4px;
}

.errorMessage {
  font-family: $nunito !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: red;
  margin-bottom: 12px;
}
