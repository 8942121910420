@import "styles/colors";
@import "styles/text";
@import "styles/mixins";

.inventoryOrdersMobileView {
  width: 100%;

  // .shadowBox {
  //   box-shadow: 0px 4px 5px 0px rgba(163, 163, 163, 0.3);
  // }

  .mobileFlexContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .noOrdersToDisplay {
    font-family: $georgia !important;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
