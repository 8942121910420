@import "styles/mixins";
@import "styles/text";
@import "styles/colors";

.groupProductCardGenerating {
  opacity: 0.5;
  // this might be the cause of the bug? : BP-1137
  // pointer-events: none;
}

.groupProductCard {
  position: relative;
  background-color: $white;
  box-shadow: 0px 4px 7px 0px #0000000d;
  border: 1px solid #cccccc;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  cursor: pointer;

  // need this in order to have aspect ratio proper with current figma designs but dynamic width
  padding-bottom: 100%;

  @include small {
    padding-bottom: 120%;
  }

  transition: border-color 0.3s ease;
  &:hover {
    border-color: $peach;
  }

  .boxLabel {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wandContainer {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 0;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .productFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: white;
    max-height: 48px !important;
    height: 48px !important;
    display: flex;
    align-items: center;
    padding: 0 16px;

    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    &.visible {
      opacity: 1;
    }

    .productName {
      color: red;
      color: $gray;
      font-size: 0.75rem;
      font-weight: 700;
      letter-spacing: 2px;
      text-transform: uppercase;

      @include small {
        font-size: 0.625rem;
      }
    }
  }

  .editButton {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    background-color: $peach;
    z-index: 5;
    height: 28px;
    max-height: 28px;

    color: $white;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    transition: border-color 0.3s ease;

    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.visible {
      opacity: 1;
    }
  }

  .infoContent {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: $white;
    z-index: 5;
    height: 42px;
    max-height: 42px;

    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 0px 16px -6px #00000040;

    transition: border-color 0.3s ease;

    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.visible {
      opacity: 1;
    }
  }
}

.imageSwitcher {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: auto;
  gap: 4px;

  height: 18px;

  cursor: pointer;
  background: #0000000a;
  padding: 6px;
  border-radius: 100px;

  &:hover {
    background: #2460711a;
  }

  .imageSwitcherIndex {
    background: #0000001f;
    border-radius: 100%;
    height: 8px;
    width: 8px;
    margin: 4px;
  }

  .imageSwitcherIndexActive {
    background: $blue;
  }
}

.imageSwitcherNoBox {
  cursor: initial !important;

  &:hover {
    background: #0000000a !important;
  }
}

.customizedChip {
  position: absolute;
  z-index: 3;
  left: 8px;
  top: 8px;
  font-family: $nunito;
  font-size: 0.625rem;
  line-height: 0.75rem;
  font-weight: 600;
  text-align: center;

  height: 14px;
  max-height: 14px;

  background-color: $bg-blue-faded;
  color: $blue;

  .icon {
    height: 10px;
    width: 10px;
  }
}
