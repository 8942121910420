@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.settingsView {
  .title {
    font-family: $georgia !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32.4px;
    color: $gray;
    margin-bottom: 24px;
  }

  .innerCard {
    border: 1px solid #0000001f;
    background-color: $white;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 32px;

    @include small {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .subtitle {
      font-family: $georgia !important;
      font-size: 21px;
      font-weight: 700;
      line-height: 29.4px;
      text-align: left;
    }

    .description {
      font-family: $nunito !important;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.37px;
      text-align: left;
      color: $gray;
    }

    .settingTitle {
      font-family: $nunito !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 19.1px;
      text-align: left;
      color: $gray;
    }

    .settingColumn {
      font-family: $nunito !important;
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 2px;
      color: $gray;
    }

    .editStock {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $peach;
      }
    }

    .editRow {
      display: inline-flex;
      align-items: center;
      gap: 8px;

      .editStockInput {
        width: 40px;
        border: 1px solid $medium-gray;
        text-align: center;
        padding: 4px;

        font-family: $nunito !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.37px;
        text-align: center;
        color: $gray;

        &:focus {
          outline: none;
        }
      }
    }

    .notificationsList {
      display: flex;
      flex-direction: column;

      .notificationsListRow {
        padding: 12.5px 0;
        display: grid;
        grid-template-columns: 1fr 0.3fr 0.3fr;
        border-bottom: 1px solid #0000001f;
        align-items: center;
        gap: 1rem;

        @include small {
        }
      }

      .noBorder {
        border: none;
      }
    }
  }
}
