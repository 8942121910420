@import "styles/colors.scss";
@import "styles/mixins";
@import "styles/text";

.submenu {
  color: $gray;
  margin-left: 6px;

  div,
  a {
    &:hover {
      background: $white;
    }
  }

  .navItem.activeItem .MuiListItemText-root span::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0px;
    bottom: 3px;
  }
}

:global(
    .activeItem div,
    .activeItem div:hover,
    .navItem:hover,
    .navItem div:hover
  ) {
  background-color: #fff;
}

:global(.navItem.activeItem) {
  .icon--normal {
    display: none;
  }

  .icon--hover {
    display: inline-block;
  }

  .material-icons {
    color: rgba(230, 136, 128, 0.9);
  }

  .MuiListItemText-root span {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 5px;
      background: rgba(230, 136, 128, 0.5);
      left: 0px;
      bottom: 2px;
    }
  }
}

.navItem {
  .MuiSvgIcon-root {
    font-size: 1.3rem;
  }

  .icon--normal {
    display: inline-block;
  }

  .icon--hover {
    display: none;
  }

  &:hover {
    .MuiListItemText-root span {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 5px;
        background: rgba(230, 136, 128, 0.5);
        left: 0px;
        bottom: 2px;
      }
    }

    .icon--normal {
      display: none;
    }

    .icon--hover {
      display: inline-block;
    }
  }

  .MuiListItemText-root {
    width: 151px;
    span {
      width: fit-content;
    }
  }
}

.blankaLogo {
  position: absolute;
  left: 65px;
  cursor: pointer;

  img {
    width: 80px;
    height: 21px;
  }
}

:global(.MuiListItemText-primary) {
  font-family: "Nunito Sans", sans-serif !important;
  text-transform: uppercase;
  letter-spacing: 2.4px !important;
  font-size: 12px !important;
  color: $gray;
  line-height: 16px !important;
  padding-left: 0px;
}

:global(.MuiListItemButton-root) {
  padding: 0px !important;
}

:global(.MuiListItemIcon-root) {
  min-width: 36px;
}

.drawer {
  border-right: none;
  box-shadow: 0px 0px 4.7px rgba(0, 0, 0, 0.3);
  width: 0;
}

.drawerHead {
  position: relative;
}

.drawerHead::after {
  content: "";
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 0px;
  left: 8%;
  width: 86%;
  height: 1px;
}

.hasHeader {
  :global(.MuiPaper-root) {
    padding-top: 36px;
  }
}
