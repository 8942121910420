@import "styles/mixins";

.googleSsoWrapper {
  width: 100%;
  padding: 5px;

  @include small {
    padding: 0px;
    margin-bottom: 10px;
  }

  button:hover {
    cursor: pointer;
    transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s,
      box-shadow 0.218s ease 0s;
    box-shadow: rgb(66 133 244 / 30%) 0px 0px 3px 3px !important;
  }
}

.googleSsoBtn {
  transition: background-color 0.3s, box-shadow 0.3s;

  height: 52px;
  width: 100%;
  border: 0.5px solid rgb(204, 204, 204);
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  background-color: #ffffff;
  border-radius: 4px;
  font-family: "Nunito Sans";
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    padding-right: 18px;
    position: relative;
  }

  :hover {
    cursor: pointer;
    transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s,
      box-shadow 0.218s ease 0s;
    box-shadow: rgb(66 133 244 / 30%) 0px 0px 3px 3px !important;
  }

  &:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }
}
