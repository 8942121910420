@import "styles/colors";
@import "styles/text";
@import "styles/mixins";

.productStatusIndicator {
  position: relative;
  width: 100%;

  .stackWrapper {
    position: absolute;
    display: flex;
    gap: 4px;
    top: 5px;
    left: 0px;

    @include small {
      display: flex;
      gap: 4px;
      top: 5px;
      left: 0px;

      svg {
        display: flex;
        position: absolute;
        width: 12px;
        height: 12px;
        z-index: 2;
      }
    }
  }

  .alignRight {
    align-items: flex-end;
    right: 20px;
    top: -20px;
  }

  .boxWrapper {
    position: absolute;
    display: flex;
    top: -10px;
    left: -10px;
    width: calc(100% + 10px);
    z-index: 1;

    @include small {
    }
  }
}
