@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.ConfirmOrderModal {
  display: flex;
  flex-direction: column;
  text-align: center;

  .buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 35px;
  }

  .text {
    &Header {
      color: $medium-gray;

      @include small {
        color: $gray;
        font-family: $georgia !important;
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-top: 30px;
      }
    }

    &Content {
      font-size: 14px;
      color: $gray;
      font-weight: 400;
      text-align: center;

      @include small {
        font-family: $nunito !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .contentContainer {
    padding-top: 15px;
    width: auto;
    max-width: 374px;
    text-align: left;
    justify-content: center;

    @include small {
      padding-top: 16px;
    }
  }
}

.textContentDisclaimer {
  font-size: 12px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.warning {
  margin-top: 10px;
  font-family: $nunito !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $medium-gray;
}
