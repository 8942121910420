@import "styles/colors";
@import "styles/mixins";

.storesContainer {
  display: flex;
  justify-content: center;

  margin-top: 1rem;
  border: 1px solid #0000001f;
  background-color: $white;
  padding: 40px 20px;

  @include small {
    //flex-direction: column;
    flex-wrap: wrap;
  }
}

.text {
  &ShopType {
    margin-top: auto !important;
  }

  &Redirect {
    cursor: pointer;
    text-decoration-color: $gray;
  }

  &ComingSoon {
    font-weight: bold;
    visibility: hidden;
    position: absolute;
    text-transform: uppercase;
    left: 22px;
  }
}

.storeContainer {
  border: 1px solid #ededed;
  border-radius: 2px;
  background: #f7f7f7;
  margin-right: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 150px;
  height: 150px;

  img {
    padding-top: 32px;
    align-self: center;
    max-width: 75px;
    margin-top: auto !important;
  }

  @include small {
    width: 100%;
    margin: 5px;
  }
}

.storeContainerActive:hover {
  background: $blue;

  .text {
    &ShopType {
      color: $white;
    }
  }
  .waitlistTag {
    background-color: $white;
  }
  .vip {
    color: $white;
  }
}

.storeContainerInactive:hover {
  background: #5e5e5e;
  opacity: 0.73;

  cursor: default;

  img {
    opacity: 0.1;
  }

  .text {
    &ComingSoon {
      visibility: visible;
    }

    &ShopType {
      color: $medium-gray;
    }
  }
}

.noticeContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.loading {
  padding: 75px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.waitlistTag {
  background-color: rgba(36, 96, 113, 0.1);
  color: $blue;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  height: 18px;
  border-radius: 100px;
  z-index: 999999;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.vip {
  position: absolute;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
  height: 18px;
  border-radius: 100px;
  z-index: 999999;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
