@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.myLogoDropzone {
  position: absolute;
  width: 143px;
  height: 143px;
  top: 140px;
  left: 95px;

  background: rgba(36, 96, 113, 0.6);
  border: 0.680952px dashed #000000;

  @include small {
    left: calc(50% - 90% / 2);
    top: calc(50% - 262px / 2 - 17.5px);
    height: 262px;
    width: 90%;
  }

  .hidden {
    display: none;
  }

  .dragDropContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding: 21px 15px 20px 15px;
    gap: 20px;

    @include small {
      height: 262px;
      width: 100%;
    }
  }

  .dragDropZone {
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: fit-content;

    .dragDropText {
      font-family: $nunito;
      font-style: normal;
      font-weight: 700;
      font-size: 9.74335px;
      line-height: 13px;
      text-transform: uppercase;
      color: $white;
      width: 100%;
    }
  }

  .divider {
    font-family: $nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 7.49488px;
    line-height: 10px;
    text-align: center;
    color: $white;
    width: 80px;
    margin: 0 auto;

    &::before,
    &::after {
      border-color: $white;
    }
  }

  .dragDropCta {
    background-color: $white;
    border-radius: 2px;
    height: 20px;
    width: 100%;

    @include small {
      height: 40px;
      width: 158px;
    }

    .dragDropCtaText {
      display: block;
      font-family: $nunito;
      font-style: normal;

      font-weight: 700;
      font-size: 7.3px;
      line-height: 10px;
      letter-spacing: 2px;
      text-transform: uppercase;
      width: 113px;

      @include small {
        font-family: $nunito;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.096em;
        text-transform: uppercase;
      }
    }
  }
}
