@import "styles/colors.scss";
@import "styles/mixins.scss";
@import "styles/text.scss";

.myLogoView {
  width: 100%;
  height: 100%;
  z-index: 10000;
  position: relative;
  // overflow: hidden;

  .leftPanel {
    background: $blue-gradient;
    width: 100%;
    height: 100%;
  }

  .rightPanel {
    background: $white;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .rightPanelChildren {
      flex-grow: 1;
      overflow-y: auto;
      height: 100%;
      padding-bottom: 40px;
    }
  }

  @include small {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    // overflow: auto;

    .leftPanel {
      position: relative;
      background: $blue-gradient;
      width: 100%;
      height: 80px;
      // overflow: auto;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .textTitle {
        font-family: $nunito !important;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.4em !important;
        text-transform: uppercase;
      }

      .modalCloseButton {
        position: absolute;
        top: 24px;
        right: 16px;
        color: $white;
        cursor: pointer;
      }
    }

    .rightPanel {
      height: 100%;
      overflow: auto;
    }
  }
}
