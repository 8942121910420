@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.orderStatusBar {
  display: flex;
  width: 100%;
  height: auto;

  align-items: center;
  justify-content: center;
  gap: 32px;
  background-color: #fff;

  .orderProgressBar {
    display: flex;
    width: 100%;
    height: 47px;

    align-items: center;
    justify-content: space-between;

    border: 2px solid $blue;
    border-radius: 100px;

    @include small {
      height: 38px;
    }
  }

  .orderStatus {
    border-radius: 100px;
    width: 100%;
    min-width: 84px;
    padding: 16px;

    font-family: $nunito;
    font-size: 8px;
    font-weight: 600;
    line-height: 10.91px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;

    @include small {
      min-width: initial;
      font-size: 10px;
    }
  }

  .title,
  .cancelled {
    font-family: $nunito !important;
    font-size: 10px;
    font-weight: 700;

    line-height: 12px;
    letter-spacing: 2px;

    text-transform: uppercase;
    color: $gray;
  }

  .cancelled {
    text-transform: none;
  }

  .active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: auto;
    height: calc(49px - 32px);
    border: 1px solid $blue;
    background-color: $blue;
    color: $white;
  }
}
