.App .profile-billing-update {
    .root {
      width: 100%;
    }
  
    .stripe {
      max-width: 600px;
      margin-top: 15px;
    }
  
    .paper {
      width: 100%;
      margin-bottom: 16px;
    }
  
    .header {
      border-bottom: 1px solid $gray;
    }
  
    .card {
      width: 100%;
      display: flex;
      padding-left: 15px;
      padding-top: 0px;
      flex-direction: column !important;
    }
  
    .text {
      &__payment-header {
        font-size: 24px;
        color: $medium-gray;
      }
  
      &__name {
        margin-top: 25px;
        width: 53%;
        margin-right: 10px;
      }
  
      &__postal-zip {
        margin-top: 25px;
        margin-bottom: 25px;
        width: 45%;
      }
    }
  
    .button {
      margin-top: 50px;
      width: 200px;
    }
  }