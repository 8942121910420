@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandPlateDefaultContent {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .linkIcon {
        margin-bottom: -2px;
        margin-left: 2px;
    }

    @include small {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .boxSetupContainer {
        background-color: $lighter-gray;
        border: $border;
        padding: 24px;

        .title {
            font-family: $georgia !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: center;
            display: flex;
            color: $gray;
            span {
                text-align: center;
            }
        }

        .subTitle {
            font-family: $nunito !important;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 8px;
            margin-bottom: 16px;
        }

        .stackContainer {
            display: flex;
            flex-direction: row;
            gap: 40px;

            @include small {
                flex-direction: column-reverse;
                gap: 32px;
            }
        }

        .boxStepper {
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex-shrink: 0;
            width: auto;
            flex-basis: 20%;

            .stepperProgress {
                font-family: $georgia !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: $peach;
            }
            @include small {
                flex-basis: initial;
            }
        }
    }

    .mobileHeader {
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 4.8px !important;
        font-size: 12px;
        color: $gray;
        font-weight: 400;
        font-family: $nunito !important;
    }
}

.PageHeader {
    border-bottom: 1px solid $gray;
    padding-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 4.8px;
    font-size: 12px;
    color: $gray;
    font-weight: 400;
}

.pendingReviewBox {
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.12);
    overflow: initial;
}

.actionBox {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
    @include small {
        flex-direction: column;
        gap: 16px;
    }
}

.fileDownload {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.pendingReviewTitle {
    margin-bottom: 10px;
}

.pendingReviewContainer {
    background-color: $white;
}

.downloadContainer {
    border: 1px solid $light-gray;
    border-radius: 4px;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.04);
    margin-bottom: 20px;
    svg {
        margin-right: 5px;
    }
}

.mockupTitle {
    font-weight: 700;
    margin-bottom: 10px;
}

.actionContainer {
    border-top: 1px solid $light-gray;
    display: flex;
    justify-content: center;
    align-items: center;
}
