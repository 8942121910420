@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.alertError,
.alertSuccess {
  font-family: $nunito !important;
  color: #ab0000;
  font-size: 14px;
  background-color: #ab00000d;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

.alertSuccess {
  color: $blue;
  background-color: rgba($color: $blue, $alpha: 0.15);
}
