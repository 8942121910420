@import "styles/mixins";
@import "styles/colors";
@import "styles/text";

.onboardingStepTwo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .planCardsContainer {
    display: flex;
    justify-content: center;
    width: 100%;

    @include medium-down {
      flex-direction: column;
    }

    @include small {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      width: fit-content;
      height: 100%;
      width: 100%;

      padding: 20px 0px !important;
    }
  }

  .modalTitle {
    font-family: $nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    letter-spacing: 6px;
    text-transform: uppercase;
    color: $peach;
    margin-bottom: 12px;

    img {
      width: 170px;
      height: 47px;
    }

    @include small {
      font-size: 26px;
      line-height: 33px;
      letter-spacing: 4px;
      margin-bottom: 10px;

      img {
        width: 120px;
        height: 33px;
      }
    }
  }

  .modalSubTitle {
    font-family: $nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: $blue;
    // margin-bottom: 10px;
    margin: 10px 0;

    @include small {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 20px;
    }
  }

  .boxContainer {
    display: flex;
    flex-direction: row;
    height: 40px;
    width: 164px;
    gap: 10px;
    justify-content: center;
    align-items: center;

    @include small {
      margin-top: 0px;
    }
  }

  .planText {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: $gray;

    @include small {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.3em;
    }
  }
}

.tryFreeContainer {
  display: flex;
  justify-content: center;
  align-items: baseline;

  li span {
    position: relative;
    left: -10px;
  }

  p {
    margin-right: 5px;
  }
}

.getStartedButton {
  font-size: 12px !important;
  margin-top: 5px;

  div {
    display: flex;
  }
}

.warning {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  align-self: flex-start;
  margin-top: 22px;

  font-family: $nunito !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: $gray;

  @include small {
    margin-top: 0;
    padding: 0px 20px;
  }
}
