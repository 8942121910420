@import "styles/mixins";

html {
    //wrap so it takes precedence
    .hidden {
        display: none !important;
    }

    .hidden-mobile {
        @include small {
            display: none;
        }
    }

    .visibity-hidden {
        visibility: hidden;
    }

    .flex {
        &--center {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--apart-center {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .mb {
        &-0 {
            margin-bottom: 0px;
        }

        &-5 {
            margin-bottom: 5px;
        }
        &-9 {
            margin-bottom: 9px;
        }
        &-10 {
            margin-bottom: 10px;
        }
        &-15 {
            margin-bottom: 15px;
        }
        &-20 {
            margin-bottom: 20px;
        }
        &-25 {
            margin-bottom: 15px;
        }
        &-30 {
            margin-bottom: 30px;
        }
    }

    .mt {
        &-5 {
            margin-top: 5px;
        }
        &-9 {
            margin-top: 9px;
        }
        &-10 {
            margin-top: 10px;
        }
        &-15 {
            margin-top: 15px;
        }
        &-20 {
            margin-top: 20px;
        }
        &-25 {
            margin-top: 25px;
        }
        &-30 {
            margin-top: 30px;
        }
        &-50 {
            margin-top: 50px;
        }
    }
    .mr {
        &-5 {
            margin-right: 5px;
        }
        &-9 {
            margin-right: 9px;
        }
        &-10 {
            margin-right: 10px;
        }
        &-15 {
            margin-right: 15px;
        }
        &-16 {
            margin-right: 16px;
        }
        &-20 {
            margin-right: 20px;
        }
        &-25 {
            margin-right: 15px;
        }
        &-30 {
            margin-right: 30px;
        }
    }
    .ml {
        &-5 {
            margin-left: 5px;
        }
        &-9 {
            margin-left: 9px;
        }
        &-10 {
            margin-left: 10px;
        }
        &-15 {
            margin-left: 15px;
        }
        &-16 {
            margin-left: 16px;
        }
        &-20 {
            margin-left: 20px;
        }
        &-25 {
            margin-left: 15px;
        }
        &-30 {
            margin-left: 30px;
        }
    }

    .pt {
        &-0 {
            padding-top: 0px;
        }
    }
}

.no-underline,
.no-underline > * {
    text-decoration: none !important;
}

.color-swatch {
    width: 50px;
    height: 50px;
}
