$min: 0px;
$xsmall: 375px;
$small: 600px;
$medium: 960px;
$large: 1280px;
$xlarge: 1600px;

@mixin xsmall {
  @media (min-width: #{$min}) and (max-width: #{$xsmall}) {
    @content;
  }
}

@mixin small {
  @media (min-width: #{$min}) and (max-width: #{$small}) {
    @content;
  }
}

@mixin medium {
  @media (min-width: #{$small}) and (max-width: #{$medium}) {
    @content;
  }
}

@mixin medium-down {
  @media (max-width: #{$medium}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$medium}) and (max-width: #{$large}) {
    @content;
  }
}

@mixin small-up {
  @media (min-width: #{$small}) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: #{$medium}) {
    @content;
  }
}

@mixin large-down {
  @media (max-width: #{$large}) {
    @content;
  }
}

@mixin large-up {
  @media (min-width: #{$large}) {
    @content;
  }
}

@mixin xlarge {
  @media (min-width: #{$xlarge}) {
    @content;
  }
}
