@import "styles/colors";
@import "styles/mixins";

.brandSetupExplainModalContainer {
  @include small {
    padding-top: 2rem;
    min-height: initial;
  }
  .imagesContainer {
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
    align-items: flex-end;

    @include small {
      gap: 0;
    }

    .leftImages {
      display: flex;
      gap: 1rem;

      @include small {
        gap: 8px;
      }

      img {
        opacity: 0;
        transform: scale(0);
        animation-fill-mode: forwards;

        &:nth-child(1) {
          animation: popUp 0.5s ease-in-out 0s forwards;
        }

        &:nth-child(2) {
          animation: popUp 0.5s ease-in-out 0.3s forwards;
        }
      }
    }

    .middleImage {
      .arrowCurl {
        stroke-dasharray: 200; // Adjust based on the total length of your path
        stroke-dashoffset: 200; // Start with the full length hidden
        animation: draw 1s linear 0.6s forwards;

        @keyframes draw {
          to {
            stroke-dashoffset: 0;
          }
        }
      }
      .arrow {
        stroke-dasharray: 200; // Adjust based on the total length of your path
        stroke-dashoffset: 200; // Start with the full length hidden
        animation: draw 2s linear 0.9s forwards;
        position: relative;
        left: -4px;
        top: 5px;
        @keyframes draw {
          to {
            stroke-dashoffset: 0;
          }
        }
      }
    }

    .rightImages {
      display: flex;
      gap: 0.5rem;
      align-items: flex-end;

      .shapes {
        opacity: 0;
        transform: scale(0);

        animation-fill-mode: forwards;
        animation: popUp 0.5s ease-in-out 0.8s forwards;
      }

      .jar {
        position: relative;
        .jarSquare {
          opacity: 0;
          transform: scale(0);
          animation-fill-mode: forwards;
          position: absolute;
          background-color: $peach;
          width: 14px;
          height: 14px;
          z-index: 2;
          left: 14px;
          bottom: 21px;

          animation-fill-mode: forwards;
          animation: popUp 0.5s ease-in-out 1.2s forwards;
        }
      }

      .lipStick {
        position: relative;

        .lipStickSquare {
          opacity: 0;
          transform: scale(0);
          animation-fill-mode: forwards;
          position: absolute;
          background-color: $peach;
          width: 5px;
          height: 8.4px;
          z-index: 2;
          left: 6px;
          bottom: 13px;

          animation-fill-mode: forwards;
          animation: popUp 0.5s ease-in-out 1.4s forwards;
        }
      }

      .circle {
        position: relative;

        .circleSquare {
          opacity: 0;
          transform: scale(0);
          animation-fill-mode: forwards;
          position: absolute;
          background-color: $peach;
          width: 10px;
          height: 10px;
          z-index: 2;
          left: 11px;
          bottom: 16px;

          animation-fill-mode: forwards;
          animation: popUp 0.5s ease-in-out 1.6s forwards;
        }
      }

      .squares {
        position: relative;

        .squaresSquare {
          opacity: 0;
          transform: scale(0);
          animation-fill-mode: forwards;
          position: absolute;
          background-color: $peach;
          width: 7px;
          height: 7px;
          z-index: 2;
          left: 11px;
          top: 7px;

          animation-fill-mode: forwards;
          animation: popUp 0.5s ease-in-out 1.8s forwards;
        }
      }

      .squaresSquareTwo {
        opacity: 0;
        transform: scale(0);
        animation-fill-mode: forwards;
        position: absolute;
        background-color: $peach;
        width: 10px;
        height: 10px;
        z-index: 2;
        left: 10px;
        bottom: 16px;

        animation-fill-mode: forwards;
        animation: popUp 0.5s ease-in-out 2s forwards;
      }
    }
  }
}

@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
