@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.orderCard {
  width: 100%;

  border: 0.5px solid $light-gray;
  border-radius: 0;

  background-color: $lighter-gray;

  margin: 0 auto;
  margin-bottom: 10px;

  box-shadow: none;

  .cardWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
  }

  .leftSideBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .rightSideBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .commonTextStyles {
    font-feature-settings: "clig" off, "liga" off;
    line-height: normal;
    font-style: normal;
    font-style: normal;
    color: $gray;
  }

  .orderNumber {
    font-family: $georgia !important;
    font-size: 16px;
    font-weight: 700;
  }

  .orderDate {
    font-family: $georgia !important;
    font-size: 12px;
    font-weight: 400;
    margin-top: 7px;
  }

  .flexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
  }

  .orderDetails {
    font-family: $nunito;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .arrowIcon {
    width: 16px !important;
    height: 16px !important;
    padding: 3px !important;
    margin-left: 6px;
    font-weight: bold;
  }
}
