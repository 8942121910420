@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandSetupLoading {
  background: $lighter-gray;
  border: 1px solid #0000001f;
  height: 400px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;

  .brandSetupLoadingGif {
    width: 120px;
    height: 120px;
  }
}

.brandSetupReview {
  height: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;

  .brandSetupReviewHeader {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 80px;

    @include small {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    .brandSetupReviewHeaderIcon {
      height: 12px;
      width: 12px;
    }
  }

  .brandSetupReviewContent {
    margin-top: 3rem;

    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .brandSetupReviewCta {
    margin-top: 2rem;
    padding: 32px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    background: linear-gradient(63.36deg, #246071 16.7%, #143f4b 83.3%);
  }

  .brandSetupReviewNote {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1.5rem;
    text-align: center;

    @include small {
      margin-bottom: 6rem;
    }
  }
}

.confirmModal {
  max-width: 900px;
  padding: 6rem;

  @include small {
    padding: 2rem;
    min-height: initial;
  }

  .confirmIcon {
    height: 52px;
    width: 52px;
  }

  .confirmTitle {
    font-family: $georgia !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.4px;
    text-align: center;
  }

  .confirmDescription {
    font-family: $nunito;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.1px;
    text-align: center;
  }

  .confirmCancel {
    font-family: $nunito;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.1px;
    text-align: center;
    text-decoration: underline;
    margin-top: 2rem;
    cursor: pointer;

    &:hover {
      color: $peach;
    }
  }
}

.backNav {
  cursor: pointer;
  .backNavIcon {
    font-size: 14px;
    color: $gray;
  }

  @include small {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}
