@import "styles/colors";
@import "styles/text.scss";
@import "styles/mixins";

.planVisualizerCard {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  width: 370px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: $white;
  border-radius: 4px;

  @include small {
    border: none;
    border-radius: 0;
    padding: 24px;
    width: auto;
  }

  .planName {
    display: flex;
    justify-content: center;
    width: 100%;

    font-family: $georgia !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;

    line-height: normal;
    color: $peach;
    padding-bottom: 8px;
    text-transform: uppercase;
  }

  .stackContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include small {
      width: auto;
    }

    .feature,
    .featureItem {
      display: flex;

      font-family: $nunito !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      padding: 10px 0;
    }

    .feature {
      color: $gray;
    }

    .featureItem {
      justify-content: flex-end;
      color: $blue;
    }

    .checkIcon {
      background-color: $blue;
      border-radius: 100%;
      width: 12px;
      height: 12px;

      padding: 3px;

      fill: $white;
    }
  }
}
