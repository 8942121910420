@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.brandPlateorderHistory {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .item {
    background: $white;
    border: $border;
    border-radius: 4px;

    padding: 16px;

    @include small {
      padding: 8px;
    }
  }
}
