@import 'styles/colors.scss';

.errorContainer{
    align-items: center;
    justify-content: center;
    height: 50vh;

}

.errorCard{
    position: relative;
    background: $peach;
    padding: 40px;
    border-radius: 0px;
    max-width: 400px;
    max-height: 400px;
    min-height: 300px;
    min-width: 400px;
    background-image: url('/assets/images/icon-error.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 90%;
}

.button{
    position: absolute;
    bottom: 40px;
    left: 40px;
    width: 80%;
}
