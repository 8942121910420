@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.addressStatusTag {
  background-color: rgba($color: $blue, $alpha: 0.12);
  color: $blue;

  font-family: $nunito !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  height: 16px;
  padding: 0.5px 3px !important;
}
