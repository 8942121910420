@import "styles/colors";
@import "styles/mixins";
@import "styles/text";

.feedbackWrapper {
  max-width: 480px;
  min-width: 480px;
  display: flex;
  flex-direction: column;
  margin: auto;

  @include small {
    max-width: initial;
    min-width: initial;
    padding: 16px;
  }
}

.feedback {
  position: relative;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 3rem;
  width: 100%;

  .title {
    font-family: $georgia;
    font-size: 21px;
    font-weight: 700;
    line-height: 29.4px;
    text-align: center;
    color: $gray;
    margin-bottom: 30px;
  }

  .cardWrapper {
    padding: 24px 30px;
    background-color: $lighter-gray;
    border-radius: 0;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    // width: 480px;
  }

  .questions {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .questionWrapper {
    margin: 8px 0;
    .checkboxAndQuestion {
      display: flex;
      flex-direction: row;
      align-items: center;

      .question {
        font-family: $nunito !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.1px;
        text-align: left;
        text-transform: initial !important;
        letter-spacing: initial !important;
      }
    }

    .replyWrapper {
      display: flex;
      margin-left: 34px;

      .reply {
        margin-top: 8px;
        height: 50px;
        width: 295px;
        margin-bottom: 16px;
      }
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  .keepCurrentLink {
    font-family: $nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.4px;
    color: $gray;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      color: $peach;
    }
  }
}
