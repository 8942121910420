@import "styles/colors.scss";
@import "styles/text.scss";
@import "styles/mixins.scss";

.card {
  margin-top: 20px;
}

.encryptedPassword {
  font-family: $nunito !important;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
}

.formControlWrapper {
  max-width: 510px;
  min-width: 315px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;

  .passwordLabel {
    font-family: $nunito !important;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 2px;
    text-align: left;
    padding-top: 20px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
}

.changePasswordButton {
  margin-top: 20px;
  max-width: 340px;
  min-width: 315px;
  width: 100%;
}

.submitButton {
  width: 310px;
  // height: 50px;
}

.backBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: fit-content;

  cursor: pointer;

  font-family: $nunito !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-align: left;
  color: $gray;

  svg {
    width: 16px;
    height: 16px;
  }
}
